let config = {
  API_URL: 'http://localhost:6060',
  SDE_URL: 'http://localhost:3700',
}

if (window) {
  const protocol = window.location.hostname === 'localhost' ? 'http' : 'https'
  config.API_URL = `${protocol}://${window.location.hostname}:6060`
}

if (window && window.__serverConfig) {
  config = window.__serverConfig
}

export default config
