import * as React from 'react'

import { FormConsumer, FormProvider } from './context'
import {
  objectTreeImmutableSet,
  deepImmutableSetInputError,
  flattenValues,
  flattenErrors,
  validateValues,
} from './utils'

const defaultFormCompositeMapper = (val) => val

/**
  The FormConnector is intended for giving the Section access to the state API
  of the most immediate Form or Section parent.
*/
class FormConnector extends React.Component {
  static defaultProps = {
    defaultValues: undefined,
    component: undefined,
    componentProps: {},
    render: undefined,
    children: undefined,
    validate: undefined,
    storeValues: undefined,
  }

  constructor(props) {
    super(props)

    if (!props.compositeInputMode) {
      this.initSectionErrors([], props)
      this.registerSection([], props)
    }

    const {
      name,
      defaultValues,
      formState: { values: { [name]: values } = {}, setInputValue } = {},
      storeValues,
    } = props

    if (defaultValues !== undefined && values === undefined) {
      setInputValue(name, defaultValues, { noValuesHaveChangedUpdate: true })
    } else if (storeValues !== undefined) {
      setInputValue(name, storeValues, { noValuesHaveChangedUpdate: true })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.storeValues !== this.props.storeValues) {
      this.setValues(nextProps.storeValues, {
        noValuesHaveChangedUpdate: true,
      })
    }
  }

  /**
    This method sets the whole values object of the section.

    If there is a validate prop, it is called after this is triggered.

    @param {(object|formStateUpdaterCallback)} values - The new values to set
      the Section to, or the callback that returns the new values to set the
      Section to.
    @param {object} [options={}] - Additional options
    @param {afterUpdateCallback} options.afterUpdate - An optional function to
      call once the Section values have finished updating.
  */
  setValues = (values, { afterUpdate, noValuesHaveChangedUpdate } = {}) => {
    const {
      name,
      formState: { setInputValue } = {},
      compositeInputMode,
      formValueMapper = defaultFormCompositeMapper,
    } = this.props

    setInputValue(
      name,
      (formApi) => {
        const { values: { [name]: sectionValues } = {} } = formApi
        const prevSectionApi = this.getSectionApiFromFormApi(formApi)
        const { validate } = this.props

        let _formValueMapper = defaultFormCompositeMapper
        if (compositeInputMode) {
          _formValueMapper = formValueMapper
        }

        return _formValueMapper(
          validateValues(
            prevSectionApi,
            values,
            undefined,
            (newValues) => ({
              ...sectionValues,
              ...newValues,
            }),
            validate,
          ),
        )
      },
      { afterUpdate, noValuesHaveChangedUpdate },
    )
  }

  /**
    This method updates the a value within the values object at the specified
    location.

    If there is a validate prop, it is called after this is triggered.

    @param {(string|string[])} inputPath - The path to location in the values
      object to update.
    @param {(*|formStateUpdaterCallback)} value - The new value to set at the
      specified location or the callback the returns the new value to set at the
      specified location.
    @param {object} [options={}] - Additional options
    @param {afterUpdateCallback} options.afterUpdate - An optional function to
      call once the Form value has finished updating.
  */
  setInputValue = (inputPath, value, { afterUpdate, noValuesHaveChangedUpdate } = {}) => {
    const {
      name,
      formState: { setInputValue } = {},
      compositeInputMode,
      formValueMapper = defaultFormCompositeMapper,
    } = this.props

    setInputValue(
      name,
      (formApi) => {
        const { values: { [name]: sectionValues } = {} } = formApi
        const prevSectionApi = this.getSectionApiFromFormApi(formApi)
        const { validate } = this.props

        let _formValueMapper = defaultFormCompositeMapper
        if (compositeInputMode) {
          _formValueMapper = formValueMapper
        }

        return _formValueMapper(
          validateValues(
            prevSectionApi,
            value,
            inputPath,
            (newValue) => objectTreeImmutableSet(sectionValues, inputPath, newValue),
            validate,
          ),
        )
      },
      { afterUpdate, noValuesHaveChangedUpdate },
    )
  }

  /**
    This method generates the form API to be used externally at the section level.

    @return {object} The section-level form API.
  */
  getSectionApi = () => {
    const {
      name,
      formState: {
        values: { [name]: _values } = {},
        errors: { nested: { [name]: _errors } = {} } = {},
        sectionRegister: { [name]: sectionRegister } = {},
        submit,
        valuesHaveChanged,
      },
      compositeInputMode,
      compositeValuesMapper = defaultFormCompositeMapper,
      compositeErrorsMapper = defaultFormCompositeMapper,
    } = this.props

    let _compositeValuesMapper = defaultFormCompositeMapper
    let _compositeErrorsMapper = defaultFormCompositeMapper

    if (compositeInputMode) {
      _compositeValuesMapper = compositeValuesMapper
      _compositeErrorsMapper = compositeErrorsMapper
    }

    const values = _compositeValuesMapper(_values)
    const errors = _compositeErrorsMapper(_errors)

    return {
      values,
      errors,
      flatValues: flattenValues(sectionRegister, values),
      flatErrors: flattenErrors(sectionRegister, errors, name),
      onChange: this.setValues,
      onChangeInput: this.setInputValue,
      setError: this.setError,
      setInputError: this.setInputError,
      submit,
      valuesHaveChanged,
    }
  }

  /**
    This method generates the form API to be used externally at the section level.
    It uses the form API from the previous level section/form.

    @param {object} formApi - The form API from the parent form/section.
    @return {object} The section-level form API.
  */
  getSectionApiFromFormApi = (formApi) => {
    const {
      name,
      compositeInputMode,
      compositeValuesMapper = defaultFormCompositeMapper,
      compositeErrorsMapper = defaultFormCompositeMapper,
    } = this.props
    const {
      values: { [name]: _values } = {},
      errors: { nested: { [name]: _errors } = {} } = {},
      sectionRegister: { [name]: sectionRegister } = {},
    } = formApi

    let _compositeValuesMapper = defaultFormCompositeMapper
    let _compositeErrorsMapper = defaultFormCompositeMapper

    if (compositeInputMode) {
      _compositeValuesMapper = compositeValuesMapper
      _compositeErrorsMapper = compositeErrorsMapper
    }

    const values = _compositeValuesMapper(_values)
    const errors = _compositeErrorsMapper(_errors)

    return {
      ...this.getSectionApi(),
      values,
      errors,
      flatValues: flattenValues(sectionRegister, values),
      flatErrors: flattenErrors(sectionRegister, errors, name),
    }
  }

  /**
    This method generates the section state. This gets passed down to child sections
    and inputs internally through context.

    @return {object} The section state.
  */
  getSectionState = () => {
    const {
      name,
      formState: {
        values: { [name]: _values } = {},
        errors: { nested: { [name]: _errors } = {} } = {},
        sectionRegister: { [name]: sectionRegister } = {},
        submit,
        valuesHaveChanged,
      },
      compositeInputMode,
      compositeValuesMapper = defaultFormCompositeMapper,
      compositeErrorsMapper = defaultFormCompositeMapper,
    } = this.props

    let _compositeValuesMapper = defaultFormCompositeMapper
    let _compositeErrorsMapper = defaultFormCompositeMapper

    if (compositeInputMode) {
      _compositeValuesMapper = compositeValuesMapper
      _compositeErrorsMapper = compositeErrorsMapper
    }

    const values = _compositeValuesMapper(_values)
    const errors = _compositeErrorsMapper(_errors)

    return {
      values,
      errors,
      sectionRegister,
      setInputValue: this.setInputValue,
      setInputError: this.setInputError,
      initSectionErrors: this.initSectionErrors,
      registerSection: this.registerSection,
      submit,
      valuesHaveChanged,
    }
  }

  /**
    This method sets the error of the section.

    @param {(*|formStateUpdaterCallback)} error - The new error to set the
      Section to, or a callback that returns the new error to set the Section to.
    @param {object} [options={}] - Additional options
    @param {afterUpdateCallback} options.afterUpdate - An optional function to
      call once the Section error has finished updating.
  */
  setError = (error, { afterUpdate } = {}) => {
    const {
      name,
      formState: { setInputError } = {},
      compositeInputMode,
      formErrorMapper = defaultFormCompositeMapper,
    } = this.props

    setInputError(
      name,
      (formApi) => {
        const _error =
          typeof error === 'function' ? error(this.getSectionApiFromFormApi(formApi)) : error

        let _formErrorMapper = defaultFormCompositeMapper
        if (compositeInputMode) {
          _formErrorMapper = formErrorMapper
        }

        return _formErrorMapper(_error)
      },
      { afterUpdate },
    )
  }

  /**
    This method sets the errors object of the whole Section.

    @param {(object|formStateUpdaterCallback)} errors - The new errors to set the
      Section to, or a callback that returns the new errors to set the Section to.
    @param {object} [options={}] - Additional options
    @param {afterUpdateCallback} options.afterUpdate - An optional function to
      call once the Section errors have finished updating.
  */
  setErrors = (errors, props, { afterUpdate } = {}) => {
    const {
      name,
      formState: { setInputError } = {},
      compositeInputMode,
      formErrorMapper = defaultFormCompositeMapper,
    } = props || this.props

    setInputError(
      name,
      (formApi) => {
        const { errors: { nested: { [name]: sectionErrors } = {} } = {} } = formApi
        const _errors =
          typeof errors === 'function' ? errors(this.getSectionApiFromFormApi(formApi)) : errors

        let _formErrorMapper = defaultFormCompositeMapper
        if (compositeInputMode) {
          _formErrorMapper = formErrorMapper
        }

        return _formErrorMapper({
          ...sectionErrors,
          ..._errors,
        })
      },
      { setSectionErrors: true, afterUpdate },
    )
  }

  /**
    This method sets the error of the input or section at the specified location.

    @param {(string|string[])} inputPath - The path to location in the errors
      object to set the error.
    @param {(*|formStateUpdaterCallback)} error - The new error to set at
      the specified location, or a callback that returns the new error to set
      at the specified location.
    @param {object} [options={}] - Additional options
    @param {afterUpdateCallback} options.afterUpdate - An optional function to
      call once the Section errors have finished updating.
    @param {bool} [options.setSectionErrors=false] - If this is true, then the end of the
      path will be assumed to land on a section rather than an input, and all
      errors of that section will be updated.
  */
  setInputError = (inputPath, error, { setSectionErrors = false, afterUpdate } = {}) => {
    const {
      name,
      formState: { setInputError } = {},
      compositeInputMode,
      formErrorMapper = defaultFormCompositeMapper,
    } = this.props

    setInputError(
      name,
      (formApi) => {
        const { errors: { nested: { [name]: sectionErrors } = {} } = {} } = formApi
        const _error =
          typeof error === 'function' ? error(this.getSectionApiFromFormApi(formApi)) : error

        let _formErrorMapper = defaultFormCompositeMapper
        if (compositeInputMode) {
          _formErrorMapper = formErrorMapper
        }

        return _formErrorMapper(
          deepImmutableSetInputError(sectionErrors, inputPath, _error, {
            setSectionErrors,
          }),
        )
      },
      { setSectionErrors: true, afterUpdate },
    )
  }

  /**
    This method intializes a section's key in the errors object.

    @param {(string|string[])} sectionPath - The path to the section to initialize.
    @param {object} [props=this.props] - The props from which to get the name and
      the form state of the Section.
  */
  initSectionErrors = (sectionPath, props) => {
    const { name, formState: { initSectionErrors } = {} } = props

    initSectionErrors([name].concat(sectionPath))
  }

  /**
    This method registers a section in the section register.

    @param {(string|string[])} sectionPath - The path to the section to register.
    @param {object} [props=this.props] - The props from which to get the name and
      the form state of the Section.
  */
  registerSection = (sectionPath, props = this.props) => {
    const { name, formState: { registerSection } = {} } = props

    registerSection([name].concat(sectionPath))
  }

  render() {
    const { component: Component, componentProps = {}, render, children } = this.props

    const sectionApi = this.getSectionApi()
    const sectionState = this.getSectionState()

    if (Component) {
      return (
        <FormProvider value={sectionState}>
          <Component formApi={sectionApi} {...componentProps} />
        </FormProvider>
      )
    }

    if (render) {
      return <FormProvider value={sectionState}>{render(sectionApi)}</FormProvider>
    }

    if (typeof children === 'function') {
      return <FormProvider value={sectionState}>{children(sectionApi)}</FormProvider>
    }

    return <FormProvider value={sectionState}>{children}</FormProvider>
  }
}

/**
  The Section component grabs the form state from the parent form/section through
  context and passes it along with any children to the FormConnector.
*/
const Section = (props) => (
  <FormConsumer>
    {(formState) => <FormConnector formState={formState} {...props} compositeInputMode={false} />}
  </FormConsumer>
)

const CompositeInput = (props) => (
  <FormConsumer>
    {(formState) => <FormConnector formState={formState} {...props} compositeInputMode />}
  </FormConsumer>
)

export default Section
export { CompositeInput }
