import React from 'react'
// Meg made the svg in a 500px viewbox with rects/polys hence the transform and fill.
export default (
  <g
    id='Styleguide'
    stroke='none'
    strokeWidth='1'
    fill='none'
    fillRule='evenodd'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <g id='Icons-fill'>
      <g id='Nav-Icon/default/qaReports'>
        <g id='icon---qa-reports-copy' transform='matrix(0.087,0,0,0.087,0,-4)'>
          <path d='M99,456V285.78h49V456h20V271.36a5.57,5.57,0,0,0-5.57-5.58H84.54A5.57,5.57,0,0,0,79,271.36V456H99Z' />
          <path d='M228,144.54h49V456h20V130.12a5.57,5.57,0,0,0-5.57-5.58h-77.9a5.57,5.57,0,0,0-5.57,5.58V456h20Z' />
          <path d='M357.8,62.28h49V456h20V47.86a5.57,5.57,0,0,0-5.57-5.58h-77.9a5.57,5.57,0,0,0-5.57,5.58V456h20Z' />
          <path d='M72.53,453h-25V32.57A5.57,5.57,0,0,0,41.92,27H33.06a5.58,5.58,0,0,0-5.57,5.57V473H79V453Z' />
          <rect x='168.01' y='453' width='40' height='20' />
          <rect x='98.97' y='453' width='49.04' height='20' />
          <rect x='228.01' y='453' width='49.04' height='20' />
          <rect x='297.05' y='453' width='40.75' height='20' />
          <rect x='357.8' y='453' width='49.04' height='20' />
          <path d='M473.49,458.57a5.57,5.57,0,0,0-5.57-5.57H426.84v20h41.08a5.58,5.58,0,0,0,5.57-5.57Z' />
          <polygon points='98.97 473 98.97 453 92.53 453 78.97 453 78.97 473 92.53 473 98.97 473' />
          <rect x='148.01' y='453' width='20' height='20' />
          <rect x='208.01' y='453' width='20' height='20' />
          <rect x='277.05' y='453' width='20' height='20' />
          <rect x='337.8' y='453' width='20' height='20' />
          <rect x='406.84' y='453' width='20' height='20' />
        </g>
      </g>
    </g>
  </g>
)
