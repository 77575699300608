import { compose, withHandlers } from 'recompose'

/**
 * Override an onChange function to convert the event to a value
 * @type {function} onChange - Overriden onChange function
 */
const withOnChangeEventHandler = compose(
  withHandlers({
    /**
     * Convert the event to a value and pass to the original function
     * @param  {object} event - onChange event object
     */
    onChange: (props) => (event) => {
      const value = event.target.value

      // Pass the value itself to the original onChange function
      props.onChange(value)
    },
  }),
)

export default withOnChangeEventHandler
