import * as React from 'react'
import { withStyles, Select as MuiSelect, MenuItem } from '@material-ui/core'

import { connectInput } from '../Form'
import PlaceholderText from './placeholderText'

function genMultiplePlaceholderRenderer(placeholder) {
  function multiplePlaceholderRender(values) {
    if (values.length === 0) {
      return placeholder
    }

    return values.map((value, index) => (
      <React.Fragment key={value}>
        {index !== 0 ? ',' : ''}&nbsp;
        {value}
      </React.Fragment>
    ))
  }

  return multiplePlaceholderRender
}

const selectStyles = (theme = {}) => {
  const { custom = {} } = theme
  const { select = {} } = custom
  const { menu = {}, root } = select
  const { paper = {}, menuList = {} } = menu

  return {
    paper,
    menuList,
    root: {
      ...root,
    },
  }
}

const BaseSelect = ({
  classes,
  children,
  onChange,
  value,
  disabled,
  multiple,
  placeholder,
  style,
}) => {
  let renderValue

  if (placeholder !== undefined) {
    const placeholderText = function () {
      return <PlaceholderText>{placeholder}</PlaceholderText>
    }

    if (multiple) {
      renderValue = genMultiplePlaceholderRenderer(placeholderText())
    } else if (!value) {
      renderValue = placeholderText
    }
  }

  return (
    <MuiSelect
      MenuProps={{
        classes: { paper: classes.paper },
        MenuListProps: { className: classes.menuList },
        getContentAnchorEl: null,
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transformOrigin: { vertical: -10, horizontal: 0 },
        marginThreshold: 10,
      }}
      className={classes.root}
      disableUnderline
      onChange={onChange}
      value={!Array.isArray(value) && multiple ? [] : value}
      disabled={disabled}
      displayEmpty
      multiple={multiple}
      renderValue={renderValue}
      style={style}
      autoWidth
    >
      {children}
    </MuiSelect>
  )
}

BaseSelect.defaultProps = {
  onChange: undefined,
  value: '',
  disabled: false,
  multiple: false,
  placeholder: undefined,
  style: undefined,
}

const Select = withStyles(selectStyles)(BaseSelect)

const FormSelectBase = ({ formApi, defaultValue, ...rest }) => {
  const { value = defaultValue, onChange } = formApi

  return <Select value={value} onChange={(e) => onChange(e.target.value)} {...rest} />
}

FormSelectBase.defaultProps = {
  defaultValue: '',
}

const FormSelect = connectInput(FormSelectBase, {
  defaultName: 'select',
  bundleApi: true,
})

export default Select
export { MenuItem, FormSelect }
