import styled from 'styled-components'

import colors from '@shared/colors'

// Menu wrapper
const AppMenuWrapper = styled.nav`
  display: flex;
  align-items: start;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
  flex-grow: 1;
`

// Menu link item
const MenuLink = styled.a`
  display: flex;
  align-items: center;
  color: ${({ selected }) => (selected ? `${colors.blue.base}` : `${colors.black}`)};
  text-decoration: none;
  & #Icons {
    stroke: ${({ selected }) => (selected ? `${colors.blue.base}` : `${colors.black}`)};
  }
  & #Icons-fill {
    fill: ${({ selected }) => (selected ? `${colors.blue.base}` : `${colors.black}`)};
  }
  &: hover {
    color: ${colors.blue.base};
    & #Icons {
      stroke: ${colors.blue.base};
    }
    & #Icons-fill {
      fill: ${colors.blue.base};
    }
  }
`

// Menu link item
const MenuLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  padding-left: ${({ selected }) => (selected ? '70px' : '75px')};
  background-color: ${({ selected }) => (selected ? 'rgba(0,0,0,0.02)' : 'none')};
  border-left: ${({ selected }) => (selected ? '5px solid #007AFF' : 'none')};
`

// Logout link item
const LogoutLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
`

// App link icon
const AppSvg = styled.svg`
  top: 3px;
  right: 5px;
  width: 25px;
  height: 25px;
  margin-right: 20px;
`

const ExternalLinkIcon = styled.svg`
  margin: 13px 0 0 7px;
  width: 25px;
  height: 25px;
`

export { AppMenuWrapper, MenuLink, AppSvg, MenuLinkWrapper, LogoutLinkWrapper, ExternalLinkIcon }
