import { compose, withState, withHandlers } from 'recompose'

/**
 * Enhance a component with an "open" state
 * @param {boolean}  isOpen       Whether the "open" state is "open"
 * @param {function} setOpenState Set the "open" state
 * @param {function} toggleState  Toggle the "open" state
 */
const withOpenState = compose(
  withState('isOpen', 'setOpenState', (props) => props.startsOpen || false),
  withHandlers({
    /**
     * Toggle the open state
     */
    toggleOpenState: (props) => () => props.setOpenState(!props.isOpen),
  }),
)

export default withOpenState
