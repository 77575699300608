import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import colors from '@shared/colors'

import NavigationLinkIconMap from './NavigationLinkIconMap'

import { NavigationLinkIcon, NavigationLinkWrapper } from './styled'

const styles = {
  activeLink: {
    color: colors.white,
  },
}

/**
 * App navigation menu link with icon
 * @param {string} link - Link path (url)
 * @param {string} text - Link display text
 */
const AppNavigationLink = (props) => {
  const { iconMap, link, location, text } = props

  // Use the current route to display a different icon and link state for the
  //  active link (react-router can't change the icon based on route)
  const currentRoute = location.pathname
  const linkState = currentRoute === link ? 'active' : 'inactive'

  const linkIcon = NavigationLinkIconMap[iconMap][linkState]

  return (
    <NavigationLinkWrapper activeStyle={styles.activeLink} alt={text} to={link}>
      <NavigationLinkIcon src={linkIcon} alt={text} />
      {text}
    </NavigationLinkWrapper>
  )
}

AppNavigationLink.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default withRouter(AppNavigationLink)
