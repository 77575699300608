import * as React from 'react'
import { withStyles, DialogContent } from '@material-ui/core'

const WIDTH_OFFSET = 55
const PADDING_OFFSET = 6

const styles = (theme) => ({
  paper: {
    width: theme.spacing.unit * WIDTH_OFFSET,
    padding: theme.spacing.unit * PADDING_OFFSET,
  },
})

const CustomDialogContent = ({ children, classes }) => (
  <DialogContent className={classes.paper}>{children}</DialogContent>
)

CustomDialogContent.defaultProps = {
  children: null,
}

export default withStyles(styles)(CustomDialogContent)
