// Libraries
import React from 'react'
import { SvgIcon } from '@material-ui/core'

function createSvgIcon(path, displayName) {
  const Icon = (props) => (
    <SvgIcon {...props} viewBox='0 0 22 26'>
      {path}
    </SvgIcon>
  )

  Icon.displayName = displayName
  Icon.muiName = 'SvgIcon'

  return Icon
}

export default createSvgIcon
