import * as React from 'react'

import SnackbarContext from './context'

const SnackbarMessageDispatcher = SnackbarContext.Consumer

function withSnackbarMessageDispatcher(Component) {
  function SnackbarMessageDispatcherWrapper(props) {
    return (
      <SnackbarMessageDispatcher>
        {(messageDispatcher) => <Component messageDispatcher={messageDispatcher} {...props} />}
      </SnackbarMessageDispatcher>
    )
  }
  return SnackbarMessageDispatcherWrapper
}

export default SnackbarMessageDispatcher
export { withSnackbarMessageDispatcher }
