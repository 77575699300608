import * as React from 'react'

import { DividerWithTextContent, DivideText, HalfDivider } from './container'

const DividerWithText = ({ text }) => (
  <DividerWithTextContent>
    <HalfDivider />
    <DivideText>{text}</DivideText>
    <HalfDivider />
  </DividerWithTextContent>
)

DividerWithText.defaultProps = {
  text: '',
}

export default DividerWithText
