import styled from 'styled-components'

// Main app content (underneath header)
const AppContent = styled.div`
  margin: 0 auto;
  padding-bottom: 50px;
  width: 100%;
`

// Main app content wrapper
const AppContentWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  width: 100%;
`

// Main app wrapper
const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  height: 100vh;
  width: 100%;
`

// Content column
const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${({ flex, width }) => (width ? 'initial' : flex ? flex : 1)};
  width: ${({ width }) => width && width};

  @media print {
    width: 50%;
  }
`

// Left-aligned item (corresponds to "RightItem")
const LeftItem = styled.span`
  flex-shrink: 0;
`

// Right-aligned item (coresponds to "LeftItem")
const RightItem = styled.span`
  margin-left: auto;
  padding-left: 20px;
  text-align: right;
`

// Content row (contains columns)
const Row = styled.div`
  display: flex;

  ${Column}:not(:first-of-type) {
    padding-left: 50px;
  }
`

export { AppContent, AppContentWrapper, AppWrapper, Column, LeftItem, RightItem, Row }
