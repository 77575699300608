import {
  FASTElement,
  Observable,
  Updates,
  css,
  customElement,
  html,
  ref,
  when,
} from '@microsoft/fast-element'
import { designUnit } from '@adaptive-web/adaptive-ui'
import Papa from 'papaparse'

import axios from '@app/shared/utilities/axios'

const usersBulkImportTemplate = html`
  <adaptive-dialog
    class="users-bulk-import-dialog"
    ${ref('bulkImportDialog')}
    modal="true"
    hidden="false"
  >
    <div class="header">
      <h2>Bulk import</h2>
    </div>

    <div class="body">
      <form id="submit-file-form">
        <input type="file" accept=".csv" ${ref('fileInput')} />
      </form>

      <div class="preview">
        <h3>Data preview</h3>

        <adaptive-data-grid
          class="preview-grid"
          ${ref('previewGrid')}
          generate-header="sticky"
        ></adaptive-data-grid>

        ${when(
          (x) => x.previewData.length === 0,
          html` <p class="empty-list">Select a file first to preview the data</p> `,
        )}
      </div>

      <adaptive-button class="cancel-button" type="button" @click=${(x) => x.closeDialog()}>
        Cancel
      </adaptive-button>
      <adaptive-button
        class="submit-button"
        @click=${(x) => {
          x.handleSubmit()
        }}
      >
        Submit
      </adaptive-button>
    </div>
  </adaptive-dialog>
`

const styles = css`
  .users-bulk-import-dialog::part(control) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 1fr;
    gap: 0 6px;
    height: 540px;
    width: 98vw;
    max-width: 1000px;
    padding: calc(${designUnit} * 4px);
  }

  .header {
    display: grid;
    grid-row: 1;
  }

  .body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 40px 1fr 32px;
    gap: 0 6px;
    grid-row: 2;
    width: 100%;
  }

  .background {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  #submit-file-form {
    grid-row: 1;
    grid-column: 1 / 4;
  }

  .submit-button {
    grid-row: 3;
    grid-column: 3;
  }
  .cancel-button {
    grid-row: 3;
    grid-column: 2;
    margin-right: calc(${designUnit}) * 2px;
  }

  .preview {
    grid-row: 2;
    grid-column: 1 / 4;
  }

  .preview-grid {
    overflow-y: auto;
    max-height: 300px;
  }

  adaptive-dialog {
    z-index: 10000;
  }

  .empty-list {
    text-align: center;
  }
`

@customElement({
  name: 'portal-users-bulk-import',
  template: usersBulkImportTemplate,
  styles,
})
export class UsersBulkImport extends FASTElement {
  fileInput = null
  previewGrid = null
  _previewData = []

  get previewData() {
    Observable.track(this, 'previewData')
    return this._previewData
  }

  set previewData(value) {
    this._previewData = value
    Observable.notify(this, 'previewData')
  }

  connectedCallback() {
    super.connectedCallback()
    Updates.enqueue(() => {
      this.initializeComponents()
    })
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    this.fileInput.removeEventListener('change')
  }

  initializeComponents() {
    this.previewGrid.columnDefinitions = [
      { columnDataKey: 'sfId', title: 'Salesforce ID' },
      { columnDataKey: 'email', title: 'E-mail' },
      { columnDataKey: 'npi', title: 'NPI' },
      { columnDataKey: 'firstName', title: 'First name' },
      { columnDataKey: 'lastName', title: 'Last name' },
      { columnDataKey: 'middleName', title: 'Middle name' },
      { columnDataKey: 'isInvestor', title: 'Is investor' },
    ]
    this.previewGrid.rowsData = []

    this.fileInput.addEventListener('change', () => this.loadPreview(this), false)
  }

  async handleSubmit() {
    let formData = new FormData()
    formData.append('file', this.fileInput.files[0])
    await axios('/users/import', 'post', {
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    this.closeDialog()
  }

  closeDialog() {
    if (this.fileInput.files) {
      this.fileInput.value = ''
      this.previewData = []
      this.previewGrid.rowsData = []
    }
    this.$emit('togglevisibility')
  }

  loadPreview(ctx) {
    const file = ctx.fileInput.files[0]
    const fr = new FileReader()
    fr.onload = () => {
      const result = Papa.parse(fr.result, {
        header: true,
      })

      ctx.previewGrid.rowsData = result.data
      ctx.previewData = result.data
    }
    fr.readAsText(file)
  }
}
