import React from 'react'

export default (
  <svg version='1.1' viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'>
    <g id='Icons-fill'>
      <path d='M344.64,218.12A59,59,0,1,0,324.79,174,58.83,58.83,0,0,0,344.64,218.12Zm39.22-83.2A39.12,39.12,0,0,1,422.94,174c0,.64,0,1.27-.05,1.89A39.07,39.07,0,0,1,345,177.64c-.11-1.2-.17-2.42-.17-3.65A39.12,39.12,0,0,1,383.86,134.92Z' />
      <path d='M67.13,217.68A59,59,0,1,0,47.78,174,58.87,58.87,0,0,0,67.13,217.68Zm39.72-82.76A39.12,39.12,0,0,1,145.93,174c0,.64,0,1.27-.05,1.89a39.07,39.07,0,0,1-78,1.08c-.07-1-.12-2-.12-3A39.12,39.12,0,0,1,106.85,134.92Z' />
      <path d='m244.92 210.56a67.67 67.67 0 1 0-67.71-67.72 67.79 67.79 0 0 0 67.71 67.72zm0-115.44a47.77 47.77 0 1 1-8.43 0.76 47.91 47.91 0 0 1 8.43-0.76z' />
      <path d='M474,317.29a85.91,85.91,0,0,0-20.35-44.74,86.86,86.86,0,0,0-20.73-17.45,86,86,0,0,0-88.23-.29,86.83,86.83,0,0,0-14.44,10.73q-3-3.94-6.29-7.58a99.53,99.53,0,0,0-147.82.85q-3.08,3.45-5.84,7.16A86.77,86.77,0,0,0,155,254.58a86,86,0,0,0-87.82.52A86.86,86.86,0,0,0,46.4,272.55a85.62,85.62,0,0,0-20.67,65.5H474.27a84.51,84.51,0,0,0-.32-20.76ZM46.4,316.78A66.18,66.18,0,0,1,155,279.09a65.44,65.44,0,0,1,4.8,4.62,100.57,100.57,0,0,0-4.8,12.68,98.87,98.87,0,0,0-3.93,20.9H46.4Zm124.69.51a80.27,80.27,0,0,1,2.18-12.17,77.65,77.65,0,0,1,2.82-8.67,78.8,78.8,0,0,1,7.52-14.65,79.73,79.73,0,0,1,140.3,12.7,78.66,78.66,0,0,1,3.27,9.49,80.07,80.07,0,0,1,2.49,13.3Zm178.66,0a99.5,99.5,0,0,0-5.11-24.56,100.61,100.61,0,0,0-3.88-9.56c1.25-1.29,2.54-2.54,3.88-3.72a66.24,66.24,0,0,1,109,37.33v.51Z' />
      <path d='m473.78 419.12h-446v-65.05h446zm-426-20h406v-25.05h-406z' />
    </g>
  </svg>
)
