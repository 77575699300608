// Size map for AutoSuggest and AutoSuggestSelect components
const AutoSuggestSizeMap = {
  small: '80px',
  medium: '194px',
  large: '245px',
  largish: '300px',
  xlarge: '407px',
  xxlarge: '650px',
  fullWidth: '100%', // preferably used with flex
}

export default AutoSuggestSizeMap
