/**
 * @generated SignedSource<<199d3b9a6b5a76ac86cc1ec46b2637c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tenantID",
        "variableName": "tenantID"
      }
    ],
    "concreteType": "Facility",
    "kind": "LinkedField",
    "name": "facilities",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "legacyID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FacilitiesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FacilitiesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5d64dbd223495321eb076f33b4ee5cd2",
    "id": null,
    "metadata": {},
    "name": "FacilitiesQuery",
    "operationKind": "query",
    "text": "query FacilitiesQuery(\n  $tenantID: Int!\n) {\n  facilities(tenantID: $tenantID) {\n    id\n    legacyID\n    name\n  }\n}\n"
  }
};
})();

node.hash = "8588ef09ec1e11c34ee63a0f8d8f7a4b";

module.exports = node;
