import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import colors from '@shared/colors'
import { MediaQuery } from '@shared/MediaQuery'

// Navigation list item icon
const NavigationLinkIcon = styled.img`
  flex-shrink: 0;
  height: 20px;
  margin-right: 10px;
`

// Navigation list item link
const NavigationLinkWrapper = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 100%;
  padding: 0 20px;
  color: ${colors.blue.medium};
  text-decoration: none;

  transition: color 0.15s, padding 0.5s; /* Transition between different screen sizes */

  ${MediaQuery.desktopUp`
    padding: 0 40px;
  `};

  ${MediaQuery.largeUp`
    padding: 0 50px;
  `};
`

export { NavigationLinkIcon, NavigationLinkWrapper }
