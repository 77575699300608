// Libraries
import React from 'react'

// Alias Imports
import Flex from '@shared/components/Flex'
import { connectInput } from '@shared/components/Form'
import { UUIDValuePairing } from '@shared/utilities/classes'

// Relative Imports
import AutoSuggest from './AutoSuggest'
import {
  MultiSuggestRemoveIcon,
  MultiSuggestAddIcon,
  MultiSuggestAddText,
  MultiSuggestAddWrapper,
  MultiSuggestSingleWrapper,
} from './styled'

const FormMultiAutoSuggest = connectInput(
  ({ formApi: { value = [new UUIDValuePairing('')], onChange }, addText, ...rest }) => (
    <React.Fragment>
      {value.map((wrappedValue, index) => (
        <MultiSuggestSingleWrapper key={wrappedValue.getUUID()}>
          <Flex parent>
            <Flex flex='5 1 0%'>
              <AutoSuggest
                {...rest}
                value={wrappedValue.get()}
                onChange={(newVal) => {
                  const newArr = [...value]
                  newArr[index] = wrappedValue.clone(newVal)
                  onChange(newArr)
                }}
              />
            </Flex>
            <Flex parent flex='1 1 0%' alignItems='center'>
              {index !== 0 ? (
                <MultiSuggestRemoveIcon
                  onClick={() => {
                    const newArr = [...value]
                    newArr.splice(index, 1)
                    onChange(newArr)
                  }}
                />
              ) : null}
            </Flex>
          </Flex>
        </MultiSuggestSingleWrapper>
      ))}
      <Flex parent>
        <Flex flex='2 1 0%'>
          <MultiSuggestAddWrapper
            onClick={() => {
              onChange([...value, new UUIDValuePairing('')])
            }}
          >
            <MultiSuggestAddIcon />
            <MultiSuggestAddText>{addText}</MultiSuggestAddText>
          </MultiSuggestAddWrapper>
        </Flex>
        <Flex flex='4 1 0%' />
      </Flex>
    </React.Fragment>
  ),
  { bundleApi: true },
)

export default FormMultiAutoSuggest
