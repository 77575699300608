// Libraries
import styled from 'styled-components'
// Alias Imports
import { Typography, withStyles } from '@material-ui/core'
import colors from '@shared/colors'

const BigText = withStyles({
  body1: { color: colors.grey.medium, fontSize: '144px', fontWeight: '600' },
})(Typography)

const MediumText = withStyles({
  body1: { color: colors.black, fontSize: '22px', fontWeight: 'bold' },
})(Typography)

const ParagraphText = withStyles({
  body1: { color: colors.black, fontSize: '18px', margin: '15px 0 30px' },
})(Typography)

const PageWrapper = styled.div`
  width: 620px;
  margin: 90px auto 0;
  text-align: center;
`

const GoBack = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.blue.link};
`

export { BigText, MediumText, ParagraphText, PageWrapper, GoBack }
