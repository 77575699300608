// Libraries
import React from 'react'
import { SvgIcon } from '@material-ui/core'

// Alias Imports
import colors from '@shared/colors'

function KeyboardArrowRight(props) {
  const { disabled = false } = props

  return (
    <SvgIcon viewBox='0 0 22 26' {...props}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='square'>
        <g
          transform='translate(-1140.000000, -586.000000)'
          strokeWidth='3'
          stroke={disabled ? colors.grey.medium : '#000000'}
        >
          <g transform='translate(116.000000, 454.000000)'>
            <g id='Table-Row' transform='translate(13.000000, 131.000000)'>
              <g transform='translate(1018.000000, 11.500000) rotate(90.000000) translate(-1018.000000, -11.500000) translate(1010.500000, 7.500000)'>
                <polyline
                  transform='translate(7.500000, 4.000000) rotate(-180.000000) translate(-7.500000, -4.000000) '
                  points='0 0 7.5 8 15 0'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export default KeyboardArrowRight
