// NOTE: When adding colors, follow the following naming convention:
//
//  - Brand Colour
//    - Brand colours should follow the primary/secondary pattern, as a brand
//      will usually only consist of several main colours
//  - Color Family
//    - Color families consist of 4 colours that relate to a single "base" color.
//      These should always be named "light", "medium", "base", and "dark" to
//      signify their relation to the base color. This way, small future changes
//      require only a single update as well as no confusion when the name changes.
//  - Grey Colours
//    - The grey colour scheme has already been determined and adding another
//      colour is highly discouraged.
//  - Other
//    - Other colors should be clearly documented

const colors = {
  brand: {
    primary: '#00695C',
    secondary: '#EAF7F6',
    followup: '#207C71',
    investors: '#5F894A',
    simulator: '#315B80',
    stoneDecisionEngine: '#0786C1',
    treatment: '#4A4A4A',
    billing: '#206627',
    physicianNarrative: '#8C6B0F',
    qaReports: '#ffcb09',
    library: '#A11841',
    advisors: '#753F91',
    inventory: '#405160',
  },
  grey: {
    dark: '#212121',
    medium: '#9E9E9E',
    light: '#D4D4D4',
    mediumLight: '#F4F4F4',
    lightest: '#FAFAFA',
  },
  red: {
    light: '#FBE9E9',
    mediumLight: '#FF3333',
    medium: '#D50000',
    dark: '#9B0000',
  },
  green: {
    light: '#E8F5E9',
    mediumLight: '#69D641',
    medium: '#36A30E',
    base: '#36A30E',
    dark: '#037000',
  },
  teal: {
    light: '#80CBC4',
    mediumLight: '#4DB6AC',
    medium: '#26A69A',
    base: '#26A69A',
    dark: '#009688',
  },
  blue: {
    lightest: '#F5F8FF',
    light: '#E9F0FF',
    mediumLight: '#5C95FF',
    base: '#007AFF',
    medium: '#5381FF',
    mediumDark: '#002FCC',
    dark: '#2962FF',
    link: '#5381FF',
  },
  turquoise: {
    light: '#EDF7F6',
    medium: '#39D1C3',
    dark: '#4FB6AC',
  },
  orange: {
    light: '#FFEEE5',
    medium: '#FF8433',
    mediumDark: '#E65100',
    dark: '#B31E00',
  },
  white: '#FFFFFF',
  black: '#000000',
}

export default colors
