import * as React from 'react'
import { Dialog, DialogTitle, withStyles } from '@material-ui/core'
import styled from 'styled-components'

const Body = styled.div`
  text-align: center;
`

const styles = {
  dialogPaper: {
    width: 515,
    padding: '50px 70px',
  },
  title: {
    padding: 0,
    marginBottom: 20,
    textAlign: 'center',
  },
}

function Modal({ classes, title, children, ...rest }) {
  return (
    <Dialog
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      classes={{ paper: classes.dialogPaper }}
      {...rest}
    >
      <DialogTitle classes={{ root: classes.title }}>{title}</DialogTitle>
      <Body>{children}</Body>
    </Dialog>
  )
}

export default withStyles(styles)(Modal)
