import React from 'react'

import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  withStyles,
} from '@material-ui/core'
import { Popover } from '@shared/components'

import {
  MuiTableWrapper,
  TablePaginationWrapper,
  CellRowWrapper,
  StyledAnchorElement,
} from './styled'
import TablePaginationActionsWrapped from './TablePaginationActions'

const TableHCell = withStyles({
  root: {
    whiteSpace: 'nowrap',
  },
})(TableCell)

const StyledTypography = withStyles({
  body1: {
    '&:not(:first-child)': { marginTop: '10px' },
    fontSize: '16px',
  },
})(Typography)

function parseCellData(cellData) {
  if (Array.isArray(cellData) && cellData.length > 1) {
    return [
      cellData[0],
      <Popover
        key='popover'
        hover
        anchorElement={<StyledAnchorElement>+{cellData.length - 1}</StyledAnchorElement>}
      >
        <CellRowWrapper>
          {cellData.map((cellRow, key) => (
            <StyledTypography key={`cellPopover${key}`}>{cellRow}</StyledTypography>
          ))}
        </CellRowWrapper>
      </Popover>,
    ]
  } else if (Array.isArray(cellData) && cellData.length === 1) {
    return cellData[0]
  } else if (!Array.isArray(cellData) && cellData) {
    return cellData
  } else {
    return '--'
  }
}

/**
 * Table component - Imported and styled from Mui Component, used on Portal
 * @param {boolean}  clickable       - Deciding if the rows are clickable or not
 * @param {object[]}  headers        - Column names, ie:
 *                                      [{ id: 'fName', label: 'First Name' }]
 * @param {object[]}  rows           - Array of row data, ie:
 *                                      [{ fName: 'Jake' }, {fName: 'Amy'}]
 *                                     Keys in rows (ie: fName) are ids in headers
 * @param {function} onRowClick      - This function will be triggered when a row is clicked.
 *                                     If clickable === false this function is negated
 * @param {string} margin            - CSS styling, use CSS syntax, ie: margin="20px 40px 80px"
 *
 * @param {object} emptyRenderer     - UI components displayed when table has no data
 */

class Table extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 5,
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  render() {
    const {
      clickable = false,
      rows = [],
      headers = [],
      onRowClick = () => void 0,
      margin,
      emptyRenderer,
      physicianTable,
    } = this.props
    const { page, rowsPerPage } = this.state

    let tableContent = (
      <MuiTable>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableHCell style={header.style || null} key={header.id}>
                {header.label}
              </TableHCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((rowData, key) => {
            return (
              <TableRow
                onClick={(e) => {
                  if (clickable) onRowClick(e, rowData, key)
                }}
                style={clickable ? { cursor: 'pointer' } : null}
                key={key}
                hover={clickable}
              >
                {headers.map((header) => (
                  <TableCell key={`${header.id}${key}`}>
                    {parseCellData(rowData[header.id])}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </MuiTable>
    )

    if (!rows.length) {
      tableContent = emptyRenderer
    }

    if (physicianTable) {
      return <MuiTableWrapper margin={margin}>{tableContent}</MuiTableWrapper>
    }

    return (
      <React.Fragment>
        <MuiTableWrapper margin={margin}>{tableContent}</MuiTableWrapper>
        <TablePaginationWrapper>
          <TablePagination
            component='span'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActionsWrapped}
          />
        </TablePaginationWrapper>
      </React.Fragment>
    )
  }
}

export default Table
export { parseCellData }
