import { compose, withHandlers, withState } from 'recompose'

/**
 * Manage the focus state of a component
 * @param {boolean}  focused  - Whether the component has focus
 * @param {function} onBlur   - Callback function for 'onBlur'
 * @param {function} onFocus  - Callback function for 'onFocus'
 * @param {function} setFocus - Set the focus manually
 */
const focusEnhancer = compose(
  withState('focused', 'setFocus', false),
  withHandlers({
    /**
     * Remove the focus state and handle provided callbacks
     * @param  {object} props Component props
     */
    onBlur: (props) => () => {
      props.setFocus(false)

      // Call 'onBlur' (if provided in props) after updating the focus state
      if (props.onBlur) props.onBlur()
    },
    /**
     * Add the focus state and handle provided callbacks
     * @param  {object} props Component props
     */
    onFocus: (props) => () => {
      // Disabled components should not be focusable
      if (props.disabled) return

      props.setFocus(true)

      // Call 'onFocus' (if provided in props) after updating the focus state
      if (props.onFocus) props.onFocus()
    },
  }),
)

export default focusEnhancer
