import styled from 'styled-components'

import colors from '@shared/colors'

// Header content
const AppHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

// Header logo image
const AppHeaderLogo = styled.img`
  height: 40px;
  cursor: pointer;

  /* Enlarge the Logo when printing */
  @media print {
    height: 50px;
  }
`

// Header burger menu icon
const AppHeaderMenuIcon = styled.img`
  margin-right: 20px;
  cursor: pointer;
`

// Header wrapper
const AppHeaderWrapper = styled.header`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  height: 60px;
  width: 100%;
  background-color: ${colors.white};
  color: ${colors.black};
  -webkit-box-shadow: 0 10px 6px -6px ${colors.grey.light};
  -moz-box-shadow: 0 10px 6px -6px ${colors.grey.light};
  box-shadow: 0 10px 6px -6px ${colors.grey.light};
`

// App header product name (branding)
const AppHeaderProductName = styled.div`
  margin-left: 17px;
  padding: 10px 15px;
  border-left: 1px solid #d4d4d4;
  font-size: 20px;
`

const AppHeaderAside = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`

export {
  AppHeaderContent,
  AppHeaderLogo,
  AppHeaderMenuIcon,
  AppHeaderProductName,
  AppHeaderWrapper,
  AppHeaderAside,
}
