import _ from 'lodash'

// Validation
const defaultFormError = { general: undefined, others: [] }

function updateOtherTextboxError(error, formError = defaultFormError, index) {
  const newOthers = [...formError.others]
  newOthers[index] = error

  return {
    ...formError,
    others: newOthers,
  }
}

function removeOtherError(index, formError = defaultFormError) {
  const newOthers = [...formError.others]
  newOthers.splice(index, 1)

  return {
    ...formError,
    others: newOthers,
  }
}

function clearOtherErrors(formError = defaultFormError) {
  return {
    ...formError,
    others: [],
  }
}

function checkboxOtherHasNormalValues(formValue) {
  if (!_.isEmpty(_.get(formValue, 'values'))) {
    return true
  }

  return false
}

function checkboxOtherHasOtherValues(formValue) {
  if (!_.isEmpty(_.get(formValue, 'others'))) {
    return true
  }

  return false
}

function checkboxOtherHasValues(formValue) {
  if (checkboxOtherHasNormalValues(formValue) || checkboxOtherHasOtherValues(formValue)) {
    return true
  }

  return false
}

function checkboxOtherHasValueOrNone(formValue) {
  if (checkboxOtherHasValues(formValue) || formValue === 'None') {
    return true
  }

  return false
}

// Control Mappers
const checkboxControlMappers = {
  basic: {
    getChecked: (formValues = false) => formValues,
    getToggledFormValues: (formValues = false) => !formValues,
    getDisabled: (formValues, value, disabled) => disabled,
  },
  noneOrList: {
    getChecked: (formValues = [], value) => {
      if (formValues === 'None') {
        return value === formValues
      } else if (Array.isArray(formValues)) {
        return formValues.includes(value)
      }

      return false
    },
    getToggledFormValues: (formValues = [], value) => {
      let _formValues = formValues

      if (value === 'None') {
        if (formValues === 'None') {
          _formValues = []
        } else {
          _formValues = 'None'
        }
      } else if (Array.isArray(formValues)) {
        const index = formValues.indexOf(value)

        _formValues = formValues.slice()

        if (index === -1) {
          _formValues.push(value)
        } else {
          _formValues.splice(index, 1)
        }
      } else {
        _formValues = [value]
      }

      return _formValues
    },
    getDisabled: (formValues, value, disabled) => {
      if (formValues === 'None' && value !== 'None') {
        return true
      }

      return disabled
    },
  },
  noneOrObject: {
    getChecked: (formValues = { values: [] }, value) => {
      if (formValues === 'None') {
        return value === formValues
      }

      // Protect agains invalid formValues types
      if (!_.isPlainObject(formValues)) {
        return false
      }

      const { values = [], others } = formValues

      if (value === 'others' && others) {
        return true
      }

      return Boolean(values.includes(value))
    },
    getToggledFormValues: (formValues = { values: [] }, value, formError, setError) => {
      if (formValues === 'None') {
        if (value === 'None') {
          return { values: [] }
        }

        return formValues
      }

      if (value === 'None') {
        return 'None'
      }

      let _formValues = formValues

      // Protect agains invalid formValues types
      if (!_.isPlainObject(formValues)) {
        _formValues = { values: [] }
      }

      const { values, others } = _formValues

      if (value === 'others') {
        if (others) {
          return { values }
        }

        setError(clearOtherErrors(formError))
        return { values, others: [''] }
      }

      const index = values.indexOf(value)
      const newValues = values.slice()

      if (index === -1) {
        newValues.push(value)
      } else {
        newValues.splice(index, 1)
      }

      return { values: newValues, others }
    },
    getDisabled: (formValues = { values: [] }, value, disabled) => {
      if (formValues === 'None' && value !== 'None') {
        return true
      }

      return disabled
    },
  },
}

// This returns true if passed an array with items in it.
const noneOrListHasValues = (value) => Array.isArray(value) && value.length > 0

/*
  This runs an includes on the first parameter with the second parameter as its
  argument if the first parameter is an array.

  Returns false if the first parameter is not an array, returns the result of
  the includes otherwise.
*/
const noneOrListIncludes = (arr, value) => Array.isArray(arr) && arr.includes(value)

const noneOrObjectHasValues = (value) =>
  value !== 'None' && _.isPlainObject(value) && (value.others || noneOrListHasValues(value.values))

const noneOrObjectIncludes = (noneOrObj, value) =>
  noneOrObj !== 'None' && _.isPlainObject(noneOrObj) && noneOrListIncludes(noneOrObj.values, value)

/**
 * @description Checkbox others validation utils
 * @param {Object} formValue
 */
const validateCheckboxOthers = (formValue) => {
  const formError = {
    general: undefined,
    others: [],
  }

  if (!checkboxOtherHasValueOrNone(formValue)) {
    formError.general = 'Required'
  } else if (checkboxOtherHasOtherValues(formValue)) {
    const duplicateChecker = {}

    formValue.others.forEach((name) => {
      let otherError

      if (_.isEmpty(name)) {
        otherError = 'Required'
        formError.general = 'Invalid values'
      } else if (duplicateChecker[name]) {
        otherError = "Can't have duplicates."
        formError.general = 'Invalid values'
      } else {
        duplicateChecker[name] = true
      }

      formError.others.push(otherError)
    })
  }

  return formError.general ? formError : undefined
}

/**
 * @description This updates an errors object with the errors for checkbox with others fields based on their current values.
 * @param {String[]} fields The fields to validate
 * @param {Object} values The values object containing the different checkbox with others fields to validate
 * @param {Object} currentErrors The current errors object with the current errors for each of the checkbox with others fields
 */
const checkboxOthersUpdateErrors = (fields, values, currentErrors) => {
  let errors = currentErrors
  let hasErrors = false

  fields.forEach((field) => {
    const checkboxOthersErrors = validateCheckboxOthers(values[field])

    if (checkboxOthersErrors) {
      errors = {
        ...errors,
        [field]: checkboxOthersErrors,
      }

      hasErrors = true
    }
  })

  return { errors, hasErrors }
}

export {
  checkboxControlMappers,
  noneOrListHasValues,
  noneOrListIncludes,
  noneOrObjectHasValues,
  noneOrObjectIncludes,
  updateOtherTextboxError,
  removeOtherError,
  checkboxOtherHasNormalValues,
  checkboxOtherHasOtherValues,
  checkboxOtherHasValues,
  checkboxOtherHasValueOrNone,
  checkboxOthersUpdateErrors,
}
