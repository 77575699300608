import colors from '@shared/colors'

const theme = {
  // Basic box shadow
  boxShadow: `0 2px 4px 0 ${colors.grey.medium}`,
  // Focus outline for components
  outline: `0 0 6px 1px ${colors.blue.base}`,
  // Focus outline for components with errors
  outlineError: `0 0 6px 1px ${colors.red.medium}`,
}

export default theme
