import axios from 'axios'
import serverConfig from '@app/config/serverConfig'
import keycloak from '@app/keycloak'

export default (url, method = 'get', options = {}) => {
  const addSlash = url && url.substring(0, 1) !== '/' ? '/' : ''

  const { headers, ...rest } = options

  return axios({
    url: `${serverConfig.API_URL}${addSlash}${url}`,
    method: method,
    withCredentials: true,
    crossdomain: true,
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      ...headers,
    },
    ...rest,
  })
}
