import * as React from 'react'

import operaLogo from '@images/icons/opera.svg'
import firefoxLogo from '@images/icons/firefox.svg'
import chromeLogo from '@images/icons/chrome.svg'
import edgeLogo from '@images/icons/edge.svg'

// Relative Imports
import { LogoContainer, LogoRow, LogoWrapper } from './container'

const ModernBrowserLogos = () => (
  <LogoContainer>
    <LogoRow>
      <LogoWrapper alt='ie-logo' src={chromeLogo} />
      <LogoWrapper alt='firefox-logo' src={firefoxLogo} />
    </LogoRow>
    <LogoRow>
      <LogoWrapper alt='edge-logo' src={edgeLogo} />
      <LogoWrapper alt='opera-logo' src={operaLogo} />
    </LogoRow>
  </LogoContainer>
)

export default ModernBrowserLogos
