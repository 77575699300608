import styled from 'styled-components'

import colors from '@shared/colors'

// Close icon
const CloseIcon = styled.img`
  position: absolute;
  top: 25px;
  right: 25px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  z-index: 1; /* Bring above the wrapper div (because of absolute position) */
`

// Menu wrapper (inside Drawer)
const DrawerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 75px;

  min-width: 435px;
  @media (max-height: 800px) {
    overflow-y: scroll;
    min-height: 700px;
  }

  @media (min-height: 800px) {
    height: 100%;
  }
`

// Profile image
const ProfileImage = styled.img`
  height: 68px;
`

// Profile image container (circle)
const ProfileImageCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 113px;
  width: 113px;
  border-radius: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  background-color: ${colors.grey.mediumLight};
`

// Profile name display (beneath profile image)
const ProfileName = styled.h2`
  margin: 26px 0 20px 0;
  font-size: 24px;
  font-weight: normal;
`

export { CloseIcon, DrawerWrapper, ProfileImage, ProfileImageCircle, ProfileName }
