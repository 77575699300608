import styled, { css } from 'styled-components'

// Each device goes up to the specified value/width
//  (ie. "phone" is from 0 - 640 px)
const QuerySizes = {
  large: 1024,
  desktop: 992,
  tablet: 768,
  phone: 640,
}

// Idea taken from styled-components.com
//  https://github.com/styled-components/styled-components/blob/master/docs/tips-and-tricks.md#media-templates
const MediaQuery = Object.keys(QuerySizes).reduce((accumulator, query, idx, array) => {
  const size = QuerySizes[query]

  // Get parent query and size (may be undefined)
  const parentQuery = array[idx - 1]
  const parentSize = QuerySizes[parentQuery]

  // Create an object with all media query types
  const MediaQueryStrings = {}

  // An undefined parentSize indicates that there is no "larger" parent query size.
  //  This means that "largeDown" should apply to everything (it is the top size)
  //  Additionally, this means that "largeOnly" and "largeUp" are technically the same thing

  MediaQueryStrings[`${query}Up`] =
    parentSize !== undefined ? `(min-width: ${size}px)` : `(min-width: ${size}px)`

  MediaQueryStrings[`${query}Down`] =
    parentSize !== undefined ? `(max-width: ${parentSize - 1}px)` : '(min-width: 0px)'

  MediaQueryStrings[`${query}Only`] =
    parentSize !== undefined
      ? `(min-width: ${size}px) and (max-width: ${parentSize - 1}px)`
      : `(min-width: ${size}px)`

  // Generate the media queries dynamically
  //  Each query type (up, down, only) has its associated media query strings (above)
  //  This creates a media query for that range and adds it to the MediaQuery object
  Object.keys(MediaQueryStrings).forEach((key) => {
    accumulator[key] = (...args) => css`
      @media ${MediaQueryStrings[key]} {
        ${css(...args)};
      }
    `
  })

  // The final output is an object with a media query for each breakpoint and type
  //  Usage:   ${MediaQuery.largeUp`background-color: red;`};
  return accumulator
}, {})

// Container for top-level components that take up the app size
const AppMediaQuery = styled.div`
  display: flex;
  margin: 0 20px;
  max-width: ${QuerySizes.large}px;
  width: 100%;

  ${MediaQuery.desktopDown`
    padding: 0 20px;
    margin: 0 auto;
  `};

  /* Use full screen when printing PDF */
  @media print {
    max-width: 100%;
  }
`

export { AppMediaQuery, MediaQuery, QuerySizes }
