import * as React from 'react'
import { Typography, withStyles } from '@material-ui/core'

const unitStyles = {
  wrapper: {
    marginTop: 30,
  },
  smallLabelSpacing: {
    marginBottom: 3,
  },
  mediumLabelSpacing: {
    marginBottom: 8,
  },
  largeLabelSpacing: {
    marginBottom: 20,
  },
}

function Unit({ children, classes, label, labelSpacing = 'small', style, noWrapperStyle = false }) {
  const { wrapper, ...labelSpacingClasses } = classes

  return (
    <div className={noWrapperStyle ? null : wrapper} style={style}>
      {label ? (
        <Typography
          classes={{ root: labelSpacingClasses[`${labelSpacing}LabelSpacing`] }}
          variant='subheading'
        >
          {label}
        </Typography>
      ) : null}
      {children}
    </div>
  )
}

export default withStyles(unitStyles)(Unit)
