import * as React from 'react'
import styled from 'styled-components'

import { Button } from '@shared/components'

const Input = styled.input.attrs({ type: 'file' })`
  display: none;
`

class FileInput extends React.Component {
  static defaultProps = {
    children: 'Select File',
  }

  inputRef = React.createRef()

  openFileDialog = () => {
    const current = this.inputRef.current
    if (current) {
      current.click()
    }
  }

  render() {
    const { onChange, accept, multiple, children, fullWidth } = this.props

    return (
      <React.Fragment>
        <Button fullWidth={fullWidth} onClick={this.openFileDialog}>
          {children}
        </Button>
        <Input innerRef={this.inputRef} accept={accept} multiple={multiple} onChange={onChange} />
      </React.Fragment>
    )
  }
}

export default FileInput
