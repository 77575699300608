const envs = process.env

const config = {
  GOOGLE_ANALYTICS_TRACKING_ID: envs.PORTAL_APP_GOOGLE_ANALYTICS_TRACKING_ID,
  SENTRY_APP_PUBLIC_DSN: envs.PORTAL_APP_SENTRY_PUBLIC_DSN,
  SENTRY_RELEASE: envs.PORTAL_APP_SENTRY_RELEASE,
  SENTRY_ENVIRONMENT: envs.PORTAL_APP_SENTRY_ENVIRONMENT,
  KEYCLOAK_AUTH_SERVER_URL: envs.KEYCLOAK_AUTH_SERVER_URL,
  KEYCLOAK_DEFAULT_LOGOUT_REDIRECT_URL: envs.KEYCLOAK_DEFAULT_LOGOUT_REDIRECT_URL,
}

export default config
