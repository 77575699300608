import React from 'react'

import { Unit, Flex } from '@shared/components'
import IndividualPdf from './IndividualPdf'
import Typography from '@material-ui/core/Typography'

function PdfWidget(props) {
  const { subsidiaryId, subsidiaryName, subsidiaryReports } = props

  const {
    income_statement: { name: incomeStatementFileName } = {},
    balance_sheet: { name: balanceSheetFileName } = {},
    facility_update: { name: facilityUpdateFileName } = {},
  } = subsidiaryReports

  return (
    <div>
      <Unit>
        <Flex>
          <Typography component='h4'>{subsidiaryName}</Typography>
          <hr />
          <IndividualPdf
            subsidiaryId={subsidiaryId}
            subsidiaryName={subsidiaryName}
            reportDescription='Income Statement'
            fileName={incomeStatementFileName}
          />
          <IndividualPdf
            subsidiaryId={subsidiaryId}
            subsidiaryName={subsidiaryName}
            reportDescription='Balance Sheet'
            fileName={balanceSheetFileName}
          />
          <IndividualPdf
            subsidiaryId={subsidiaryId}
            subsidiaryName={subsidiaryName}
            reportDescription='Facility Update'
            fileName={facilityUpdateFileName}
          />
        </Flex>
      </Unit>
    </div>
  )
}

PdfWidget.defaultProps = {
  subsidiaryReports: {},
}

export default PdfWidget
