import * as React from 'react'
import { MenuItem, withStyles, IconButton, ButtonBase } from '@material-ui/core'
import _ from 'lodash'
import styled from 'styled-components'

import Checkbox from '@shared/components/Checkbox'
import Textbox from '@shared/components/Textbox'
import RemoveIcon from '@images/iconComponents/remove'
import AddIcon from '@images/iconComponents/add'
import colors from '@shared/colors'
import { connectInput } from '@shared/components/Form'
import Flex from '@shared/components/Flex'

import Select from '../select'
import {
  updateTextboxError,
  updateOtherTextboxError,
  updateSecondOtherTextboxError,
  removeError,
  removeOtherError,
  defaultFormError,
} from './validation'

const OtherFlexWrapper = styled(Flex)`
  min-width: 0px;
  margin-right: 20px;
`

const OptionHeader = styled.div`
  margin: 20px 0 5px;
`

function protectAgainstInvalidValueType(value, defaultValue = { values: [] }) {
  if (value !== 'None' && (!_.isPlainObject(value) || !_.isArray(value.values))) {
    return defaultValue
  }

  return value
}

function defaultTextboxValidate(value) {
  return value
}

const styles = {
  bold: {
    fontWeight: 'bold',
  },
  addRow: {
    color: colors.blue.dark,
    marginTop: 10,
  },
}

const FormMultiSelectBase = ({
  formApi,
  defaultValue,
  classes,
  textBoxPlaceholder,
  otherTextBoxPlaceholder,
  secondOtherTextBoxPlaceholder,
  textBoxEndAdornment,
  otherTextBoxEndAdornment,
  secondOtherTextBoxEndAdornment,
  textBoxValidate = defaultTextboxValidate,
  otherTextBoxValidate = defaultTextboxValidate,
  secondOtherTextBoxValidate = defaultTextboxValidate,
  items,
  ...rest
}) => {
  const { onChange, error: formError, setError } = formApi
  const formValue = protectAgainstInvalidValueType(formApi.value, defaultValue)

  let selectedItems
  let otherFields

  if (formValue === 'None') {
    selectedItems = ['None']
  } else {
    const { values } = formValue
    selectedItems = values.map(({ name }) => name)

    const otherItems = formValue.others
    if (otherItems) {
      selectedItems.push('others')

      otherFields = [
        <OptionHeader key='other-header'>
          <b className={classes.bold}>Other</b>
        </OptionHeader>,
      ]

      otherFields = otherFields.concat(
        otherItems.map((otherItem, index) => {
          const { name, value } = otherItem

          return (
            // No way to generate a valid key for this
            // eslint-disable-next-line react/jsx-key
            <Flex parent>
              <OtherFlexWrapper flex='4 1 0%'>
                <Textbox
                  fullWidth
                  value={name}
                  placeholder={otherTextBoxPlaceholder}
                  endAdornment={otherTextBoxEndAdornment}
                  onChange={(e) => {
                    const newOtherItems = [...otherItems]
                    newOtherItems[index] = {
                      name: otherTextBoxValidate(e.target.value, name, (error) =>
                        setError(updateOtherTextboxError(error, formError, index)),
                      ),
                      value,
                    }

                    onChange({ ...formValue, others: newOtherItems })
                  }}
                  error={_.get(formError, ['others', index.toString(), 'name'])}
                />
              </OtherFlexWrapper>
              <OtherFlexWrapper flex='1 1 0%'>
                <Textbox
                  fullWidth
                  value={value}
                  placeholder={secondOtherTextBoxPlaceholder}
                  endAdornment={secondOtherTextBoxEndAdornment}
                  onChange={(e) => {
                    const newOtherItems = [...otherItems]
                    newOtherItems[index] = {
                      name,
                      value: secondOtherTextBoxValidate(e.target.value, value, (error) =>
                        setError(updateSecondOtherTextboxError(error, formError, index)),
                      ),
                    }

                    onChange({ ...formValue, others: newOtherItems })
                  }}
                  error={_.get(formError, ['others', index.toString(), 'value'])}
                />
              </OtherFlexWrapper>
              <OtherFlexWrapper flex='1 1 0%'>
                {otherItems.length > 1 ? (
                  <IconButton
                    disableRipple
                    onClick={() => {
                      const newOtherItems = [...otherItems]
                      newOtherItems.splice(index, 1)

                      onChange({ ...formValue, others: newOtherItems })
                      setError(removeOtherError(index, formError))
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                ) : null}
              </OtherFlexWrapper>
              <Flex flex='4 1 0%' />
            </Flex>
          )
        }),
      )

      otherFields.push(
        <Flex key='add row' parent alignItems='center' className={classes.addRow}>
          <ButtonBase
            onClick={() => {
              const newOtherItems = [...otherItems]
              newOtherItems.push({
                name: '',
                value: '',
              })

              onChange({ ...formValue, others: newOtherItems })
            }}
          >
            <AddIcon />
            Add Row
          </ButtonBase>
        </Flex>,
      )
    }
  }

  return (
    <React.Fragment>
      <Flex parent>
        <Select
          value={selectedItems}
          onChange={(e) => {
            const newKeys = e.target.value

            if (formValue === 'None') {
              if (_.isEmpty(newKeys)) {
                onChange({ values: [] })
              }
            } else if (newKeys.includes('None')) {
              onChange('None')
              setError(defaultFormError)
            } else {
              const removedKeys = _.difference(selectedItems, newKeys)
              if (!_.isEmpty(removedKeys)) {
                setError(
                  _.reduce(
                    removedKeys,
                    (_formError, removedKey) => {
                      if (removedKey === 'others') {
                        return {
                          ..._formError,
                          others: [],
                        }
                      }

                      return removeError(removedKey, _formError)
                    },
                    formError,
                  ),
                )
              }
              onChange(
                newKeys.reduce(
                  (acc, name) => {
                    if (name === 'others') {
                      acc.others = formValue.others || [{ name: '', value: '' }]
                    } else {
                      const { values } = formValue

                      const newObj = values.find(({ name: _name }) => _name === name) || {
                        name,
                        value: '',
                      }

                      acc.values.push(newObj)
                    }

                    return acc
                  },
                  { values: [] },
                ),
              )
            }
          }}
          {...rest}
          multiple
          style={{ flex: '3 1 0%' }}
        >
          {items.map(({ value: name, label }) => {
            let checked

            if (formValue === 'None') {
              checked = name === 'None'
            } else {
              checked = selectedItems.includes(name)
            }

            return (
              <MenuItem key={name} value={name} disabled={formValue === 'None' && name !== 'None'}>
                <Checkbox
                  checked={checked}
                  value={name}
                  size='small'
                  disabled={formValue === 'None' && name !== 'None'}
                />
                {label}
              </MenuItem>
            )
          })}
        </Select>
        <Flex flex='7 1 0%' />
      </Flex>
      <React.Fragment>
        {formValue !== 'None' &&
          formValue.values.map(({ name, value }, index) => {
            if (name === 'others') {
              return null
            }

            return (
              <React.Fragment key={name}>
                <OptionHeader>
                  <b className={classes.bold}>{name}</b>
                </OptionHeader>
                <Flex parent>
                  <Textbox
                    fullWidth
                    placeholder={textBoxPlaceholder}
                    value={value}
                    onChange={(e) => {
                      const newValues = [...formValue.values]

                      newValues[index] = {
                        name,
                        value: textBoxValidate(e.target.value, value, (error) =>
                          setError(updateTextboxError(error, formError, name)),
                        ),
                      }

                      onChange({
                        ...formValue,
                        values: newValues,
                      })
                    }}
                    error={_.get(formError, ['values', name])}
                    endAdornment={textBoxEndAdornment}
                    style={{ flex: '1 1 0%' }}
                  />
                  <Flex flex='9 1 0%' />
                </Flex>
              </React.Fragment>
            )
          })}
        {otherFields}
      </React.Fragment>
    </React.Fragment>
  )
}

FormMultiSelectBase.defaultProps = {
  defaultValue: { values: [] },
  textBoxPlaceholder: '',
  otherTextBoxPlaceholder: '',
  secondOtherTextBoxPlaceholder: '',
  textBoxEndAdornment: undefined,
  otherTextBoxEndAdornment: undefined,
  secondOtherTextBoxEndAdornment: undefined,
  textBoxValidate: defaultTextboxValidate,
  otherTextBoxValidate: defaultTextboxValidate,
  secondOtherTextBoxValidate: defaultTextboxValidate,
}

const FormMultiSelect = connectInput(FormMultiSelectBase, {
  defaultName: 'select',
  bundleApi: true,
})

export default withStyles(styles)(FormMultiSelect)
