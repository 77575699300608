import * as React from 'react'
import { FormHelperText } from '@material-ui/core'

import { connectInput } from '@shared/components/Form'

function ErrorText({ children, showWhenEmpty }) {
  const show = showWhenEmpty || children

  return show ? <FormHelperText error>{children}</FormHelperText> : null
}

const FormErrorText = connectInput(
  ({ formApi: { error }, ...rest }) => <ErrorText {...rest}>{error}</ErrorText>,
  { bundleApi: true, defaultName: 'form' },
)

export default ErrorText
export { FormErrorText }
