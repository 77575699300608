/**
 * @generated SignedSource<<0524396dcdb16426b50d20f22dd621e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppHeader_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "groups",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

node.hash = "c93aeefdcfd1cf5ba283f40995cd61bc";

module.exports = node;
