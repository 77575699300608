import Keycloak from 'keycloak-js'
import config from '@app/config/env'

// Static values (for each app)
const _CLIENT_ID = 'portal'
const _ALLOWED_REALMS = 'NextMed,northernlitho'
const _DEFAULT_REALM = 'NextMed'
const _LOCAL_KEYCLOAK_AUTH_SERVER_URL = 'http://localhost:8081/auth'
const _LOCAL_APP_LOCATION_URL_ENCODED = 'http%3A%2F%2Flocalhost%3A3200%2F'

// Route add-on used by the keycloak server login page to trigger realm change
const _CHANGE_REALM_ROUTE = 'changeRealm'

const keycloakRealm = () => {
  const kcRealm = localStorage.getItem('kcRealm')

  // Ensure the realm is in the allowed list
  return _ALLOWED_REALMS.split(',').includes(kcRealm) ? kcRealm : _DEFAULT_REALM
}

// Setup Keycloak instance
const keycloak = new Keycloak({
  realm: keycloakRealm(),
  url: config.KEYCLOAK_AUTH_SERVER_URL || _LOCAL_KEYCLOAK_AUTH_SERVER_URL,
  'ssl-required': 'external',
  clientId: _CLIENT_ID,
  'public-client': true,
  'verify-token-audience': true,
  'use-resource-role-mappings': true,
  'confidential-port': 0,
  'enable-pkce': true,
})

keycloak.getLogoutUrl = (redirectUrlTarget) => {
  let redirectUrl = redirectUrlTarget
  // If no redirectURL, assign from environment variable, and default to local config
  if (!redirectUrl && config.KEYCLOAK_DEFAULT_LOGOUT_REDIRECT_URL) {
    redirectUrl = `https://${keycloak.realm}.${config.KEYCLOAK_DEFAULT_LOGOUT_REDIRECT_URL}`
  }
  if (!redirectUrl) {
    redirectUrl = _LOCAL_APP_LOCATION_URL_ENCODED
  }

  // This address triggers logout on the keycloak server
  return `${keycloak.authServerUrl}/realms/${keycloak.realm}/protocol/openid-connect/logout?redirect_uri=${redirectUrl}`
}

// Export for replacement on redirect
keycloak.changeRealmRoute = _CHANGE_REALM_ROUTE

// Check and change realm if detected
keycloak.checkForRealmChange = (pathname, queryParamString) => {
  // Check if url includes realm change trigger
  if (!pathname.includes(_CHANGE_REALM_ROUTE)) {
    return false
  }

  // Calculate new realm
  const newRealm = queryParamString
    .substring(queryParamString.search('newRealm') + 'newRealm='.length)
    .split('&')
    .shift()

  // If realm has changed,
  // and new realm is in the allowed list, update
  if (newRealm && keycloak.realm !== newRealm && _ALLOWED_REALMS.split(',').includes(newRealm)) {
    localStorage.setItem('kcRealm', newRealm)
    keycloak.realm = newRealm

    return true
  }

  return false
}

keycloak.getUserInfo = async () => {
  const user = await keycloak.loadUserInfo()
  return user
}

export default keycloak
