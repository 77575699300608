// Libraries
import styled from 'styled-components'

// Alias Imports
import colors from '@shared/colors'

const Section = styled.div`
  margin: 16px 0;
`

const HalfDivider = styled.hr`
  height: 1px;
  margin: 0;
  border: none;
  flex: 1 1 auto;
  background-color: rgba(0, 0, 0, 0.12);
  width: 12em;
  display: inline-block;
`

const DividerWithTextContent = styled.div`
  text-align: 'center';
  margin: 20px 0;
  display: flex;
  align-items: center;
`

const DivideText = styled.span`
  margin: 0 15px;
  flex: 0 0 auto;
  color: ${colors.grey.light};
`

const LogoWrapper = styled.img`
  margin-right: 5px;
`

const LogoRow = styled.div`
  margin-bottom: 5px;
`

const LogoContainer = styled.div`
  display: inline-block;
`

const LogoFrame = styled.div`
  display: inline-block;
  margin-right: 20px;
  float: left;
`

const Paragraph = styled.p`
  line-height: 1.5em;
  padding: ${(props) => (props.padding ? props.padding : undefined)};
  margin-top: 10px;
`

export {
  Section,
  HalfDivider,
  DivideText,
  Paragraph,
  DividerWithTextContent,
  LogoWrapper,
  LogoRow,
  LogoContainer,
  LogoFrame,
}
