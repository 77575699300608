import React from 'react'
import { withRouter, matchPath } from 'react-router-dom'

import { AppMediaQuery } from '@shared/MediaQuery'

import AppNavigationLink from './AppNavigationLink'

import { NavigationListWrapper, NavigationWrapper } from './styled'

/**
 * App navigation menu
 */
const AppNavigation = (props) => {
  const { location } = props

  const matchedPath = matchPath(location.pathname, {
    path: '/:simulationId/:stage',
  })

  const matchedSimulationPath = matchedPath ? `/${matchedPath.params.simulationId}` : ''

  const menuItems = [
    {
      link: `${matchedSimulationPath}/demographics`,
      text: 'Demographics',
      iconMap: '/demographics',
    },
    {
      link: `${matchedSimulationPath}/treatmentstrategy`,
      text: 'Treatment Strategy',
      iconMap: '/treatmentstrategy',
    },
    {
      link: `${matchedSimulationPath}/shockprotocol`,
      text: 'Shock Protocol',
      iconMap: '/shockprotocol',
    },
    {
      link: `${matchedSimulationPath}/summary`,
      text: 'Summary',
      iconMap: '/summary',
    },
  ]

  return (
    <NavigationWrapper className='print--hide'>
      <AppMediaQuery>
        <NavigationListWrapper>
          {menuItems.map((menuItem, idx) => (
            <AppNavigationLink {...menuItem} key={idx} />
          ))}
        </NavigationListWrapper>
      </AppMediaQuery>
    </NavigationWrapper>
  )
}

export default withRouter(AppNavigation)
