/**
  This simple utility function is used to tell if a variable is an object `{}`.

  @param {any} value - The value to check if it is an object or not.
  @return {bool} True if the value is an object, false otherwise.
*/
function isObject(value) {
  return value !== null && typeof value === 'object'
}

/**
  This checks if an object is a section within an errors object.

  @param {object} errors - The object to check if it is a section within an errors
    object.
  @return {bool} True if the object passed is a section within an errors object.
*/
function isSectionErrors(errors) {
  return isObject(errors) && errors.__isSection
}

/*
  This utility function takes a form or section error object and returns a copy
  with the hasNestedErrors keys at each level set based on whether or not any of
  its children have any errors set.

  It does this by stepping through all immediate keys of the errors object.

  If a key is a nested section errors object, it calls this function on it
  (recursively) and then checks for its error key or its hasNestedErrors key.

  If a key is not a nested section, it just checks if it exists.

  @param {object} [errors={}] - The errors object to update the hasNestedErrors
   keys for.
  @return A copy of the errors object with updated keys of hasNestedErrors based
    on the results.
*/
function updateHasNestedErrors(errors) {
  const { nested = {} } = errors

  const nestedSections = {}

  let hasNestedErrors = false

  Object.keys(nested).forEach((input) => {
    let error = nested[input]
    const isSection = isSectionErrors(error)

    if (isSection) {
      error = updateHasNestedErrors(error)
      nestedSections[input] = error
    }

    if ((!isSection && error) || (isSection && (error.hasNestedErrors || error.error))) {
      hasNestedErrors = true
    }
  })

  return {
    ...errors,
    nested: {
      ...nested,
      ...nestedSections,
    },
    hasNestedErrors,
  }
}

/**
  This creates a copy of a tree object and sets the value at the location
  specified by the path. The branches along the path are shallowly coppied.

  Branches of the tree are defaulted to objects if they do not exist.

  @param {Object} [tree={}] - The object tree to copy.
  @param {(string|string[])} path - The path to the value to set within the tree.
  @param {*} value - The value to set within the tree.
  @return {object} The new tree with the set value.
*/
function objectTreeImmutableSet(tree, path, value) {
  if (typeof path === 'string') {
    return objectTreeImmutableSet(tree, [path], value)
  } else if (Array.isArray(path)) {
    if (path.length === 0) {
      return value
    }

    const nextBranchKey = path[0]
    const nextBranch = tree[nextBranchKey]
    const remainingPath = path.slice(1)

    return {
      ...tree,
      [nextBranchKey]: objectTreeImmutableSet(nextBranch, remainingPath, value),
    }
  }

  return tree
}

/**
  This creates a copy of an errors object and sets the error at the location
  specified by the path. The branches along the path are shallowly coppied.

  Branches of the errors object are defaulted to objects if they do not exist.

  @param {Object} [errors={}] - The errors object to copy.
  @param {(string|string[])} path - The path to the error to set within the tree.
  @param {*} error - The error to set within the tree.
  @param {Object} [options={}] - An object with additional execution options.
  @param {bool} [options.setSectionErrors=false] - If this is true, then the end of the
    path will be assumed to land on a section rather than an input, therefore
    setting the errors for the whole section.
  @return {object} The new errors object with the error set.
*/
function deepImmutableSetInputError(errors, path, error, options = {}) {
  if (typeof path === 'string') {
    return deepImmutableSetInputError(errors, [path], error, options)
  } else if (Array.isArray(path)) {
    if (path.length === 0) {
      if (isSectionErrors(errors)) {
        const { setSectionErrors = false } = options
        if (setSectionErrors) {
          return {
            ...errors,
            ...error,
          }
        }

        return {
          ...errors,
          error,
        }
      }

      return error
    }

    const { nested = {} } = errors
    const nextErrorsKey = path[0]
    const nextErrors = nested[nextErrorsKey]
    const remainingPath = path.slice(1)

    return {
      ...errors,
      nested: {
        ...nested,
        [nextErrorsKey]: deepImmutableSetInputError(nextErrors, remainingPath, error, options),
      },
    }
  }

  return errors
}

/**
  This sets the __isSection key at a specific path within an errors object.
  Any object along the path is shallowly coppied instead of directly modified.

  @param {Object} [errors={}] - The errors object.
  @param {(string|string[])} path - The path within the errors object to set the
    __isSection key.
  @return {object} The new errors object with the section initialized.
*/
function initSectionErrors(errors = {}, path) {
  if (typeof path === 'string') {
    initSectionErrors(errors, [path])
  } else if (Array.isArray(path)) {
    if (path.length === 0) {
      return {
        ...errors,
        __isSection: true,
      }
    }

    const nextSectionKey = path[0]
    const remainingPath = path.slice(1)
    const { nested = {} } = errors
    const { [nextSectionKey]: nextSectionErrors } = nested

    return {
      ...errors,
      nested: {
        ...nested,
        [nextSectionKey]: initSectionErrors(nextSectionErrors, remainingPath),
      },
    }
  }

  return errors
}

/**
  This registers a particular section within a sectionRegister object.
  A section is registered by making sure an object exists at it's specific path.

  @param {object} [prevRegister={}] - The register object tree to add the section to.
  @param {(string|string[])} sectionPath - The path to the section to register.
  @return {object} The new register object with the sectioin registered.
*/
function registerSection(prevRegister, sectionPath) {
  if (typeof sectionPath === 'string') {
    registerSection(prevRegister, [sectionPath])
  } else if (Array.isArray(sectionPath)) {
    if (sectionPath.length === 0) {
      return prevRegister
    }

    const nextSectionKey = sectionPath[0]
    const nextSection = prevRegister[nextSectionKey]
    const remainingPath = sectionPath.slice(1)

    return {
      ...prevRegister,
      [nextSectionKey]: registerSection(nextSection, remainingPath),
    }
  }

  return prevRegister
}

/**
  This uses a sectionRegister to flatten a values object into a single level.
  Warning: If multiple sections/inputs share the same name across the tree,
  only one will be in the flattened object and there's no guarantee of which it
  will be.

  @param {object} [sectionRegister={}] - The section register to use as reference.
  @param {object} [values={}] - The values object to flatten.
  @return {object} An object with all the values from the values object on the
    first level.
*/
function flattenValues(sectionRegister, values) {
  let flatValues = {}
  const sections = Object.keys(sectionRegister)
  let remainingValues = values

  sections.forEach((section) => {
    const { [section]: sectionValues, ...newRemainingValues } = remainingValues
    const { [section]: subRegister } = sectionRegister
    remainingValues = newRemainingValues

    flatValues = {
      ...flatValues,
      ...flattenValues(subRegister, sectionValues),
    }
  })

  return {
    ...flatValues,
    ...remainingValues,
  }
}

/**
  This uses a sectionRegister to flatten an errors object into a single level.
  Warning: If multiple sections/inputs share the same name across the tree,
  only one will be in the flattened object and there's no guarantee of which it
  will be.

  @param {object} [sectionRegister={}] - The section register to use as reference.
  @param {object} [errors={}] - The errors object to flatten.
  @param {string} [sectionName="form"] - The the name of the current section/form
    level needed to name the section/form level error.
  @return {object} An object with all the errors from the errors object on the
    first level.
*/
function flattenErrors(sectionRegister, errors, sectionName = 'form') {
  let flatErrors = {}
  const sections = Object.keys(sectionRegister)
  let remainingErrors = errors.nested

  sections.forEach((section) => {
    const { [section]: sectionErrors, ...newRemainingErrors } = remainingErrors
    const { [section]: subRegister } = sectionRegister
    remainingErrors = newRemainingErrors

    flatErrors = {
      ...flatErrors,
      ...flattenErrors(subRegister, sectionErrors, section),
    }
  })

  return {
    ...flatErrors,
    ...remainingErrors,
    [sectionName]: errors.error,
  }
}

/**
  This callback type is the signature for validate functions used within the
  Form or Sections components of the Form.

  @callback confirmFormValidateCallback
  @param {any} confirmedValues - The values to confirm validation for.
*/

/**
  This callback type is the signature for validate functions used within the
  Form or Sections components of the Form.

  @callback formValidateCallback
  @param {confirmFormValidateCallback} confirmChange - The function to call to
    set the final values during validation.
  @param {(string|string[])} inputPath - The path in the value tree to get to the
    values to change.
  @param {object} nextFormApi - The Form API should the validation pass.
*/

/**
  This function runs the Form validation process.

  @param {object} prevFormApi - This is given to the values if it is a function
    and it is used to generate the next Form API to give to the validate function.
  @param {(any|formStateUpdaterCallback)} values - The values to validate. Can
    be a function that returns the values to validate.
  @param {(string|string[])} inputPath - The path in the value tree to get to the
    values to change. This is required for the validate function.
  @param {formValidateCallback} validate - The function that runs validation.
    The confirmChange callback has its first parameter defaulted to the next value,
    so it can be called with no parameters to confirm the change or called with
    a parameter to set an alternate validation value.
*/
function validateValues(prevFormApi, values, inputPath, defaultNextValuesGen, validate) {
  const _values = typeof values === 'function' ? values(prevFormApi) : values

  if (typeof validate === 'function') {
    let newFormValues = {}

    const getNextValues = (confirmedValues = _values) => defaultNextValuesGen(confirmedValues)

    const confirmChange = (confirmedValues) => {
      newFormValues = getNextValues(confirmedValues)
    }

    const nextFormApi = {
      ...prevFormApi,
      values: getNextValues(),
    }

    validate(confirmChange, inputPath, nextFormApi)

    return newFormValues
  }

  return defaultNextValuesGen(_values)
}

/*
  This accepts an object tree structure with stone questions assigned to a key
  that represents the index of the stone internal to the stones composite input
  and returns an array of objects for the parent Form to handle.

  From:
  { 0: { stone_question: "answer" , ...otherQuestions }, ...otherStones }

  To:
  [{ stone_question: "answer" , ...otherQuestions }, ...otherStones]
*/
const formValueMapper = (compositeValues = {}) => {
  let highestIndex
  Object.keys(compositeValues).forEach((key) => {
    const index = Number(key)
    if (!isNaN(index) && (highestIndex === undefined || index > highestIndex)) {
      highestIndex = index
    }
  })

  const formValues = []
  if (highestIndex !== undefined) {
    for (let index = 0; index <= highestIndex; index += 1) {
      formValues.push(compositeValues[index] || {})
    }
  }

  return formValues
}

/*
  This accepts an array of objects that the parent Form handles and returns an
  object tree structure with stone questions assigned to a key that represents
  the index of the stone for the stone composite input to use internally.

  From:
  [{ stone_question: "answer" , ...otherQuestions }, ...otherStones]

  To:
  { 0: { stone_question: "answer" , ...otherQuestions }, ...otherStones }
*/
const compositeValuesMapper = (formValue = []) =>
  formValue.reduce(
    (acc = {}, value, index) => ({
      ...acc,
      [index.toString()]: value,
    }),
    {},
  )

/*
  This accepts a nested object tree structure with stone question errors
  assigned to a key that represents the index of the stone internal to the
  stones composite input and returns an array of objects for the parent
  Form to handle.

  From:
  {
    nested: {
      0: { nested: { stone_question: "error" , ...otherQuestions } },
      ...otherStones
    }
  }

  To:
  [{ stone_question: "error" , ...otherQuestions }, ...otherStones]
*/
const formErrorMapper = (compositeErrors = { nested: {} }) =>
  formValueMapper(compositeErrors.nested).map((error = {}) => error.nested)

/*
  This accepts an array of objects with errors that the parent Form handles and
  returns a nested object tree structure with stone question errors assigned to
  a key that represents the index of the stone for the stone composite input to
  use internally.

  From:
  [{ stone_question: "error" , ...otherQuestions }, ...otherStones]

  To:
  {
    nested: {
      0: { nested: { stone_question: "error" , ...otherQuestions } },
      ...otherStones
    }
  }
*/
const compositeErrorsMapper = (formError = []) => ({
  nested: compositeValuesMapper(formError.map((error) => ({ nested: error }))),
})

/*
  This function takes a section register and generates an empty errors structure
  representative of the sections in the register.
*/
const genEmptyErrorsFromSectionRegister = (sectionRegister) => {
  const errors = { __isSection: true }

  Object.keys(sectionRegister).forEach((sectionKey) => {
    if (!errors.nested) {
      errors.nested = {}
    }

    errors.nested[sectionKey] = genEmptyErrorsFromSectionRegister(sectionRegister[sectionKey])
  })

  return errors
}

export {
  isObject,
  isSectionErrors,
  updateHasNestedErrors,
  objectTreeImmutableSet,
  deepImmutableSetInputError,
  initSectionErrors,
  registerSection,
  flattenValues,
  flattenErrors,
  validateValues,
  formValueMapper,
  compositeValuesMapper,
  formErrorMapper,
  compositeErrorsMapper,
  genEmptyErrorsFromSectionRegister,
}
