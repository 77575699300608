import React, { Component } from 'react'
import axios from '@app/shared/utilities/axios'
import { Unit } from '../../../shared/components'
import LibraryTable from './components/LibraryTable'
import { UserTypes } from '@app/constants'

class Library extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      libraryFiles: [],
      sopFiles: [],
    }
  }

  getLibraryDownloadLinks = async () => {
    try {
      const response = await axios('/library-items/', 'get')

      const { libraryFiles = [], sopFiles = [] } = response.data
      this.setState({ libraryFiles, sopFiles })
    } catch (err) {
      this.setState({
        loading: false,
        errorDialogBoxOpen: true,
        error: 'Error fetching your reports.',
      })
    }
  }

  componentDidMount() {
    this.setState({
      loading: true,
    })
    this.getLibraryDownloadLinks()
  }

  render() {
    const { viewer } = this.props
    const { libraryFiles, sopFiles } = this.state

    const { groups } = viewer
    const isAdmin = groups.includes(UserTypes.tasAdmin)
    const isPartner = groups.includes(UserTypes.partner)
    const isTechnologist = groups.includes(UserTypes.technologist)
    const isPhysician = groups.includes(UserTypes.physician)

    const SOPLibraryTable =
      isPartner || isTechnologist || isPhysician || isAdmin ? (
        <Unit>
          <h1>Standard Operating Procedures</h1>
          <LibraryTable SPFolder='SOP' files={sopFiles} />
        </Unit>
      ) : null

    return (
      <div>
        <h1>Library</h1>
        <LibraryTable SPFolder='Library' files={libraryFiles} />
        {SOPLibraryTable}
      </div>
    )
  }
}

export default Library
