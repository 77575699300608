import React from 'react'

export default (
  <g id='Styleguide' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
    <g id='Icons' transform='translate(-435.000000, -5766.000000)' stroke='#4A4A4A'>
      <g id='Nav-Icon/default/txpro' transform='translate(435.000000, 5766.000000)'>
        <g id='patch-34' transform='translate(0.500000, 1.500000)'>
          <path d='M6.53020418,17.8096478 L17.8096478,29.0890914' id='Shape' strokeWidth='2' />
          <path d='M18.9969576,5.34289433 L30.2764012,16.6223379' id='Shape' strokeWidth='2' />
          <path
            d='M8.54447535,34.1565298 L1.46276574,27.0748202 C0.303357667,25.9154121 0.303357667,24.0364943 1.46276574,22.8770862 L23.427998,0.911853966 C24.5874061,-0.247554104 26.4663239,-0.247554104 27.625732,0.911853966 L34.7074416,7.99356357 C35.8668496,9.15297164 35.8668496,11.0318895 34.7074416,12.1912976 L12.7422093,34.1565298 C11.5833949,35.3153442 9.70388342,35.3153442 8.54447535,34.1565298 Z'
            id='Shape'
            strokeWidth='2'
            strokeLinecap='square'
          />
          <circle
            id='Oval'
            strokeWidth='1.5'
            strokeLinecap='square'
            cx='14.2477182'
            cy='17.2159928'
            r='1.18730985'
          />
          <circle
            id='Oval'
            strokeWidth='1.5'
            strokeLinecap='square'
            cx='18.4033027'
            cy='21.3715773'
            r='1.18730985'
          />
          <circle
            id='Oval'
            strokeWidth='1.5'
            strokeLinecap='square'
            cx='18.4033027'
            cy='13.0604084'
            r='1.18730985'
          />
          <circle
            id='Oval'
            strokeWidth='1.5'
            strokeLinecap='square'
            cx='22.5588872'
            cy='17.2159928'
            r='1.18730985'
          />
        </g>
      </g>
    </g>
  </g>
)
