import * as React from 'react'

import { Popover as MuiPopover, Menu, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import colors from '@shared/colors'

const styles = (theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    boxShadow: 'box-shadow: 0 0 6px 0 rgba(0,0,0,0.12), 0 1px 6px 0 rgba(0,0,0,0.24);',
    borderRadius: '2px',
    backgroundColor: colors.grey.lightest,
  },
})

class Popover extends React.Component {
  constructor(props) {
    super(props)
    this.state = { anchorEl: null }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.open && !nextProps.open) {
      this.handleClose()
    }
  }

  handleOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = (event) => {
    this.setState({ anchorEl: null })
  }

  render() {
    const {
      anchorElement,
      children,
      anchorOrigin,
      transformOrigin,
      hover = false, // decides if the popover is triggered by hover or click
      classes,
    } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    const popoverTrigger = hover ? (
      <span
        aria-owns={open ? 'mouse-over-popover' : null}
        aria-haspopup='true'
        onMouseEnter={this.handleOpen}
        onMouseLeave={this.handleClose}
      >
        {' '}
        {anchorElement}
      </span>
    ) : (
      <div
        onClick={this.handleOpen}
        onKeyPress={() => void 0} // These 2 attributes are here to resolve an es-lint
        role='presentation' // -> Since this is not clean, we may need to think about resolving it in a different way in the future
      >
        {anchorElement}
      </div>
    )

    return (
      <React.Fragment>
        {popoverTrigger}
        <MuiPopover
          id='mouse-over-popover'
          className={hover ? classes.popover : null}
          classes={{ paper: classes.paper }}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          disableRestoreFocus
        >
          {children}
        </MuiPopover>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Popover)
export { Menu, MenuItem }
