import * as React from 'react'
import { FileDownloadButton } from '@shared/components'

import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'

import { TableWrapper } from '@shared/components/Table'

const StyledTableRow = withStyles({ root: { cursor: 'pointer' } })(TableRow)

export default function AdvisorsTable(props) {
  const { advisorsItems } = props

  return (
    <TableWrapper margin='36px 0 0'>
      {!!advisorsItems.length && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {advisorsItems.map((advisorsItem) => (
              <StyledTableRow hover key={advisorsItem.id}>
                <TableCell style={{ width: '80%' }}>{advisorsItem.name}</TableCell>
                <TableCell style={{ width: '10%' }}>
                  <FileDownloadButton
                    link={`/advisors-items/${advisorsItem.name}/file`}
                    fileName={advisorsItem.name}
                  />
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableWrapper>
  )
}
