// Libraries
import React from 'react'
import axios from '@app/shared/utilities/axios'

// Alias Imports
import { Title, Unit, Flex, LoadingIndicator } from '@shared/components'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'

//folder components
import PdfWidget from './components/PdfWidget'

class InvestorReports extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMessage: '',
      loading: true,
      reportsExist: false,
      subsidiaries: [],
      selectedSubsidiary: {},
      selectBarOpen: false,
      reportsBySubsidiaryAndType: {},
    }

    this.getReports()
  }
  getReports = async () => {
    try {
      // Get user's subsidiary ids
      const [subsidiariesResponse, reportsResponse] = await Promise.all([
        await axios('/investor-reports/subsidiaries', 'get'),
        await axios('/investor-reports/', 'get'),
      ])
      // if (subsidiariesResponse.data.length > 0) {
      const { subsidiaries } = subsidiariesResponse.data
      subsidiaries.sort((sub1, sub2) => sub1.Name.localeCompare(sub2.Name))
      this.setState({
        subsidiaries,
      })
      // }
      if (reportsResponse.data.length > 0) {
        const { data: reportsList = [] } = reportsResponse
        const reportsBySubsidiaryAndType = reportsList.reduce((acc, report) => {
          if (!acc[report.subsidiaryId]) {
            acc[report.subsidiaryId] = {}
          }

          acc[report.subsidiaryId][report.reportType] = report

          return acc
        }, {})

        this.setState({
          loading: false,
          reportsExist: true,
          subsidiaries,
          selectedSubsidiary: subsidiaries[0] || {},
          reportsBySubsidiaryAndType,
        })
      } else {
        this.setState({
          loading: false,
          reportsExist: false,
          errorMessage: 'No reports found',
        })
      }
    } catch (err) {
      if (err.response.status === 403) {
        this.setState({
          loading: false,
          reportsExist: false,
          errorMessage: 'Permission denied',
        })
      } else {
        this.setState({
          loading: false,
          errorMessage: 'There was an error fetching these reports.',
        })
      }
    }
  }

  handleChange = (event) => {
    const selectedSubsidiary = this.state.subsidiaries.find((subsidiary) => {
      return subsidiary.InternalId === event.target.value
    })
    this.setState({
      selectedSubsidiary,
    })
  }

  render() {
    const {
      errorMessage,
      loading,
      reportsExist,
      subsidiaries,
      selectedSubsidiary,
      reportsBySubsidiaryAndType,
      selectBarOpen,
    } = this.state

    if (loading) {
      return (
        <React.Fragment>
          <Title>Investor Reports</Title>
          <LoadingIndicator loadingText='Loading subsidiaries' />
        </React.Fragment>
      )
    }

    if (errorMessage) {
      return (
        <React.Fragment>
          <Flex parent justifyContent='space-between' alignItems='baseline'>
            <Title>Investor Reports</Title>
          </Flex>
          <Unit>
            <Flex parent justifyContent='space-between' alignItems='baseline'>
              <Typography component='p'>Error: {errorMessage}</Typography>
            </Flex>
            <Flex parent justifyContent='space-between' alignItems='baseline'>
              <Typography component='p'>Contact your business developer</Typography>
            </Flex>
          </Unit>
        </React.Fragment>
      )
    }

    if (!reportsExist) {
      return (
        <React.Fragment>
          <Flex parent justifyContent='space-between' alignItems='baseline'>
            <Title>Investor Reports</Title>
          </Flex>
          <Unit>
            <Flex parent justifyContent='space-between' alignItems='baseline'>
              <Typography component='p'>No reports found</Typography>
            </Flex>
            <Flex parent justifyContent='space-between' alignItems='baseline'>
              <Typography component='p'>Contact your business developer</Typography>
            </Flex>
          </Unit>
        </React.Fragment>
      )
    }

    const multipleSubsidiaries = (subsidiaries || []).length > 1

    const pdfDisplay = selectedSubsidiary ? (
      <PdfWidget
        subsidiaryId={selectedSubsidiary.InternalId}
        subsidiaryName={selectedSubsidiary.Name}
        subsidiaryReports={reportsBySubsidiaryAndType[selectedSubsidiary.InternalId]}
      />
    ) : (
      <Typography component='p'>Your account is not linked to a partnership.</Typography>
    )

    return (
      <React.Fragment>
        <React.Fragment>
          <Flex parent justifyContent='space-between' alignItems='baseline'>
            <Title>Investor Reports</Title>
          </Flex>
          {multipleSubsidiaries && (
            <Unit style={{ paddingLeft: '15px' }}>
              <FormControl>
                <InputLabel style={{ left: '10px' }} id='demo-controlled-open-select-label'>
                  Subsidiary
                </InputLabel>
                <Select
                  className='subsidiarySelectBar'
                  labelid='demo-controlled-open-select-label'
                  id='demo-controlled-open-select'
                  style={{ minWidth: '200px' }}
                  open={selectBarOpen}
                  onClose={() => {
                    this.setState({
                      selectBarOpen: false,
                    })
                  }}
                  onOpen={() => {
                    this.setState({
                      selectBarOpen: true,
                    })
                  }}
                  value={selectedSubsidiary.InternalId || 'no subsidiaries'}
                  onChange={this.handleChange}
                >
                  {subsidiaries.map((sub) => (
                    <MenuItem key={sub.InternalId} value={sub.InternalId}>
                      {sub.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Unit>
          )}
        </React.Fragment>
        {pdfDisplay}
      </React.Fragment>
    )
  }
}

export default InvestorReports
