import React, { Component } from 'react'
import axios from '@app/shared/utilities/axios'

import AppSettingsContext from './AppSettingsContext'

function getTenantAppSettings() {
  return axios('/app-settings', 'get')
}

class AppSettingsProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: null,
      loading: true,
      success: false,
      error: null,
    }

    getTenantAppSettings().then(this.onGetSettingsSuccess).catch(this.onGetSettingsError)
  }

  onGetSettingsSuccess = (res) => {
    this.setState({
      data: res.data,
      loading: false,
      success: true,
      error: null,
    })
  }

  onGetSettingsError = (error) => {
    this.setState({
      loading: false,
      success: false,
      error,
    })
  }

  render() {
    const { children } = this.props

    return <AppSettingsContext.Provider value={this.state}>{children}</AppSettingsContext.Provider>
  }
}

export default AppSettingsProvider
