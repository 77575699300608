import React, { Component } from 'react'
import styled from 'styled-components'
import { saveAs } from 'file-saver'

import { Flex, Button } from '@shared/components'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import axios from '@app/shared/utilities/axios'

const ButtonWrapper = styled.div`
  margin: 10px 0 auto;
`

const ReportDetailsWrapper = styled.div`
  margin: 0 auto;
  padding: 15px;
  display: grid;
  grid-template-rows: 50% 50%;
`

class IndividualPdf extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      error: null,
      errorDialogBoxOpen: false,
    }
  }

  downloadReport = async () => {
    const { fileName } = this.props

    try {
      const response = await axios(`/investor-reports/${fileName}/file`, 'get', {
        responseType: 'blob',
      })

      if (response.data) {
        saveAs(response.data, fileName)
      }
    } catch (err) {
      this.setState({
        errorDialogBoxOpen: true,
        error: 'Error fetching your report.',
      })
    }
  }

  handleClose = () => {
    this.setState({
      errorDialogBoxOpen: false,
    })
  }

  render() {
    const { fileName, reportDescription, subsidiaryName } = this.props
    const { error, errorDialogBoxOpen } = this.state

    const errorMessage =
      error || !fileName ? (
        <Dialog
          open={errorDialogBoxOpen}
          onClose={this.handleClose}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogTitle id='responsive-dialog-title'>{'Report Not Ready'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This report has not been uploaded yet. Please check back later.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : null

    return (
      <Flex>
        {errorMessage}
        <ReportDetailsWrapper>
          <ButtonWrapper>
            <Button onClick={this.downloadReport}>
              {reportDescription === 'Facility Update'
                ? `Download State of ${subsidiaryName}`
                : `Download ${reportDescription} for ${subsidiaryName}`}
            </Button>
          </ButtonWrapper>
        </ReportDetailsWrapper>
      </Flex>
    )
  }
}

export default IndividualPdf
