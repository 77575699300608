import styled from 'styled-components'

import colors from '@shared/colors'
import AddIcon from '@images/iconComponents/add'
import RemoveIcon from '@images/iconComponents/remove'
import { Typography, withStyles } from '@material-ui/core'

const MultiSuggestAddIcon = withStyles({
  root: {
    verticalAlign: 'middle',
    marginRight: '13.5px',
  },
})(AddIcon)

const MultiSuggestRemoveIcon = withStyles({
  root: {
    verticalAlign: 'middle',
    marginLeft: '19.5px',
    cursor: 'pointer',
  },
})(RemoveIcon)

const MultiSuggestAddWrapper = withStyles({
  root: {
    marginTop: '21px',
    cursor: 'pointer',
    color: colors.blue.dark,
  },
})(Typography)

const MultiSuggestAddText = styled.span`
  vertical-align: middle;
`

const MultiSuggestSingleWrapper = styled.div`
  width: 100%;
  &:not(:nth-child(2)) {
    margin-top: 15px;
  }
`

// Autocomplete input wrapper
const AutoSuggestInputWrapper = styled.div`
  position: relative;
  width: ${({ width }) => width};

  /* It is necessary to NOT use a styled component directly as it swallows the 'ref' prop */
  input {
    height: 40px;
    padding-right: ${({ iconSize }) => (iconSize === 'large' ? '40px' : '30px')};
    font-size: 18px;
    font-family: inherit;
    border-radius: 7px;
    outline: none;
  }

  input::placeholder {
    font: inherit;
    color: ${colors.grey.medium};
  }
`

// Autocomplete input icon (dropdown)
const AutoSuggestInputIcon = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

// AutoSuggest menu item
const AutoSuggestItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${({ checked }) => (checked ? 'bold' : 'inherit')};
  cursor: pointer;
`

// AutoSuggest menu item text
const AutoSuggestItemText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

// Autocomplete input error text wrapper
const ErrorTextWrapper = styled.div`
  position: absolute;
  left: 8px;
  bottom: -20px;
  color: ${colors.red};
  font-size: 12px;
  line-height: 12px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
`

export {
  AutoSuggestInputWrapper,
  AutoSuggestInputIcon,
  AutoSuggestItem,
  AutoSuggestItemText,
  ErrorTextWrapper,
  MultiSuggestRemoveIcon,
  MultiSuggestAddIcon,
  MultiSuggestAddText,
  MultiSuggestAddWrapper,
  MultiSuggestSingleWrapper,
}
