/**
 *
 * @file This file contains utililty
 * helper functions that are shared across
 * all files in the follow-up app
 */

import bowser from 'bowser'

const browser = bowser.getParser(window.navigator.userAgent)

const isUnsupportedBrowser = () => {
  return !browser.satisfies({
    'internet explorer': '>10',
    chrome: '>0',
    opera: '>0',
    safari: '>0',
    chromium: '>0',
    firefox: '>0',
    'microsoft edge': '>0',
  })
}

export { isUnsupportedBrowser }
