import * as React from 'react'
import { withStyles } from '@material-ui/core'

const placeholderTextStyles = (theme = {}) => {
  const { custom = {} } = theme
  const { select = {} } = custom
  const { placeholderText = {} } = select

  return {
    ...placeholderText,
  }
}

const PlaceholderText = withStyles(placeholderTextStyles)(({ classes, ...rest }) => (
  <span className={classes.root} {...rest} />
))

export default PlaceholderText
