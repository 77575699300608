import React from 'react'
import { withRouter } from 'react-router-dom'
import { createFragmentContainer, graphql } from 'react-relay'

// Alias Imports
import { Routes, UserTypes } from '@app/constants'
import menuIcon from '@app/images/icons/menu-burger.svg'
import settingsIcon from '@app/images/icons/settings.svg'
import serverConfig from '@app/config/serverConfig'
import Popover, { MenuItem } from '@shared/components/Popover'
import { AppMediaQuery } from '@shared/MediaQuery'
import logoFile from '@images/icons/logo.svg'

import {
  AppHeaderContent,
  AppHeaderLogo,
  AppHeaderMenuIcon,
  AppHeaderWrapper,
  AppHeaderAside,
} from './styled'

/**
 * App header with menu and logo
 * @param {function} onMenuIconClick Open the App Menu
 */

class BaseAppHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popoverOpen: false,
    }
  }

  render() {
    const { popoverOpen } = this.state
    const { onMenuIconClick, history, viewer } = this.props

    const isAdmin = Array.isArray(viewer.groups) && viewer.groups.includes(UserTypes.tasAdmin)
    const settingsOptions = []

    if (isAdmin) {
      settingsOptions.push(
        <MenuItem
          key='userManagementSettings'
          onClick={() => {
            if (history.location.pathname !== Routes.userList) history.push(Routes.userList)
            this.setState({ popoverOpen: false })
          }}
        >
          User Management
        </MenuItem>,
      )
    }

    let headerLogo
    if (process.env.NODE_ENV === 'production' && serverConfig.LOGO_URL) {
      headerLogo = `${serverConfig.LOGO_URL}`
    } else {
      headerLogo = logoFile
    }

    return (
      <AppHeaderWrapper>
        <AppMediaQuery>
          <AppHeaderContent>
            <AppHeaderMenuIcon alt='Menu' src={menuIcon} onClick={onMenuIconClick} />
            <AppHeaderLogo alt='Logo' src={headerLogo} onClick={() => history.push(Routes.home)} />
            {settingsOptions.length > 0 ? (
              <AppHeaderAside>
                <Popover
                  open={popoverOpen}
                  anchorElement={
                    <AppHeaderMenuIcon
                      alt='Settings'
                      src={settingsIcon}
                      onClick={() => this.setState({ popoverOpen: true })}
                    />
                  }
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  {settingsOptions}
                </Popover>
              </AppHeaderAside>
            ) : null}
          </AppHeaderContent>
        </AppMediaQuery>
      </AppHeaderWrapper>
    )
  }
}

const AppHeader = createFragmentContainer(BaseAppHeader, {
  viewer: graphql`
    fragment AppHeader_viewer on Viewer {
      groups
    }
  `,
})

export default withRouter(AppHeader)
