import React from 'react'
import axios from '@app/shared/utilities/axios'
import { Title, Flex, LoadingIndicator } from '@shared/components'

//folder components
import PhysicianNarrativeTable from './components/PhysicianNarrativeTable'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

class PhysicianNarrative extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      ceoMessages: null,
      hasError: false,
    }

    this.getCEOMessages()
  }

  async getCEOMessages() {
    try {
      const response = await axios('/ceo-messages', 'get')

      this.setState({
        ceoMessages: response.data || [],
        loading: false,
        hasError: false,
      })
    } catch (error) {
      this.setState({
        loading: false,
        hasError: true,
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Dialog open={this.state.hasError} aria-labelledby='responsive-dialog-title'>
          <DialogTitle id='responsive-dialog-title'>
            Error loading messages from the CEO
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Please try again</DialogContentText>
          </DialogContent>
        </Dialog>

        <Flex parent justifyContent='space-between' alignItems='baseline'>
          <Title>Messages from the CEO</Title>
        </Flex>

        {this.state.loading ? (
          <LoadingIndicator loadingText='Loading messages...' />
        ) : (
          <PhysicianNarrativeTable ceoMessages={this.state.ceoMessages} />
        )}
      </React.Fragment>
    )
  }
}

export default PhysicianNarrative
