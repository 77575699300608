/*
  Note: This is essentially copied from the Mui source code just to support some
  minor changes.

  https://github.com/mui-org/material-ui/blob/v1-beta/packages/material-ui/src/Table/TableSortLabel.js
*/

// Libraries
import * as React from 'react'
import classNames from 'classnames'
import { withStyles, ButtonBase } from '@material-ui/core'
import { capitalize } from '@material-ui/core/utils/helpers'

// Alias Imports
import ArrowDownwardIcon from '@images/iconComponents/down-arrow'

export const styles = (theme) => ({
  root: {
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    flexDirection: 'inherit',
    alignItems: 'center',
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '&:focus': {
      color: theme.palette.text.primary,
    },
  },
  active: {
    color: theme.palette.text.primary,
    '& $icon': {
      opacity: 1,
    },
  },
  icon: {
    height: 16,
    marginRight: 4,
    marginLeft: 4,
    opacity: 0,
    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: theme.transitions.duration.shorter,
    }),
    userSelect: 'none',
    width: 16,
  },
  iconDirectionDesc: {
    transform: 'rotate(0deg)',
  },
  iconDirectionAsc: {
    transform: 'rotate(180deg)',
  },
})

/**
 * A button based label for placing inside `TableCell` for column sorting.
 */
function TableSortLabel(props) {
  const {
    active,
    classes,
    className,
    children,
    direction,
    svgIconComponent: Icon,
    ...other
  } = props

  return (
    <ButtonBase
      className={classNames(classes.root, { [classes.active]: active }, className)}
      component='span'
      disableRipple
      {...other}
    >
      {children}

      {Icon && (
        <Icon
          className={classNames(classes.icon, classes[`iconDirection${capitalize(direction)}`])}
        />
      )}
    </ButtonBase>
  )
}

TableSortLabel.defaultProps = {
  active: false,
  direction: 'desc',
  children: undefined,
  className: undefined,
  svgIconComponent: ArrowDownwardIcon,
}

export default withStyles(styles, { name: 'MuiTableSortLabel' })(TableSortLabel)
