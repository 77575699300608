import React from 'react'

export default (
  <g id='Styleguide' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
    <g id='Icons' transform='translate(-301.000000, -5767.000000)' stroke='#4A4A4A'>
      <g id='Nav-Icon/default/sde' transform='translate(301.000000, 5766.000000)'>
        <g id='talk' transform='translate(1.000000, 3.000000)'>
          <path
            d='M17.6223776,0 L17.6223776,28.7832168'
            id='Shape'
            strokeWidth='2'
            strokeLinecap='square'
          />
          <path
            d='M9.3986014,31.7202797 L25.8461538,31.7202797'
            id='Shape'
            strokeWidth='2'
            strokeLinecap='square'
          />
          <path d='M0,14.0979021 L14.0979021,14.0979021' id='Shape' strokeWidth='2' />
          <path d='M21.1468531,14.0979021 L35.2447552,14.0979021' id='Shape' strokeWidth='2' />
          <path
            d='M28.1958042,3.52447552 L21.1468531,14.0979021 C21.1468531,16.575021 23.4965035,19.972028 28.1958042,19.972028 C32.8951049,19.972028 35.2447552,16.575021 35.2447552,14.0979021 L28.1958042,3.52447552 L7.04895105,3.52447552 L0,14.0979021 C0,16.575021 2.34965035,19.972028 7.04895105,19.972028 C11.7482517,19.972028 14.0979021,16.575021 14.0979021,14.0979021 L7.04895105,3.52447552'
            id='Shape'
            strokeWidth='2'
          />
        </g>
      </g>
    </g>
  </g>
)
