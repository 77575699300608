// Relative Imports
import { Section, LogoFrame, Paragraph } from './container'
import DividerWithText from './dividerWithText'
import DialogContent from './dialogContent'
import ModernBrowserLogos from './modernBrowserLogos'

const style = {
  title: { textAlign: 'center' },
}

export { Paragraph, Section, DividerWithText, DialogContent, ModernBrowserLogos, LogoFrame, style }
