import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { Redirect, Route } from 'react-router'
import styled from 'styled-components'
import _ from 'lodash'

import followup from '@app/images/iconComponents/followup'
import investors from '@app/images/iconComponents/investors'
import qaReports from '@app/images/iconComponents/qaReports'
import simulator from '@app/images/iconComponents/simulator'
import treatment from '@app/images/iconComponents/treatment'
import sde from '@app/images/iconComponents/sde'
import note from '@app/images/iconComponents/note'
import hospital from '@app/images/iconComponents/hospital'
import advisors from '@app/images/iconComponents/advisors'
import inventory from '@app/images/iconComponents/inventory'
import billing from '@app/images/iconComponents/billing'
import { AppButton as AppButtonBase, Flex, Title } from '@shared/components'
import { GATEWAY_URL } from '@app/config/relayEnvironment'
import { Routes } from '@app/constants'
import { txpro } from '@shared/utilities/integrations/txpro'
import colors from '@shared/colors'

import TxProDownloadModal from '../TxProDownloadModal'
import { QaReportsModal } from '../QaReportsModal'

class TxProVersion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      version: '',
    }
    txpro().then((res) => {
      this.setState({
        version: res.data.metadata.buildInfo.version,
      })
    })
  }

  render() {
    return <span> {this.state.version} </span>
  }
}

const appsInfo = {
  followup: {
    link: () => window.location.assign(`${GATEWAY_URL}/followup`),
    displayName: 'Follow Up',
    icon: followup,
  },
  investors: {
    link: Routes.investorReports,
    displayName: 'Investor Reports',
    icon: investors,
  },
  simulator: {
    link: () => window.location.assign(`${GATEWAY_URL}/simulator`),
    displayName: 'Simulator',
    icon: simulator,
  },
  treatment: {
    link: Routes.downloadTxPro,
    displayName: 'TxPro',
    icon: treatment,
    extraText: <TxProVersion />,
  },
  stoneDecisionEngine: {
    link: Routes.stoneDecisionEngine,
    displayName: 'Stone Logik',
    icon: sde,
  },
  physicianNarrative: {
    link: Routes.physicianNarrative,
    displayName: 'Message From the CEO',
    icon: note,
  },
  library: {
    link: Routes.library,
    displayName: 'Library',
    icon: hospital,
  },
  advisors: {
    link: Routes.advisors,
    displayName: 'NextMed Advisors',
    icon: advisors,
  },
  qaReports: {
    link: Routes.qaReports,
    displayName: 'QA Reports',
    icon: qaReports,
  },
  billing: {
    link: () => window.location.assign(`${GATEWAY_URL}/billing`),
    displayName: 'Billing',
    icon: billing,
  },
  inventory: {
    link: () => window.location.assign(`${GATEWAY_URL}/dashboards/inventory`),
    displayName: 'Inventory',
    icon: inventory,
  }
}

const AppSvg = styled.svg.attrs({
  viewBox: '0 0 39 39',
  preserveAspectRatio: 'xMidYMid slice',
})`
  width: 39px;
  height: 39px;
  & #Icons {
    stroke: ${({ color = 'treatment' }) => colors.brand[color]};
  }
  & #Icons-fill {
    fill: ${({ color = 'treatment' }) => colors.brand[color]};
  }
`

const ButtonTextWrapper = styled.span`
  margin-left: 15px;
  text-align: left;
`

const DisplayNameWrapper = styled.div`
  font-size: 20px;
`

const ExtraTextWrapper = styled.div`
  font-size: 16px;
  font-style: italic;
`

function AppButton({ app, history }) {
  const { displayName, extraText, link, icon } = appsInfo[app]

  return (
    <AppButtonBase
      color={app}
      onClick={() => {
        if (typeof link === 'string') {
          history.push(link)
        } else if (typeof link === 'function') {
          link()
        }
      }}
    >
      <AppSvg color={app} alt={app}>
        {icon}
      </AppSvg>
      <ButtonTextWrapper>
        <DisplayNameWrapper>{displayName}</DisplayNameWrapper>
        {extraText ? <ExtraTextWrapper>{extraText}</ExtraTextWrapper> : null}
      </ButtonTextWrapper>
    </AppButtonBase>
  )
}

const appsOrder = [
  'physicianNarrative',
  'investors',
  'followup',
  'qaReports',
  'simulator',
  'stoneDecisionEngine',
  'treatment',
  'billing',
  'library',
  'advisors',
  'inventory'
]

const ButtonsContainer = styled(Flex)`
  margin-top: 36px;
`

const buttonRowSize = 3

const Landing = (props) => {
  const { viewer, history, appSettings } = props
  const { groups, memberEquivalent } = viewer

  const {
    enableFollowup,
    enableLibrary,
    enablePhysicianNarrative,
    enableInvestors,
    enableSimulator,
    enableStoneLogik,
    enableAdvisors,
    enableInventory,

    appAccessPerGroup,
  } = appSettings

  // Map for easily checking which of the landing page "apps" are disabled.
  const disabledApps = {
    followup: !enableFollowup,
    library: !enableLibrary,
    physicianNarrative: !enablePhysicianNarrative,
    investors: !enableInvestors,
    simulator: !enableSimulator,
    stoneDecisionEngine: !enableStoneLogik,
    advisors: !enableAdvisors,
    inventory: !enableInventory
  }

  // This creates a list of all the apps the viewer has access to based on their
  // groups and orders them according to a custom order.
  const appAccessList = groups.reduce((acc, group) => {
    if (group === 'physician' && memberEquivalent) {
      return [...acc, ...appAccessPerGroup.physician_me]
    }

    return [...acc, ...(appAccessPerGroup[group] || [])]
  }, [])
  const orderedAppAccessList = _.filter(
    appsOrder,
    (app) =>
      // Check that the app isn't disabled
      !disabledApps[app] &&
      // Check that the user has access to the app
      appAccessList.includes(app),
  )

  const appRows = _.chunk(orderedAppAccessList, buttonRowSize)

  return (
    <React.Fragment>
      <Title>My Applications</Title>
      <ButtonsContainer parent direction='column' spacing={23}>
        {appRows.map((appRow) => {
          // extraSpacing is used to properly space buttons in a row that doesn't
          // have enough of them to fill up the whole row
          const extraSpacing = []
          for (let i = 0; i < buttonRowSize - appRow.length; i++) {
            extraSpacing.push(<Flex key={i} flex='1 1 0%' />)
          }

          return (
            // eslint-disable-next-line react/jsx-key
            <Flex parent spacing={23}>
              {appRow.map((app) => (
                <Flex key={app} flex='1 1 0%'>
                  <AppButton app={app} history={history} />
                </Flex>
              ))}
              {extraSpacing}
            </Flex>
          )
        })}
      </ButtonsContainer>
      <Route
        path={Routes.downloadTxPro}
        render={(route) =>
          appAccessList.includes('treatment') ? (
            <TxProDownloadModal {...props} {...route} />
          ) : (
            <Redirect to={Routes.home} />
          )
        }
      />
      <Route
        path={Routes.qaReports}
        render={(route) =>
          appAccessList.includes('qaReports') ? (
            <QaReportsModal {...props} {...route} />
          ) : (
            <Redirect to={Routes.home} />
          )
        }
      />
    </React.Fragment>
  )
}

export default createFragmentContainer(Landing, {
  viewer: graphql`
    fragment landing_viewer on Viewer {
      groups
      memberEquivalent
    }
  `,
})
