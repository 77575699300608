import * as React from 'react'

import SnackbarContext from './context'
import DefaultSnackbar from './Snackbar'

class SnackbarListener extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      additionalSnackbarProps: {},
    }
  }

  dispatchMessage = (message, additionalSnackbarProps) => {
    this.setState({ open: true, message, additionalSnackbarProps })
  }

  closeMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ open: false })
  }

  render() {
    const { children, Snackbar = DefaultSnackbar, defaultSnackbarProps = {} } = this.props
    const { open, message, additionalSnackbarProps } = this.state

    const snackbarProps = {
      ...defaultSnackbarProps,
      ...additionalSnackbarProps,
    }

    return (
      <SnackbarContext.Provider
        value={{
          dispatchMessage: this.dispatchMessage,
          closeMessage: this.closeMessage,
        }}
      >
        {children}
        <Snackbar open={open} message={message} onClose={this.closeMessage} {...snackbarProps} />
      </SnackbarContext.Provider>
    )
  }
}

export default SnackbarListener
