import demographicsActiveIcon from '@app/images/icons/navigation/demographics_active.svg'
import demographicsInactiveIcon from '@app/images/icons/navigation/demographics.svg'

import shockProtocolActiveIcon from '@app/images/icons/navigation/shock-protocol_active.svg'
import shockProtocolInactiveIcon from '@app/images/icons/navigation/shock-protocol.svg'

import treatmentActiveIcon from '@app/images/icons/navigation/treatment_active.svg'
import treatmentInactiveIcon from '@app/images/icons/navigation/treatment.svg'

import summaryActiveIcon from '@app/images/icons/navigation/summary_active.svg'
import summaryInactiveIcon from '@app/images/icons/navigation/summary.svg'

/*
 * Map the current url to an icon state
 */
const NavigationLinkIconMap = {
  '/demographics': {
    active: demographicsActiveIcon,
    inactive: demographicsInactiveIcon,
  },
  '/shockprotocol': {
    active: shockProtocolActiveIcon,
    inactive: shockProtocolInactiveIcon,
  },
  '/treatmentstrategy': {
    active: treatmentActiveIcon,
    inactive: treatmentInactiveIcon,
  },
  '/summary': {
    active: summaryActiveIcon,
    inactive: summaryInactiveIcon,
  },
}

export default NavigationLinkIconMap
