// Libraries
import React from 'react'

// Alias Imports
import colors from '@shared/colors'

const ErrorIcon = ({ color = colors.white, ...rest }) => (
  <svg {...rest} width='30px' height='26px'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g fillRule='nonzero' fill={color}>
        <g transform='translate(0, 1)'>
          <path d='M23.8746152,21.4611678 C24.0524336,21.7865689 24.0404336,22.1780998 23.8429788,22.4930041 C23.645524,22.8079084 23.2920691,23 22.9091596,23 L1.09095456,23 C0.708045057,23 0.354590135,22.8079084 0.156044469,22.4919544 C-0.040319377,22.1780998 -0.0523193898,21.7865689 0.125498981,21.4611678 L11.0346015,1.51723027 C11.4120565,0.827589909 12.5880577,0.827589909 12.9655127,1.51723027 L23.8746152,21.4611678 Z M12.0000571,19.8509572 C12.6022395,19.8509572 13.0909673,19.3807002 13.0909673,18.8012763 C13.0909673,18.2218524 12.6022395,17.7515954 12.0000571,17.7515954 C11.3978746,17.7515954 10.9091468,18.2218524 10.9091468,18.8012763 C10.9091468,19.3807002 11.3978746,19.8509572 12.0000571,19.8509572 Z M13.0909673,15.6522336 L13.0909673,8.30446711 L10.9091468,8.30446711 L10.9091468,15.6522336 L13.0909673,15.6522336 Z' />
        </g>
      </g>
    </g>
  </svg>
)

ErrorIcon.displayName = 'error'

export default ErrorIcon
