import React, { Component } from 'react'
import { QueryRenderer, graphql } from 'react-relay'

import Root from './root.jsx'

import relayEnvironment from './../config/relayEnvironment.js'

import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from '@app/keycloak'
import styled from 'styled-components'

/*
 * This components renders the root Relay QueryRenderer which is root of a Relay
 * tree. Upon successful query fetch it returns Root app component and passess fetched
 * result.
 */

const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

class RootQuery extends Component {
  state = {
    query: graphql`
      query rootQuery {
        viewer {
          ...appRoutes_viewer
          ...AppMenuDrawer_viewer
          ...AppHeader_viewer
        }
      }
    `,
    environment: relayEnvironment,
  }

  render() {
    return (
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{ checkLoginIframe: false }}
        LoadingComponent={<LoadingContainer>Loading...</LoadingContainer>}
      >
        <QueryRenderer
          environment={this.state.environment}
          query={this.state.query}
          variables={{}}
          render={({ error, props }) => {
            if (keycloak.checkForRealmChange(window.location.pathname, window.location.search)) {
              // Check for Realm Change trigger
              window.location.replace(
                `${window.location.protocol}//${
                  window.location.host
                }${window.location.pathname.replace(keycloak.changeRealmRoute, '')}`,
              )
              return <div>Redirecting...</div>
            } else if (!keycloak.authenticated) {
              // Check for authentication
              return keycloak.login()
            } else if (props) {
              return <Root {...props} />
            } else {
              return <div></div>
            }
          }}
        />
      </ReactKeycloakProvider>
    )
  }
}

export default RootQuery
