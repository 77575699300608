import React from 'react'
import styled from 'styled-components'
import { GATEWAY_URL } from '@app/config/relayEnvironment'
import serverConfig from '../../../config/serverConfig'

const SDEFrameWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: block;
`

const SDEFrame = styled.iframe`
  height: 100%;
`

class StoneDecisionEngine extends React.Component {
  constructor(props) {
    super(props)
    this.addEventListener()
  }

  // Listen for message from SDE iframe to change the window if auth fails
  addEventListener = () => {
    window.addEventListener('message', function (event) {
      if (
        (~event.origin.indexOf(`${GATEWAY_URL}/stone-logik`) ||
          ~event.origin.indexOf(serverConfig.SDE_URL)) &&
        event.data === 'redirect'
      ) {
        window.location.assign(`${GATEWAY_URL}/portal`)
      }
    })
  }

  render() {
    return (
      <SDEFrameWrapper>
        <SDEFrame
          width='100%'
          frameBorder='0'
          title='stone logik'
          src={`${GATEWAY_URL}/stone-logik`}
        />
      </SDEFrameWrapper>
    )
  }
}

export default StoneDecisionEngine
