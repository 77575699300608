import React from 'react'

export default (
  <g id='Styleguide' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
    <g id='Icons' transform='translate(-502.000000, -5768.000000)' stroke='#4A4A4A'>
      <g id='Nav-Icon/default/portal' transform='translate(502.000000, 5768.000000)'>
        <g id='icon---portal'>
          <rect
            id='Rectangle-path'
            strokeWidth='2'
            x='1'
            y='1'
            width='11.3333333'
            height='11.3333333'
          />
          <rect
            id='Rectangle-path'
            strokeWidth='2'
            x='19.6666667'
            y='1'
            width='11.3333333'
            height='11.3333333'
          />
          <rect
            id='Rectangle-path'
            strokeWidth='2'
            x='1'
            y='19.6666667'
            width='11.3333333'
            height='11.3333333'
          />
          <rect
            id='Rectangle-path'
            strokeWidth='2'
            x='19.6666667'
            y='19.6666667'
            width='11.3333333'
            height='11.3333333'
          />
        </g>
      </g>
    </g>
  </g>
)
