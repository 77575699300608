import { gql } from '@apollo/client'

export const UserListQuery = gql`
  query UsersQuery($tenantID: Int!, $groupID: String, $max: Int!, $offset: Int!, $search: String) {
    users(tenantID: $tenantID, groupID: $groupID, max: $max, offset: $offset, search: $search) {
      id
      firstName
      middleInitial
      lastName
      email
      emailVerified
      enabled
      groups
      ... on PhysicianUser {
        facilities {
          facility {
            name
          }
        }
      }
      ... on FacilityUser {
        facilities {
          facility {
            name
          }
        }
      }
    }
  }
`
