import styled from 'styled-components'

import colors from '@shared/colors'

const navigationHeight = 44

// Wrapper for the menu list
const NavigationListWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${navigationHeight}px;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
`

// Wrapper for menu
const NavigationWrapper = styled.nav`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  justify-content: center;
  background-color: ${colors.brand.primary};
  height: ${navigationHeight}px;
  width: 100%;
  font-size: 16px;
`

export { NavigationListWrapper, NavigationWrapper }
