import * as React from 'react'
import { Radio as MuiRadio, RadioGroup, withStyles } from '@material-ui/core'

import { SelectedIcon, UnselectedIcon } from '@images/iconComponents/radio-button'

import { connectInput } from '../Form'
import { radioGroupControlMappers } from './utils'

const { string: stringControlMapper } = radioGroupControlMappers

const Radio = ({ checked, disabled, error, onChange, value }) => (
  <MuiRadio
    icon={<UnselectedIcon disabled={disabled} error={error} />}
    checkedIcon={<SelectedIcon disabled={disabled} />}
    checked={checked}
    disabled={disabled}
    onChange={onChange}
    value={value}
  />
)

Radio.defaultProps = {
  checked: undefined,
  disabled: false,
  error: false,
  onChange: undefined,
}

const formGroupSpacedStyles = {
  root: {
    justifyContent: 'space-between',
  },
}

const FormRadioGroupBase = ({
  formApi,
  children,
  classes,
  evenlySpaced,
  row,
  controlMapper = stringControlMapper,
}) => {
  const { value, onChange } = formApi
  const {
    getValue = stringControlMapper.getValue,
    getUpdatedFormValues = stringControlMapper.getUpdatedFormValues,
  } = controlMapper

  return (
    <RadioGroup
      value={getValue(value)}
      onChange={(e) => onChange(getUpdatedFormValues(value, e.target.value))}
      row={row}
      className={evenlySpaced ? classes.root : ''}
    >
      {children}
    </RadioGroup>
  )
}

FormRadioGroupBase.defaultProps = {
  children: undefined,
  row: false,
  evenlySpaced: false,
  controlMapper: stringControlMapper,
}

const FormRadioGroupBaseWithStyles = withStyles(formGroupSpacedStyles)(FormRadioGroupBase)

const FormRadioGroup = connectInput(FormRadioGroupBaseWithStyles, {
  defaultName: 'radioGroup',
  bundleApi: true,
})

export default Radio
export { RadioGroup, FormRadioGroup }
