// Libraries
import React from 'react'

// Alias Imports
import { connectInput } from '@shared/components/Form'

// Relative Imports
import AutoSuggest from './AutoSuggest'

const FormAutoSuggest = connectInput(
  ({ formApi: { value, onChange }, ...rest }) => (
    <AutoSuggest {...rest} value={value} onChange={onChange} />
  ),
  { bundleApi: true },
)

export default FormAutoSuggest
