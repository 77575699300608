import React from 'react'
import PropTypes from 'prop-types'
import { SvgIcon } from '@material-ui/core'

import colors from '@shared/colors'

/**
 * Dropdown menu icon
 * @param {boolean} disabled  Whether Select Field is disabled
 * @param {string}  iconSize  Size of the dropdown icon
 */
const DropDownIcon = (props) => {
  const { disabled, iconSize, ...rest } = props

  const size = 24
  const fillColor = disabled ? colors.grey.medium : colors.grey.dark
  const style = { width: size, height: size, flexShrink: 0 }
  const DEFAULT_SCALE = 0.75

  // Determine custom SVG icon size and transform options
  const svgScale = iconSize === 'large' ? 1 : DEFAULT_SCALE
  const svgTranslate = iconSize === 'large' ? '4, 6' : '5, 7.5'
  const svgTransform = `translate(${svgTranslate}), scale(${svgScale})`

  return (
    <SvgIcon style={style} viewBox={`0 0 ${size} ${size}`} {...rest}>
      <path
        fill={fillColor}
        transform={svgTransform}
        d='M15.249689,1 L1.74976383,1 C1.4655154,1 1.20601684,1.15285714 1.07851755,1.395 C0.952518245,1.63714286 0.979518096,1.92642857 1.14976715,2.14285714 L7.89972973,10.7142857 C8.04072895,10.8942857 8.26347771,11 8.4997264,11 C8.73597509,11 8.95797386,10.8942857 9.09972308,10.7142857 L15.8496857,2.14285714 C16.0206847,1.92642857 16.0476846,1.63714286 15.9209353,1.395 C15.793436,1.15285714 15.5339374,1 15.249689,1 Z'
      />
    </SvgIcon>
  )
}

DropDownIcon.propTypes = {
  disabled: PropTypes.bool,
  iconSize: PropTypes.string,
}

DropDownIcon.defaultProps = {
  disabled: false,
  iconSize: 'large',
}

export default DropDownIcon
