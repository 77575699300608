import React from 'react'
import { withStyles, SvgIcon } from '@material-ui/core'

import colors from '@shared/colors'

const styles = {
  root: {
    fontSize: 48,
  },
}

const SelectedIcon = withStyles(styles)(({ disabled, classes }) => {
  const strokeWidth = disabled ? '1' : '2'
  const stroke = disabled ? colors.grey.medium : colors.blue.dark
  const fill = disabled ? colors.grey.light : colors.black

  return (
    <SvgIcon viewBox='0 0 48 48' classes={classes}>
      <defs>
        <path
          d='M45.35 24C45.35 35.78 35.78 45.35 24 45.35C12.22 45.35 2.65 35.78 2.65 24C2.65 12.22 12.22 2.65 24 2.65C35.78 2.65 45.35 12.22 45.35 24Z'
          id='iyeG8Eesl'
        />
        <path
          d='M36.47 24C36.47 30.88 30.88 36.47 24 36.47C17.12 36.47 11.53 30.88 11.53 24C11.53 17.12 17.12 11.53 24 11.53C30.88 11.53 36.47 17.12 36.47 24Z'
          id='a4Nl0lVQZw'
        />
      </defs>
      <g>
        <g>
          <g>
            <g>
              <use
                xlinkHref='#iyeG8Eesl'
                opacity='1'
                fillOpacity='1'
                fill={colors.white}
                stroke={stroke}
                strokeWidth={strokeWidth}
                strokeOpacity='1'
              />
            </g>
          </g>
          <g>
            <use xlinkHref='#a4Nl0lVQZw' opacity='1' fill={fill} fillOpacity='1' />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
})

SelectedIcon.displayName = 'checkbox-checked'
SelectedIcon.muiName = 'SvgIcon'

const UnselectedIcon = withStyles(styles)(({ disabled, error, classes }) => {
  let color = colors.grey.light
  let fill = colors.white
  let strokeWidth = '1'

  if (disabled) {
    color = colors.grey.medium
    fill = colors.grey.light
  } else if (error) {
    color = colors.red.medium
    strokeWidth = '2'
  }

  return (
    <SvgIcon viewBox='0 0 48 48' classes={classes}>
      <defs>
        <path
          d='M45.35 24C45.35 35.78 35.78 45.35 24 45.35C12.22 45.35 2.65 35.78 2.65 24C2.65 12.22 12.22 2.65 24 2.65C35.78 2.65 45.35 12.22 45.35 24Z'
          id='cErrBBNJ'
        />
      </defs>
      <g>
        <g>
          <g>
            <g>
              <use
                xlinkHref='#cErrBBNJ'
                opacity='1'
                fill={fill}
                fillOpacity='1'
                stroke={color}
                strokeWidth={strokeWidth}
                strokeOpacity='1'
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
})

UnselectedIcon.displayName = 'checkbox-unchecked'
UnselectedIcon.muiName = 'SvgIcon'

export { SelectedIcon, UnselectedIcon }
