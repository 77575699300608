import React from 'react'

export default (
  <g id='Styleguide' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
    <g id='Icons' transform='translate(-368.000000, -5771.000000)' stroke='#4A4A4A'>
      <g id='Nav-Icon/default/simulator' transform='translate(368.000000, 5766.000000)'>
        <g id='laptop-71' transform='translate(1.000000, 6.000000)'>
          <path
            d='M3.47353497,18.5255198 L3.47353497,2.31568998 C3.47353497,1.03685019 4.51038516,0 5.78922495,0 L28.9461248,0 C30.2249646,0 31.2618147,1.03685019 31.2618147,2.31568998 L31.2618147,18.5255198'
            id='Shape'
            strokeWidth='2'
            strokeLinecap='square'
          />
          <path
            d='M21.9990548,21.9990548 L21.9990548,23.7358223 L12.7362949,23.7358223 L12.7362949,21.9990548 L0,21.9990548 C0,24.5567344 2.07370038,26.6304348 4.63137996,26.6304348 L30.1039698,26.6304348 C32.6616493,26.6304348 34.7353497,24.5567344 34.7353497,21.9990548 L21.9990548,21.9990548 Z'
            id='Shape'
            strokeWidth='2'
            strokeLinecap='square'
          />
          <polyline
            id='Shape'
            strokeWidth='2'
            points='25.4725898 6.94706994 17.9465974 14.4730624 13.3152174 9.84168242 8.68383743 14.4730624'
          />
          <polyline
            id='Shape'
            strokeWidth='2'
            strokeLinecap='square'
            points='20.2622873 6.94706994 25.4725898 6.94706994 25.4725898 12.1573724'
          />
        </g>
      </g>
    </g>
  </g>
)
