import React, { Component } from 'react'
import styled from 'styled-components'
import { saveAs } from 'file-saver'

import { Button } from '@shared/components'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import axios from '@app/shared/utilities/axios'

const ButtonWrapper = styled.div``

class FileDownloadButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      errorDialogBoxOpen: false,
    }
  }

  downloadFile = async () => {
    const { link, fileName } = this.props

    try {
      const response = await axios(link, 'get', { responseType: 'blob' })
      if (response.data) {
        saveAs(response.data, fileName)
      }
    } catch (err) {
      this.setState({
        errorDialogBoxOpen: true,
      })
    }
  }

  handleClose = () => {
    this.setState({
      errorDialogBoxOpen: false,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Dialog
          open={this.state.errorDialogBoxOpen}
          onClose={this.handleClose}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogTitle id='responsive-dialog-title'>{'File Not Ready'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This report has not been uploaded yet. Please check back later.
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <ButtonWrapper>
          <Button onClick={() => this.downloadFile()}>Download</Button>
        </ButtonWrapper>
      </React.Fragment>
    )
  }
}

export default FileDownloadButton
