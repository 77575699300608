import * as React from 'react'

import { connectInput } from '@shared/components/Form'

// Relative Imports
import FileInput from './fileInput'

// Connects the file input to the form
class FormFileInputBase extends React.Component {
  handleChange = (e) => {
    const {
      formApi: { onChange },
    } = this.props

    onChange(e.target.files)
  }

  render() {
    const { formApi, ...rest } = this.props

    return <FileInput {...rest} onChange={this.handleChange} />
  }
}

const FormFileInput = connectInput(FormFileInputBase, {
  bundleApi: true,
  defaultName: 'files',
})

function defaultRenderName(name) {
  return <div key={name}>{name}</div>
}

// Displays names from a file input
function FormFileNamesBase({ value: files = [], renderName = defaultRenderName }) {
  const names = Array.from(files).map(({ name }) => renderName(name))

  return names
}

const FormFileNames = connectInput(FormFileNamesBase, {
  defaultName: 'files',
})

export default FormFileInput
export { FormFileNames }
