import { Environment, Network, RecordSource, Store } from 'relay-runtime'
import axios from 'axios'
import { sendExceptionToSentry } from '@shared/utilities/logging'
import serverConfig from './serverConfig'
import keycloak from '../keycloak'

// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise:
async function fetchQuery(operation, variables, cacheConfig, uploadables) {
  try {
    const response = await axios({
      url: `${serverConfig.API_URL}/graphql`,
      method: 'post',
      withCredentials: true,
      crossdomain: true,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${keycloak.token}`,
      },
      data: JSON.stringify({
        query: operation.text,
        variables,
      }),
    })

    return response.data
  } catch (error) {
    sendExceptionToSentry(error)
    console.log('FetchQuery Error:', error) // eslint-disable-line no-console
  }
}

const GATEWAY_URL = serverConfig.GATEWAY_API_URL || 'http://localhost:5050'

// Create a network layer from the fetch function
const network = Network.create(fetchQuery)

// Create a RecordSoure store
const source = new RecordSource()
const store = new Store(source)

// Create an environment using this network:
const environment = new Environment({
  network,
  store,
})

export default environment
export { GATEWAY_URL }
