import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { Route, Redirect, Switch } from 'react-router-dom'
import styled from 'styled-components'

// Alias Imports
import { Routes, UserTypes } from '@app/constants'
import { PageNotFound, AppSettingsConsumer, LoadingIndicator } from '@shared/components'

// Relative Imports
import Landing from './landing'

import { InvestorReports, PhysicianNarrative, Library, Advisors } from '../Routes'

import '@user-admin/exports'

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PageWrapper = styled.div`
  width: 100%;
  margin-bottom: 50px;
  padding-top: 50px;
`

class AppRoutes extends React.Component {
  render() {
    const { viewer } = this.props
    const { groups } = viewer
    const isAdmin = groups.includes(UserTypes.tasAdmin)
    const isPartner = groups.includes(UserTypes.partner)

    return (
      <AppSettingsConsumer>
        {({ data: appSettings, loading }) => {
          const { enableLibrary, enablePhysicianNarrative } = appSettings || {}

          if (loading) {
            return (
              <LoadingWrapper>
                <LoadingIndicator />
              </LoadingWrapper>
            )
          }

          return (
            <PageWrapper>
              <Switch>
                <Route exact path='/(txpro|qa-reports)?'>
                  <Landing {...this.props} appSettings={appSettings || {}} />
                </Route>
                <Route path={Routes.userList}>
                  <portal-user-list-page></portal-user-list-page>
                </Route>
                <Route
                  path={Routes.investorReports}
                  render={(route) =>
                    isAdmin || isPartner ? (
                      <InvestorReports {...this.props} {...route} />
                    ) : (
                      <Redirect to={Routes.pageNotFound} />
                    )
                  }
                />
                <Route
                  path={Routes.physicianNarrative}
                  render={(route) =>
                    enablePhysicianNarrative ? (
                      <PhysicianNarrative {...this.props} {...route} />
                    ) : (
                      <Redirect to={Routes.pageNotFound} />
                    )
                  }
                />
                <Route
                  path={Routes.library}
                  render={(route) =>
                    enableLibrary ? (
                      <Library {...this.props} {...route} />
                    ) : (
                      <Redirect to={Routes.pageNotFound} />
                    )
                  }
                />
                <Route
                  path={Routes.advisors}
                  render={(route) => <Advisors {...this.props} {...route} />}
                />
                <Route path={Routes.pageNotFound} render={() => <PageNotFound />} />
                <Redirect to={Routes.pageNotFound} />
              </Switch>
            </PageWrapper>
          )
        }}
      </AppSettingsConsumer>
    )
  }
}

export default createFragmentContainer(AppRoutes, {
  viewer: graphql`
    fragment appRoutes_viewer on Viewer {
      keycloakID
      groups
      ...landing_viewer
    }
  `,
})
