import React from 'react'

export default (
  <g id='Styleguide' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
    <g id='Icons' transform='translate(-170.000000, -5766.000000)' stroke='#4A4A4A'>
      <g id='Nav-Icon/default/notes' transform='translate(167.000000, 5766.000000)'>
        <g id='notes' transform='translate(4.000000, 1.000000)'>
          <polygon
            id='Shape'
            strokeWidth='2'
            strokeLinecap='square'
            points='21.6 0 0 0 0 36 28.8 36 28.8 0'
          />
          <rect
            id='Rectangle-path'
            strokeWidth='1.5'
            strokeLinecap='square'
            x='4.8'
            y='5'
            width='20'
            height='3.6'
          />
          <rect
            id='Rectangle-path'
            strokeWidth='1.5'
            strokeLinecap='square'
            x='4.8'
            y='12'
            width='20'
            height='3.6'
          />
          <rect
            id='Rectangle-path'
            strokeWidth='1.5'
            strokeLinecap='square'
            x='4.8'
            y='19'
            width='20'
            height='3.6'
          />
          <rect
            id='Rectangle-path'
            strokeWidth='1.5'
            strokeLinecap='square'
            x='4.8'
            y='26'
            width='20'
            height='3.6'
          />
        </g>
      </g>
    </g>
  </g>
)
