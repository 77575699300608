import * as React from 'react'
import classNames from 'classnames'
import MuiSnackbar from '@material-ui/core/Snackbar'
import MuiSnackbarContent from '@material-ui/core/SnackbarContent'
import { withStyles } from '@material-ui/core/styles'

import Flex from '@shared/components/Flex'
import SuccessIcon from '@images/iconComponents/success'
import ErrorIcon from '@images/iconComponents/error'
import colors from '@shared/colors'

const variantIcon = {
  success: SuccessIcon,
  error: ErrorIcon,
}

const styles = {
  success: {
    backgroundColor: colors.green.medium,
  },
  error: {
    backgroundColor: colors.red.medium,
  },
  root: {
    borderRadius: 0,
  },
  message: {
    width: '100%',
    padding: '11px 0',
    fontSize: 14,
  },
}

function SnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <MuiSnackbarContent
      className={classNames(classes[variant], classes.root, className)}
      classes={{ message: classes.message }}
      aria-describedby='client-snackbar'
      message={
        <Flex parent id='client-snackbar' justifyContent='center' alignItems='center' spacing={10}>
          {Icon ? <Icon /> : null}
          <span>{message}</span>
        </Flex>
      }
      {...other}
    />
  )
}

const SnackbarContentWrapper = withStyles(styles)(SnackbarContent)

const Snackbar = ({ message, onClose, action, variant, ...rest }) => (
  <MuiSnackbar onClose={onClose} {...rest}>
    <SnackbarContentWrapper message={message} onClose={onClose} action={action} variant={variant} />
  </MuiSnackbar>
)

export default Snackbar
