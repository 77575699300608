// Libraries
import React from 'react'
import { withRouter } from 'react-router-dom'

// Relative Imports
import { BigText, MediumText, ParagraphText, PageWrapper, GoBack } from './styled'
import Button from '../Button'

const PageNotFound = (props) => (
  <PageWrapper>
    <BigText>404</BigText>
    <MediumText>Page not found</MediumText>
    <ParagraphText>
      Sorry, we can’t seem to find the page you are looking for. Feel free to go back to{' '}
      <GoBack role='presentation' onKeyDown={() => void 0} onClick={() => props.history.goBack()}>
        where you were
      </GoBack>{' '}
      or visit the Dashboard.
    </ParagraphText>
    <Button size='large' onClick={() => props.history.push('/')}>
      Main Menu
    </Button>
  </PageWrapper>
)

export default withRouter(PageNotFound)
