import styled from 'styled-components'

import colors from '@shared/colors'

const MuiTableWrapper = styled.div`
  border: 1px solid ${colors.grey.light};
  border-radius: 7px;
  padding: 20px 0;
  margin: ${({ margin = '0' }) => margin};
`

const TablePaginationWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
`

const CellRowWrapper = styled.div`
  margin: 24px 30px;
`

const StyledAnchorElement = styled.span`
  color: ${colors.blue.link};
`

export { MuiTableWrapper, TablePaginationWrapper, CellRowWrapper, StyledAnchorElement }
