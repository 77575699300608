/**
 * @generated SignedSource<<bf0c059afb5a4f97759fba86fa20e89c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "landing_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "groups",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memberEquivalent",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

node.hash = "af1d7875059faa732bdc58257dbccdef";

module.exports = node;
