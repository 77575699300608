import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import PropTypes from 'prop-types'
import Drawer from '@material-ui/core/Drawer'

import AppMenu from './AppMenu'

import closeCircleIcon from '@app/images/icons/close_circle.svg'
import profileImageIcon from '@app/images/icons/profile-image.svg'

import { CloseIcon, DrawerWrapper, ProfileImage, ProfileImageCircle, ProfileName } from './styled'

/**
 * App menu with profile picture and options
 * @param {function} onClose Close the menu
 * @param {boolean}  open    Whether the menu is open
 */
const AppMenuDrawer = (props) => {
  const { onClose, open, viewer } = props
  const { firstName, lastName, middleInitial } = viewer

  const fullName = `${firstName} ${middleInitial ? middleInitial.concat('.') : ''} ${lastName}`
  const profileImage = profileImageIcon

  return (
    <Drawer className='print--hide' open={open} onClose={onClose}>
      <CloseIcon alt='Close Menu' src={closeCircleIcon} onClick={onClose} />
      <DrawerWrapper>
        <ProfileImageCircle>
          <ProfileImage alt='Profile Image' src={profileImage} />
        </ProfileImageCircle>
        <ProfileName>{fullName}</ProfileName>
        <AppMenu user={viewer} />
      </DrawerWrapper>
    </Drawer>
  )
}

AppMenuDrawer.propTypes = {
  docked: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  width: PropTypes.number,
}

AppMenuDrawer.defaultProps = {
  docked: false,
  onClose: null,
  open: false,
  width: 400,
}

export default createFragmentContainer(AppMenuDrawer, {
  viewer: graphql`
    fragment AppMenuDrawer_viewer on Viewer {
      firstName
      lastName
      middleInitial
      groups
      roles
      memberEquivalent
    }
  `,
})
