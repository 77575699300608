import * as React from 'react'
import { Grid, withStyles, IconButton } from '@material-ui/core'
import _ from 'lodash'

import { connectInput } from '@shared/components/Form'
import Textbox from '@shared/components/Textbox'
import RemoveIcon from '@images/iconComponents/remove'
import AddIcon from '@images/iconComponents/add'
import colors from '@shared/colors'

import { updateOtherTextboxError, removeOtherError } from './utils'

function defaultTextboxValidate(value, prevValue, setError) {
  return value
}

const style = {
  bold: {
    fontWeight: 'bold',
  },
  blueLabel: {
    color: colors.blue.dark,
  },
}

const BaseOthers = ({
  value: formValue,
  error: formError,
  onChange,
  setError,
  otherTextBoxPlaceholder,
  otherTextBoxValidate = defaultTextboxValidate,
  classes,
}) => {
  if (
    formValue !== 'None' && // Flow doesn't seem to understand that isPlainObject already covers for this
    _.isPlainObject(formValue) &&
    formValue.others &&
    Array.isArray(formValue.others)
  ) {
    const { others } = formValue
    let otherFields = [
      <Grid key='other label' item xs={12}>
        <br />
        <b className={classes.bold}>Other</b>
      </Grid>,
    ]

    otherFields = otherFields.concat(
      others.map((value, index) => (
        // No way to generate a valid key for this
        // eslint-disable-next-line react/jsx-key
        <React.Fragment>
          <Grid item xs={3}>
            <Textbox
              fullWidth
              value={value}
              placeholder={otherTextBoxPlaceholder}
              onChange={(e) => {
                const newOtherItems = [...others]
                newOtherItems[index] = otherTextBoxValidate(e.target.value, value, (error) =>
                  setError(updateOtherTextboxError(error, formError, index)),
                )

                onChange({ ...formValue, others: newOtherItems })
              }}
              error={_.get(formError, ['others', index.toString()])}
            />
          </Grid>
          <Grid item xs={9}>
            {others.length > 1 ? (
              <IconButton
                disableRipple
                onClick={() => {
                  const newOtherItems = [...others]
                  newOtherItems.splice(index, 1)

                  onChange({ ...formValue, others: newOtherItems })
                  setError(removeOtherError(index, formError))
                }}
              >
                <RemoveIcon />
              </IconButton>
            ) : null}
          </Grid>
        </React.Fragment>
      )),
    )

    otherFields.push(
      <Grid
        key='add row'
        item
        container
        xs={12}
        spacing={16}
        alignItems='center'
        className={classes.blueLabel}
      >
        <IconButton
          disableRipple
          onClick={() => {
            const newOtherItems = [...others]
            newOtherItems.push('')

            onChange({ ...formValue, others: newOtherItems })
          }}
        >
          <AddIcon />
        </IconButton>
        Add Row
      </Grid>,
    )

    return (
      <Grid container spacing={16} alignItems='flex-start'>
        {otherFields}
      </Grid>
    )
  }

  return null
}

BaseOthers.defaultProps = {
  otherTextBoxPlaceholder: undefined,
  otherTextBoxValidate: defaultTextboxValidate,
}

const Others = connectInput(BaseOthers)

export default withStyles(style)(Others)
