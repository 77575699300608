import React from 'react'
import { ButtonBase, withStyles } from '@material-ui/core'

import colors from '@shared/colors'

const stylesBase = {
  width: '100%',
  height: 110,
  borderRadius: 8,
  boxShadow: '0 1px 4px 0 rgba(0,0,0,0.3)',
  color: colors.brand.treatment,
  stroke: colors.brand.treatment,
}

const stylesHoverFocusBase = {
  background: 'none',
}

const styles = {
  followupRoot: {
    ...stylesBase,
    '&:hover, &:focus': {
      ...stylesHoverFocusBase,
      color: colors.brand.followup,
      boxShadow: `0 1px 4px 0 ${colors.brand.followup}`,
    },
  },
  followupRippleRoot: {
    color: colors.brand.followup,
  },
  investorsRoot: {
    ...stylesBase,
    '&:hover, &:focus': {
      ...stylesHoverFocusBase,
      color: colors.brand.investors,
      boxShadow: `0 1px 4px 0 ${colors.brand.investors}`,
    },
  },
  investorsRippleRoot: {
    color: colors.brand.investors,
  },
  simulatorRoot: {
    ...stylesBase,
    '&:hover, &:focus': {
      ...stylesHoverFocusBase,
      color: colors.brand.simulator,
      boxShadow: `0 1px 4px 0 ${colors.brand.simulator}`,
    },
  },
  simulatorRippleRoot: {
    color: colors.brand.simulator,
  },
  stoneDecisionEngineRoot: {
    ...stylesBase,
    '&:hover, &:focus': {
      ...stylesHoverFocusBase,
      color: colors.brand.stoneDecisionEngine,
      boxShadow: `0 1px 4px 0 ${colors.brand.stoneDecisionEngine}`,
    },
  },
  stoneDecisionEngineRippleRoot: {
    color: colors.brand.stoneDecisionEngine,
  },
  treatmentRoot: {
    ...stylesBase,
    '&:hover, &:focus': {
      ...stylesHoverFocusBase,
      color: colors.brand.treatment,
      boxShadow: `0 1px 4px 0 ${colors.brand.treatment}`,
    },
  },
  treatmentRippleRoot: {
    color: colors.brand.treatment,
  },
  billingRoot: {
    ...stylesBase,
    '&:hover, &:focus': {
      ...stylesHoverFocusBase,
      color: colors.brand.billing,
      boxShadow: `0 1px 4px 0 ${colors.brand.billing}`,
    },
  },
  billingRippleRoot: {
    color: colors.brand.billing,
  },
  physicianNarrativeRoot: {
    ...stylesBase,
    '&:hover, &:focus': {
      ...stylesHoverFocusBase,
      color: colors.brand.physicianNarrative,
      boxShadow: `0 1px 4px 0 ${colors.brand.physicianNarrative}`,
    },
  },
  physicianNarrativeRippleRoot: {
    color: colors.brand.physicianNarrative,
  },
  qaReportsRoot: {
    ...stylesBase,
    '&:hover, &:focus': {
      ...stylesHoverFocusBase,
      color: colors.brand.qaReports,
      boxShadow: `0 1px 4px 0 ${colors.brand.qaReports}`,
    },
  },
  qaReportRipleRoot: {
    color: colors.brand.qaReports,
  },
  libraryRoot: {
    ...stylesBase,
    '&:hover, &:focus': {
      ...stylesHoverFocusBase,
      color: colors.brand.library,
      boxShadow: `0 1px 4px 0 ${colors.brand.library}`,
    },
  },
  libraryRippleRoot: {
    color: colors.brand.library,
  },
  advisorsRoot: {
    ...stylesBase,
    '&:hover, &:focus': {
      ...stylesHoverFocusBase,
      color: colors.brand.advisors,
      boxShadow: `0 1px 4px 0 ${colors.brand.advisors}`,
    },
  },
  advisorsRippleRoot: {
    color: colors.brand.advisors,
  },
  inventoryRoot: {
    ...stylesBase,
    '&:hover, &:focus': {
      ...stylesHoverFocusBase,
      color: colors.brand.inventory,
      boxShadow: `0 1px 4px 0 ${colors.brand.inventory}`,
    },
  },
  inventoryRippleRoot: {
    color: colors.brand.inventory,
  },
}

const AppButton = ({ classes, color = 'treatment', ...rest }) => {
  const { [`${color}Root`]: root, [`${color}RippleRoot`]: rippleRoot } = classes

  return (
    <ButtonBase
      focusRipple
      classes={{ root }}
      TouchRippleProps={{ classes: { root: rippleRoot } }}
      {...rest}
    />
  )
}

export default withStyles(styles)(AppButton)
