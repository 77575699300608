import React, { Component } from 'react'
import axios from '@app/shared/utilities/axios'
import AdvisorsTable from './components/AdvisorsTable'
import { LoadingIndicator } from '@shared/components'

class Advisors extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      advisorsItems: [],
    }

    this.loadAdvisorsItems()
  }

  async loadAdvisorsItems() {
    try {
      // Load the files
      const response = await axios('/advisors-items', 'get')

      const { data: advisorsItems = [] } = response
      if (advisorsItems.length) {
        this.setState({
          loading: false,
          advisorsItems,
        })
      }
    } catch (error) {
      this.setState({
        loading: false,
      })
    }
  }

  render() {
    return (
      <div>
        <h1>NextMed Advisors</h1>
        {this.state.loading ? (
          <LoadingIndicator loadingText='Loading files...' />
        ) : (
          <AdvisorsTable advisorsItems={this.state.advisorsItems} />
        )}
      </div>
    )
  }
}

export default Advisors
