import _ from 'lodash'

function hasNormalValues(formValue) {
  if (!_.isEmpty(_.get(formValue, 'values'))) {
    return true
  }

  return false
}

function hasOtherValues(formValue) {
  if (!_.isEmpty(_.get(formValue, 'others'))) {
    return true
  }

  return false
}

function hasValues(formValue) {
  if (hasNormalValues(formValue) || hasOtherValues(formValue)) {
    return true
  }

  return false
}

function hasValueOrNone(formValue) {
  if (hasValues(formValue) || formValue === 'None') {
    return true
  }

  return false
}

function valueHasValue(innerValue) {
  if (!_.isPlainObject(innerValue)) {
    throw Error('valueHasValue: The inner value is not an object!')
  }

  if (_.isString(innerValue.value) && !_.isEmpty(innerValue.value)) {
    return true
  }

  return false
}

function valueHasName(innerValue) {
  if (!_.isPlainObject(innerValue)) {
    throw Error('valueHasName: The inner value is not an object!')
  }

  if (_.isString(innerValue.name) && !_.isEmpty(innerValue.name)) {
    return true
  }

  return false
}

function normalValuesHaveValue(formValue) {
  if (!_.isPlainObject(formValue) || !_.isArray(formValue.values)) {
    throw Error('normalValuesHaveValue: The values array was not found!')
  }

  return formValue.values.every((innerNormalValue) => valueHasValue(innerNormalValue))
}

const defaultFormError = { general: '', values: {}, others: [] }

function updateTextboxError(error, formError = defaultFormError, name) {
  return {
    ...formError,
    values: {
      ...formError.values,
      [name]: error,
    },
  }
}

function updateOtherTextboxError(error, formError = defaultFormError, index) {
  const newOther = {
    ...formError.others[index],
    name: error,
  }

  const newOthers = [...formError.others]
  newOthers[index] = newOther

  return {
    ...formError,
    others: newOthers,
  }
}

function updateSecondOtherTextboxError(error, formError = defaultFormError, index) {
  const newOther = {
    ...formError.others[index],
    value: error,
  }

  const newOthers = [...formError.others]
  newOthers[index] = newOther

  return {
    ...formError,
    others: newOthers,
  }
}

function removeError(name, formError = defaultFormError) {
  const { [name]: error, ...remainingErrors } = formError.values

  return {
    ...formError,
    values: remainingErrors,
  }
}

function removeOtherError(index, formError = defaultFormError) {
  const newOthers = [...formError.others]
  newOthers.splice(index, 1)

  return {
    ...formError,
    others: newOthers,
  }
}

export {
  hasValues,
  hasValueOrNone,
  hasNormalValues,
  hasOtherValues,
  valueHasName,
  normalValuesHaveValue,
  updateTextboxError,
  updateOtherTextboxError,
  updateSecondOtherTextboxError,
  removeError,
  removeOtherError,
  defaultFormError,
}
