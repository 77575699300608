import * as React from 'react'
import { withStyles, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { isFinite } from 'lodash'

import KeyboardArrowRight from '@images/iconComponents/keyboard-arrow-right'
import KeyboardArrowLeft from '@images/iconComponents/keyboard-arrow-left'

const actionsStyles = (theme) => ({
  arrowIcons: {
    boxSizing: 'border-box',
    fontSize: '26px',
  },
  pageText: {
    display: 'inline',
    fontWeight: 'bold',
    height: '24px',
    width: '11px',
    marginLeft: '12.5px',
    marginRight: '20.5px',
    paddingBottom: '5px',
  },
})

class TablePaginationActions extends React.Component {
  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1)
  }

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1)
  }

  render() {
    const { classes, count, page, rowsPerPage } = this.props
    const pageNumberText = isFinite(page) ? page + 1 : 1
    const disablePreviousButton = page === 0
    const disableNextButton = page >= Math.ceil(count / rowsPerPage) - 1

    return (
      <React.Fragment>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={disablePreviousButton}
          aria-label='Previous Page'
        >
          <KeyboardArrowLeft className={classes.arrowIcons} disabled={disablePreviousButton} />
        </IconButton>
        <Typography variant='display1' className={classes.pageText}>
          {pageNumberText}
        </Typography>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={disableNextButton}
          aria-label='Next Page'
        >
          <KeyboardArrowRight className={classes.arrowIcons} disabled={disableNextButton} />
        </IconButton>
      </React.Fragment>
    )
  }
}

const TablePaginationActionsWrapped = withStyles(actionsStyles)(TablePaginationActions)

export default TablePaginationActionsWrapped
