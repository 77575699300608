// Libraries
import React from 'react'
import { SvgIcon } from '@material-ui/core'

// Alias Imports
import colors from '@shared/colors'

const RemoveIcon = (props) => (
  <SvgIcon {...props} viewBox='0 0 27 27' width='27px' height='27px'>
    <g transform='translate(1, 1)'>
      <circle
        stroke={colors.red.medium}
        strokeWidth='1'
        cx='12.5'
        cy='12.5'
        r='12.5'
        fill='transparent'
      />
      <path
        d='M17.2348485,11.5530303 L13.4469697,11.5530303 L13.4469697,7.76515152 C13.4469697,7.1969697 13.0681818,6.81818182 12.5,6.81818182 C11.9318182,6.81818182 11.5530303,7.1969697 11.5530303,7.76515152 L11.5530303,11.5530303 L7.76515152,11.5530303 C7.1969697,11.5530303 6.81818182,11.9318182 6.81818182,12.5 C6.81818182,13.0681818 7.1969697,13.4469697 7.76515152,13.4469697 L11.5530303,13.4469697 L11.5530303,17.2348485 C11.5530303,17.8030303 11.9318182,18.1818182 12.5,18.1818182 C13.0681818,18.1818182 13.4469697,17.8030303 13.4469697,17.2348485 L13.4469697,13.4469697 L17.2348485,13.4469697 C17.8030303,13.4469697 18.1818182,13.0681818 18.1818182,12.5 C18.1818182,11.9318182 17.8030303,11.5530303 17.2348485,11.5530303 Z'
        fill={colors.red.medium}
        fillRule='nonzero'
        transform='translate(12.500000, 12.500000) rotate(45.000000) translate(-12.500000, -12.500000) '
      />
    </g>
  </SvgIcon>
)

RemoveIcon.displayName = 'remove'
RemoveIcon.muiName = 'SvgIcon'

export default RemoveIcon
