/* eslint-disable no-unreachable */
import React from 'react'
import lodash from 'lodash'
import { withRouter } from 'react-router-dom'

import logout from '@app/images/iconComponents/logout'
import portal from '@app/images/iconComponents/portal'
import followup from '@app/images/iconComponents/followup'
import investors from '@app/images/iconComponents/investors'
import simulator from '@app/images/iconComponents/simulator'
import treatment from '@app/images/iconComponents/treatment'
import sde from '@app/images/iconComponents/sde'
import note from '@app/images/iconComponents/note'
import hospital from '@app/images/iconComponents/hospital'
import advisors from '@app/images/iconComponents/advisors'
import billing from '@app/images/iconComponents/billing'
import { AppSettingsConsumer } from '@shared/components/AppSettingsProvider'

import {
  AppMenuWrapper,
  AppSvg,
  ExternalLinkIcon,
  LogoutLinkWrapper,
  MenuLink,
  MenuLinkWrapper,
} from './styled'
import { GATEWAY_URL } from '@app/config/relayEnvironment'
import keycloak from '@app/keycloak'
import { Routes } from '@app/constants'

const appInfo = {
  portal: {
    link: `${GATEWAY_URL}/portal`,
    displayName: 'Main Menu',
    icon: portal,
  },
  followup: {
    link: `${GATEWAY_URL}/followup`,
    displayName: 'Follow Up',
    icon: followup,
  },
  investors: {
    link: `/portal${Routes.investorReports}`,
    displayName: 'Investor Reports',
    icon: investors,
  },
  simulator: {
    link: `${GATEWAY_URL}/simulator`,
    displayName: 'Simulator',
    icon: simulator,
  },
  treatment: {
    link: `/portal${Routes.downloadTxPro}`,
    displayName: 'TxPro',
    icon: treatment,
  },
  stoneDecisionEngine: {
    link: `/portal${Routes.stoneDecisionEngine}`,
    displayName: 'Stone Logik',
    icon: sde,
  },
  physicianNarrative: {
    link: `/portal${Routes.physicianNarrative}`,
    displayName: 'Message From the CEO',
    icon: note,
  },
  library: {
    link: `/portal${Routes.library}`,
    displayName: 'Library',
    icon: hospital,
  },
  advisors: {
    link: `/portal${Routes.advisors}`,
    displayName: 'NextMed Advisors',
    icon: advisors,
  },
  billing: {
    link: `${GATEWAY_URL}/billing`,
    displayName: 'Billing',
    icon: billing,
  },
}

const appsOrder = [
  'physicianNarrative',
  'investors',
  'followup',
  'simulator',
  'stoneDecisionEngine',
  'treatment',
  'billing',
  'library',
  'advisors',
]

const constructLinks = (links, selectedApp) => {
  return links.map((app) => {
    const selected = selectedApp === app
    const target = '_self'

    return (
      <MenuLinkWrapper key={app} selected={selected}>
        <MenuLink href={appInfo[app].link} target={target} selected={selected}>
          <AppSvg alt={app} viewBox={'0 0 39 39'} preserveAspectRatio='xMidYMid slice'>
            {appInfo[app].icon}
          </AppSvg>
          {appInfo[app].displayName}
          {appInfo[app].rightIcon ? (
            <ExternalLinkIcon>{appInfo[app].rightIcon}</ExternalLinkIcon>
          ) : null}
        </MenuLink>
      </MenuLinkWrapper>
    )
  })
}

const determineUserAppLinks = (user, selectedApp, appSettings) => {
  const { groups = [], memberEquivalent } = user

  const {
    enableFollowup,
    enableLibrary,
    enablePhysicianNarrative,
    enableInvestors,
    enableSimulator,
    enableStoneLogik,
    enableAdvisors,

    appAccessPerGroup,
  } = appSettings

  // Map for easily checking which of the landing page "apps" are disabled.
  const disabledApps = {
    followup: !enableFollowup,
    library: !enableLibrary,
    physicianNarrative: !enablePhysicianNarrative,
    investors: !enableInvestors,
    simulator: !enableSimulator,
    stoneDecisionEngine: !enableStoneLogik,
    advisors: !enableAdvisors,
  }

  // This creates a list of all the apps the viewer has access to based on their
  // groups and orders them according to a custom order.
  const appAccessList = groups.reduce((acc, group) => {
    if (group === 'physician' && memberEquivalent) {
      return [...acc, ...appAccessPerGroup.physician_me]
    }

    return [...acc, ...(appAccessPerGroup[group] || [])]
  }, [])
  const orderedAppAccessList = lodash.filter(
    appsOrder,
    (app) =>
      // Check that the app isn't disabled
      !disabledApps[app] &&
      // Check that the user has access to the app
      appAccessList.includes(app),
  )

  return constructLinks(orderedAppAccessList, selectedApp)
}

const getSelectedPortalApp = (url) => {
  return Object.keys(appInfo).find((key) => appInfo[key].link.includes(url)) || 'portal'
}
/**
 * App menu drawer menu items
 */
const AppMenu = (props) => {
  const { user, location } = props
  const { pathname } = location
  // determine selected app
  const selectedApp = getSelectedPortalApp(pathname)
  const isPortal = selectedApp === 'portal'

  return (
    <AppSettingsConsumer>
      {({ data: appSettings }) => (
        <AppMenuWrapper>
          <LogoutLinkWrapper>
            <MenuLink href={keycloak.getLogoutUrl()}>
              <AppSvg alt='logout' viewBox={'0 0 23 23'} preserveAspectRatio='xMidYMid slice'>
                {logout}
              </AppSvg>
              Log Out
            </MenuLink>
          </LogoutLinkWrapper>
          <MenuLinkWrapper selected={isPortal}>
            <MenuLink href={appInfo.portal.link} selected={isPortal}>
              <AppSvg alt={'portal'} viewBox={'0 0 32 32'} preserveAspectRatio='xMidYMid slice'>
                {appInfo.portal.icon}
              </AppSvg>
              {appInfo.portal.displayName}
            </MenuLink>
          </MenuLinkWrapper>
          {determineUserAppLinks(user, selectedApp, appSettings || {})}
        </AppMenuWrapper>
      )}
    </AppSettingsConsumer>
  )
}

export default withRouter(AppMenu)
