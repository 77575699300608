import * as React from 'react'
import { Typography } from '@material-ui/core'

function Title({ children, ...props }) {
  return (
    <Typography variant='headline' {...props}>
      {children}
    </Typography>
  )
}

export default Title
