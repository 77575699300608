import * as React from 'react'
import { CircularProgress, Typography } from '@material-ui/core'

import { Wrapper } from './styled'

function LoadingIndicator(props) {
  const { loadingText } = props

  return (
    <Wrapper>
      <CircularProgress />
      <Typography variant='display1'>{loadingText}</Typography>
    </Wrapper>
  )
}

export default LoadingIndicator
