import * as React from 'react'
import { Dialog, DialogTitle } from '@material-ui/core'

import internetExplorerLogo from '@images/icons/internet-explorer.svg'

import {
  Section,
  DividerWithText,
  DialogContent,
  ModernBrowserLogos,
  LogoFrame,
  Paragraph,
  style,
} from './styled'

const IncompatibleBrowserModal = () => (
  <Dialog aria-labelledby='simple-modal-title' aria-describedby='simple-modal-description' open>
    <DialogContent>
      <DialogTitle style={style.title}>Outdated Browser Detected</DialogTitle>
      <Section>
        <Paragraph>
          We’re sorry, but we have detected that you are using a version of Internet Explorer which
          is no longer supported.
        </Paragraph>
      </Section>
      <Section>
        <Paragraph>Please consider one of the following options:</Paragraph>
      </Section>
      <Section>
        <LogoFrame>
          <img alt='ie-logo' src={internetExplorerLogo} />
        </LogoFrame>
        <Paragraph>Upgrade to the latest version of Internet Explorer (IE11).</Paragraph>
      </Section>
      <Section>
        <DividerWithText text='OR' />
      </Section>
      <Section>
        <LogoFrame>
          <ModernBrowserLogos />
        </LogoFrame>
        <Paragraph padding='15px 0 0 0'>Use a different supported browser.</Paragraph>
      </Section>
    </DialogContent>
  </Dialog>
)

export default IncompatibleBrowserModal
