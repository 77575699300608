/**
 * @generated SignedSource<<9c0b55c0da4d99657caa935b251247e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "rootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "appRoutes_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppMenuDrawer_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppHeader_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "rootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "keycloakID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "groups",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "memberEquivalent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "middleInitial",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c92e9269d4322de42a900c069522bf13",
    "id": null,
    "metadata": {},
    "name": "rootQuery",
    "operationKind": "query",
    "text": "query rootQuery {\n  viewer {\n    ...appRoutes_viewer\n    ...AppMenuDrawer_viewer\n    ...AppHeader_viewer\n    id\n  }\n}\n\nfragment AppHeader_viewer on Viewer {\n  groups\n}\n\nfragment AppMenuDrawer_viewer on Viewer {\n  firstName\n  lastName\n  middleInitial\n  groups\n  roles\n  memberEquivalent\n}\n\nfragment appRoutes_viewer on Viewer {\n  keycloakID\n  groups\n  ...landing_viewer\n}\n\nfragment landing_viewer on Viewer {\n  groups\n  memberEquivalent\n}\n"
  }
};

node.hash = "a366dbec99490aa41065022650674128";

module.exports = node;
