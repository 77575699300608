const Routes = Object.freeze({
  home: '/',
  investorReports: '/investor-reports',
  userList: '/users',
  addUsers: '/users/add',
  downloadTxPro: '/txpro',
  qaReports: '/qa-reports',
  stoneDecisionEngine: '/stone-logik',
  physicianNarrative: '/physician-narrative',
  library: '/library',
  advisors: '/advisors',
  pageNotFound: '/not-found',
})

const UserTypes = Object.freeze({
  tasAdmin: 'tas_admin',
  technologist: 'technologist',
  facilityAdmin: 'facility_admin',
  physician: 'physician',
  facilityQa: 'facility_qa',
  partner: 'partner',
  advisor: 'advisor',
})

const TenantIDToRealmName = Object.freeze({
  1: 'NextMed',
})

const RealmNameToTenantID = Object.freeze({
  NextMed: 1,
})

const UserGroupsDisplayNames = {
  tas_admin: 'TAS Admin',
  facility_admin: 'Facility Admin',
  facility_qa: 'Facility QA',
  physician: 'Physician',
  technologist: 'Technologist',
  partner: 'Partner',
  advisor: 'Advisor',
}

const TechnologistTypeId = Object.freeze({
  regular: 1,
  traveler: 2,
})

export {
  Routes,
  UserTypes,
  TenantIDToRealmName,
  RealmNameToTenantID,
  UserGroupsDisplayNames,
  TechnologistTypeId,
}
