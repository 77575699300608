import React from 'react'
import { withStyles, SvgIcon } from '@material-ui/core'

import colors from '@shared/colors'

const sizes = Object.freeze({
  small: 30,
  medium: 48,
})

const sizeMapper = (SvgComponent) => {
  const WrappedComponent = ({ classes, size = 'medium', ...rest }) => {
    const _size = sizes[size]

    return (
      <SvgComponent
        viewBox='0 0 48 48'
        classes={{ root: classes[size] }}
        width={_size}
        height={_size}
        {...rest}
      />
    )
  }
  WrappedComponent.defaultProps = {
    size: 'medium',
    disabled: false,
    error: false,
  }

  return WrappedComponent
}

const styles = {
  small: {
    fontSize: sizes.small,
  },
  medium: {
    fontSize: sizes.medium,
  },
}

const CheckedIcon = withStyles(styles)(
  sizeMapper(({ disabled, classes, viewBox }) => {
    const strokeWidth = disabled ? '1' : '2'
    const stroke = disabled ? colors.grey.medium : colors.blue.dark
    const fill = disabled ? colors.grey.light : colors.black

    return (
      <React.Fragment>
        <SvgIcon classes={classes} viewBox={viewBox}>
          <path
            d='M39.01 2.64C42.52 2.64 45.36 5.48 45.36 8.99C45.36 16.26 45.36 31.74 45.36 39.01C45.36 42.52 42.52 45.36 39.01 45.36C31.74 45.36 16.26 45.36 8.99 45.36C5.48 45.36 2.64 42.52 2.64 39.01C2.64 31.74 2.64 16.26 2.64 8.99C2.64 5.48 5.48 2.64 8.99 2.64C16.26 2.64 31.74 2.64 39.01 2.64Z'
            stroke={stroke}
            strokeWidth={strokeWidth}
            fill={colors.white}
          />
          <path
            d='M19.68 27.38L33.8 13.34L37.92 17.38L19.68 35.66L10.08 25.88L14.18 21.88L19.68 27.38Z'
            fill={fill}
          />
        </SvgIcon>
      </React.Fragment>
    )
  }),
)

CheckedIcon.displayName = 'checkbox-checked'
CheckedIcon.muiName = 'SvgIcon'

const UncheckedIcon = withStyles(styles)(
  sizeMapper(({ viewBox, classes, disabled, error }) => {
    let color = colors.grey.light
    let fill = colors.white
    let strokeWidth = '1'

    if (disabled) {
      color = colors.grey.medium
      fill = colors.grey.light
    } else if (error) {
      color = colors.red.medium
      fill = 'none'
      strokeWidth = '2'
    }

    return (
      <React.Fragment>
        <SvgIcon classes={classes} viewBox={viewBox}>
          <path
            d='M39.01 2.64C42.52 2.64 45.36 5.48 45.36 8.99C45.36 16.26 45.36 31.74 45.36 39.01C45.36 42.52 42.52 45.36 39.01 45.36C31.74 45.36 16.26 45.36 8.99 45.36C5.48 45.36 2.64 42.52 2.64 39.01C2.64 31.74 2.64 16.26 2.64 8.99C2.64 5.48 5.48 2.64 8.99 2.64C16.26 2.64 31.74 2.64 39.01 2.64Z'
            opacity='1'
            fill={fill}
            fillOpacity='1'
            stroke={color}
            strokeWidth={strokeWidth}
            strokeOpacity='1'
          />
        </SvgIcon>
      </React.Fragment>
    )
  }),
)

UncheckedIcon.displayName = 'checkbox-unchecked'
UncheckedIcon.muiName = 'SvgIcon'

export { CheckedIcon, UncheckedIcon }
