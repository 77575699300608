import * as React from 'react'
import classNames from 'classnames'
import { Input as BaseInput, FormHelperText, FormControl, withStyles } from '@material-ui/core'

import { connectInput } from './../Form'

const inputStyles = {
  input: {
    width: '100%',
  },
}

const Input = withStyles(inputStyles)(BaseInput)

const textboxStyles = (theme = {}) => {
  const { custom = {} } = theme
  const { textbox = {} } = custom

  return textbox
}

const Textbox = withStyles(textboxStyles)(
  ({
    error,
    classes,
    value,
    disabled,
    multiline,
    rows,
    placeholder,
    fullWidth,
    onChange,
    style,
    endAdornment,
    onKeyUp,
    onBlur,
  }) => (
    <FormControl error={Boolean(error)} fullWidth={fullWidth} style={style}>
      <Input
        disableUnderline
        className={classNames(disabled ? classes.disabled : '', classes.root)}
        value={value}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        placeholder={placeholder}
        fullWidth={fullWidth}
        onChange={onChange}
        onBlur={onBlur}
        endAdornment={
          endAdornment ? <span className={classes.adornment}>{endAdornment}</span> : undefined
        }
        onKeyUp={onKeyUp}
      />
      {error ? <FormHelperText>{error}</FormHelperText> : undefined}
    </FormControl>
  ),
)

Textbox.defaultProps = {
  error: undefined,
  formControlProps: {},
  formHelperTextProps: {},
  value: undefined,
  disabled: false,
  classes: {},
  multiline: false,
  rows: 1,
  placeholder: '',
  fullWidth: false,
  onChange: () => {},
  style: undefined,
  onKeyUp: () => {},
}

const FormTextboxBase = ({ formApi, ...rest }) => {
  const { value = '', onChange, error } = formApi

  return (
    <Textbox {...rest} value={value} onChange={(e) => onChange(e.target.value)} error={error} />
  )
}

const FormTextbox = connectInput(FormTextboxBase, {
  defaultName: 'textbox',
  bundleApi: true,
})

export default Textbox
export { FormTextbox }
