import colors from '@shared/colors'

const muiTheme = {
  typography: {
    fontFamily: "Lato, 'Helvetica Neue', Arial, sans-serif",
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
      colorPrimary: {
        color: colors.black,
        backgroundColor: colors.white,
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '30px',
      },
    },
    MuiToolbar: {
      root: {
        margin: '0 auto',
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: colors.brand.primary,
      },
      indicator: {
        display: 'none',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontSize: '20px !important',
      },
      labelIcon: {
        height: '50px',
      },
      textColorInherit: {
        color: colors.white,
      },
      wrapper: {
        flexDirection: 'row',
        minWidth: 262,
      },
    },
    MuiTypography: {
      headline: {
        fontSize: 24,
      },
      title: {
        fontWeight: 'bold',
        fontSize: 22,
      },
      subheading: {
        fontWeight: 'bold',
        fontSize: 18,
      },
      body1: {
        fontSize: '18px',
      },
      display1: {
        color: colors.black,
        fontSize: '19px',
      },
      display2: {
        color: colors.black,
        fontSize: '22px',
      },
      display3: {
        color: colors.black,
      },
      display4: {
        color: colors.black,
      },
      colorSecondary: {
        color: colors.grey.medium,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
        textTransform: 'none',
        fontSize: '18px',
        padding: '8px 10px',
        minHeight: '45px',
        minWidth: '112px',
      },
      sizeLarge: {
        fontSize: '18px',
        padding: '8px 10px',
        minHeight: '45px',
        width: '407px',
      },
      fab: {
        width: 44,
        height: 44,
      },
      mini: {
        width: 36,
        height: 36,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
      label: {
        fontSize: 18,
      },
      disabled: {
        color: `${colors.black} !important`,
      },
    },
    MuiCheckbox: {
      root: {
        marginRight: 10,
      },
      checked: {
        color: `${colors.grey.medium} !important`,
      },
    },
    MuiRadio: {
      root: {
        marginRight: 10,
      },
      checked: {
        color: `${colors.white} !important`,
      },
    },
    MuiInput: {
      root: {
        border: `1px solid ${colors.grey.light}`,
        borderRadius: 5,
        fontSize: 18,
        padding: 1,
      },
      input: {
        padding: '6px 8px 7px',
      },
      multiline: {
        padding: '6px 8px 7px',
      },
      focused: {
        borderColor: colors.blue.dark,
        borderWidth: 2,
        padding: 0,
      },
      error: {
        borderColor: colors.red.medium,
        borderWidth: 2,
        padding: 0,
      },
    },
    MuiTablePagination: {
      input: {
        height: '40px',
        width: '78px',
        borderRadius: '7px',
        borderColor: colors.grey.light,
      },
      select: {
        fontSize: '18px',
        paddingLeft: '0px',
        paddingRight: '35px',
        marginTop: '5px',
        marginLeft: '12px',
      },
      selectRoot: {
        marginLeft: 0,
        marginRight: 0,
        color: colors.black,
      },
      caption: {
        fontSize: '16px',
        marginRight: '10px',
        marginLeft: '10px',
        color: colors.black,
      },
      selectIcon: {
        height: '35px',
        width: '35px',
      },
      actions: {
        color: `${colors.black} !impotant`,
      },
    },
    MuiIconButton: {
      root: {
        color: colors.black,
        width: 42,
        height: 42,
        '&:hover': {
          backgroundColor: colors.grey.light,
        },
        '&:focus': {
          backgroundColor: colors.grey.light,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 14,
      },
      error: {
        color: `${colors.red.medium} !important`,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        top: 'calc(50% - 15px)',
        color: 'inherit',
      },
      disabled: {
        color: colors.grey.medium,
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          backgroundColor: colors.blue.light,
        },
        '&:focus': {
          backgroundColor: colors.blue.light,
        },
      },
      selected: {
        backgroundColor: `${colors.blue.light} !important`,
      },
    },
    MuiTable: {
      root: {
        borderCollapse: 'separate',
      },
    },
    MuiTableRow: {
      root: {
        height: 0,
        border: 'none',
      },
      head: {
        height: 0,
      },
      hover: {
        '&:hover': {
          backgroundColor: `${colors.blue.light} !important`,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
        color: colors.black,
        verticalAlign: 'top',
        '&:first-child': {
          paddingLeft: '25px',
        },
        '&:last-child': {
          paddingRight: '20px',
        },
      },
      head: {
        padding: '15px 14px 20px',
        fontSize: '18px',
        fontWeight: 'bold',
        color: colors.black,
      },
      body: {
        padding: '10px 14px',
        fontSize: '16px',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: colors.black,
        fontWeight: 'bold',
        fontSize: '17px',
      },
      icon: {
        width: 18,
        height: 18,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '8px',
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.black,
        '&$focused': {
          color: colors.black,
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: colors.blue.dark,
      },
    },
    MuiFormGroup: {
      root: {
        marginTop: 20,
      },
    },
  },
  custom: {
    button: {
      colors: {
        primary: {
          color: colors.white,
          backgroundColor: colors.blue.dark,
          '&:hover': {
            backgroundColor: colors.blue.mediumLight,
          },
        },
        secondary: {
          color: colors.blue.dark,
          backgroundColor: colors.white,
          borderStyle: 'solid',
          border: 2,
          borderColor: colors.blue.dark,
          '&:hover': {
            backgroundColor: colors.blue.light,
          },
        },
        confirm: {
          color: colors.green.medium,
          backgroundColor: colors.white,
          borderStyle: 'solid',
          border: 2,
          borderColor: colors.green.medium,
          '&:hover': {
            backgroundColor: colors.green.light,
          },
        },
        warning: {
          color: colors.orange.mediumDark,
          backgroundColor: colors.white,
          borderStyle: 'solid',
          border: 2,
          borderColor: colors.orange.mediumDark,
          '&:hover': {
            backgroundColor: colors.orange.light,
          },
        },
        danger: {
          color: colors.red.medium,
          backgroundColor: colors.white,
          borderStyle: 'solid',
          border: 2,
          borderColor: colors.red.medium,
          '&:hover': {
            backgroundColor: colors.red.light,
          },
        },
        flatDanger: {
          color: colors.white,
          backgroundColor: colors.red.medium,
          '&:hover': {
            backgroundColor: colors.red.mediumLight,
          },
        },
        disabled: {
          color: colors.grey.medium,
          backgroundColor: colors.grey.light,
        },
        secondaryDisabled: {
          color: colors.grey.medium,
          borderColor: colors.grey.light,
        },
        primaryRipple: {
          backgroundColor: colors.blue.mediumDark,
        },
        confirmRipple: {
          backgroundColor: colors.green.dark,
        },
        warningRipple: {
          backgroundColor: colors.orange.dark,
        },
        dangerRipple: {
          backgroundColor: colors.red.dark,
        },
        flatDangerRipple: {
          backgroundColor: colors.red.dark,
        },
      },
    },
    select: {
      root: {
        backgroundColor: colors.white,
      },
      placeholderText: {
        root: {
          color: colors.grey.medium,
        },
      },
      menu: {
        paper: {
          boxShadow: 'none',
          border: `2px solid ${colors.blue.dark}`,
          borderRadius: 5,
        },
      },
    },
    textbox: {
      root: {
        backgroundColor: colors.white,
      },
      disabled: {
        backgroundColor: colors.grey.light,
        color: colors.grey.medium,
      },
      adornment: {
        padding: '6px 8px 7px 0',
        color: colors.grey.medium,
      },
    },
  },
}

export default muiTheme
