import * as React from 'react'
import { withStyles, Button as MuiButton } from '@material-ui/core'

import { connectInput } from '@shared/components/Form'

const buttonStyles = (props) => {
  const { custom = {} } = props
  const { button = {} } = custom
  const { colors = {} } = button
  const {
    primary,
    secondary,
    confirm,
    warning,
    danger,
    flatDanger,
    disabled,
    secondaryDisabled,
    primaryRipple,
    confirmRipple,
    warningRipple,
    dangerRipple,
    flatDangerRipple,
  } = colors

  return {
    primary,
    secondary,
    confirm,
    warning,
    danger,
    flatDanger,
    disabled,
    secondaryDisabled,
    primaryRipple,
    confirmRipple,
    warningRipple,
    dangerRipple,
    flatDangerRipple,
  }
}

const ButtonBase = ({
  onClick,
  children,
  color,
  disabled,
  fullWidth,
  classes,
  className,
  href,
  size = 'medium',
}) => {
  let rootClass = classes.primary
  let rippleClass = classes.primaryRipple

  if (color) {
    rootClass = classes[color]
    rippleClass = classes[`${color}Ripple`]
  }

  return (
    <MuiButton
      onClick={onClick}
      disabled={disabled}
      size={size}
      fullWidth={fullWidth}
      href={href}
      classes={{
        root: rootClass,
        disabled: color === 'secondary' ? classes.secondaryDisabled : classes.disabled,
      }}
      className={className}
      TouchRippleProps={{ classes: { child: rippleClass } }}
    >
      {children}
    </MuiButton>
  )
}

ButtonBase.defaultProps = {
  onClick: undefined,
  children: undefined,
  color: 'primary',
  className: '',
  disabled: false,
}

const Button = withStyles(buttonStyles)(ButtonBase)

const SubmitButtonBase = ({ formApi, submitTag, ...rest }) => (
  <Button onClick={() => formApi.submit(submitTag)} {...rest} />
)

SubmitButtonBase.defaultProps = {
  submitTag: undefined,
}

const SubmitButton = connectInput(SubmitButtonBase, { bundleApi: true })

export default Button
export { SubmitButton }
