import React from 'react'

import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'

import { TableWrapper, FileDownloadButton } from '@shared/components'

const StyledTableRow = withStyles({ root: { cursor: 'pointer' } })(TableRow)

function PhysicianNarrativeReportTable(props) {
  const { ceoMessages } = props

  return (
    <TableWrapper margin='36px 0 0'>
      {ceoMessages && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Report Name</TableCell>
              <TableCell>Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ceoMessages.map((ceoMessage) => (
              <StyledTableRow hover key={ceoMessage.id}>
                <TableCell style={{ width: '80%' }}>{ceoMessage.name}</TableCell>
                <TableCell style={{ width: '10%' }}>
                  <FileDownloadButton
                    link={`/ceo-messages/${ceoMessage.name}/file`}
                    fileName={ceoMessage.name}
                  />
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableWrapper>
  )
}

export default PhysicianNarrativeReportTable
