import React from 'react'

export default (
  <svg
    strokeWidth='2'
    id='Icons-fill'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 500 500'
  >
    <path
      strokeWidth='2'
      d='M423,160.16H341a23,23,0,0,0-3.12.21V104A23.86,23.86,0,0,0,314,80.16H172A23.86,23.86,0,0,0,148.16,104v56.31a24.21,24.21,0,0,0-2.71-.15h-82A23.86,23.86,0,0,0,39.61,184V406a23.86,23.86,0,0,0,23.84,23.84H423A23.87,23.87,0,0,0,446.81,406V184A23.87,23.87,0,0,0,423,160.16Zm-277.51,249h-82A3.12,3.12,0,0,1,60.33,406V184a3.12,3.12,0,0,1,3.12-3.12h82a3.11,3.11,0,0,1,2.71,1.6V406c0,.48,0,1,0,1.43A3.1,3.1,0,0,1,145.45,409.12Zm76.55,0a3.12,3.12,0,0,1-3.12-3.12V334a3.12,3.12,0,0,1,3.12-3.12h42a3.12,3.12,0,0,1,3.12,3.12v72a3.12,3.12,0,0,1-3.12,3.12Zm92,0H287.63a22.92,22.92,0,0,0,.21-3.12V334A23.86,23.86,0,0,0,264,310.16H222A23.86,23.86,0,0,0,198.16,334v72a22.92,22.92,0,0,0,.21,3.12H172a3.1,3.1,0,0,1-2.75-1.69c0-.47,0-.95,0-1.43V184a23.4,23.4,0,0,0-.41-4.38V104a3.12,3.12,0,0,1,3.12-3.12H314a3.12,3.12,0,0,1,3.12,3.12V406A3.12,3.12,0,0,1,314,409.12ZM426.08,406a3.12,3.12,0,0,1-3.12,3.12H341a3.12,3.12,0,0,1-3.12-3.12V184a3.12,3.12,0,0,1,3.12-3.12h82a3.12,3.12,0,0,1,3.12,3.12Z'
    />
    <circle strokeWidth='2' cx='102.23' cy='212.25' r='10.36' />
    <circle strokeWidth='2' cx='102.23' cy='262.86' r='10.36' />
    <circle strokeWidth='2' cx='102.23' cy='313.47' r='10.36' />
    <circle strokeWidth='2' cx='102.23' cy='364.07' r='10.36' />
    <circle strokeWidth='2' cx='382.96' cy='212.25' r='10.36' />
    <circle strokeWidth='2' cx='382.96' cy='262.86' r='10.36' />
    <circle strokeWidth='2' cx='382.96' cy='313.47' r='10.36' />
    <circle strokeWidth='2' cx='382.96' cy='364.07' r='10.36' />
    <rect strokeWidth='2' x='233.02' y='129.11' width='20.37' height='87.54' />
    <polyline strokeWidth='2' points='199.43 162.7 286.98 162.7 286.98 183.07 199.43 183.07' />
  </svg>
)
