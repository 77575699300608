import * as Sentry from '@sentry/browser'
import config from '@app/config/env'

/*
 * Basically what is going on here is whenever someone calls logger.* somewhere in the app to
 * log something to the console, it gets logged in sentry for that log level AND logged in the
 * console. This is beneficial because it helps with debugging (sentry is not available) and could provide
 * us with a way to ssh into the container if we really needed and look at the console logs.
 */

Sentry.init({
  dsn: config.SENTRY_APP_PUBLIC_DSN,
  release: config.SENTRY_RELEASE,
  environment: config.SENTRY_ENVIRONMENT,
  autoBreadcrumbs: true,
})

const sendExceptionToSentry = (error) => {
  Sentry.captureException(error)
}

const sendMessageToSentry = (message, level = 'info') => {
  Sentry.captureMessage(message, level)
}

const sentEventToSentry = (event) => {
  Sentry.captureEvent(event)
}

/* eslint-disable no-console */
const logger = {
  debug: (message) => {
    sendMessageToSentry(message, 'debug')
  },
  info: (message) => {
    sendMessageToSentry(message, 'info')
  },
  warning: (message) => {
    sendMessageToSentry(message, 'warning')
  },
  error: (message) => {
    sendMessageToSentry(message, 'error')
  },
  fatal: (message) => {
    sendMessageToSentry(message, 'fatal')
  },
}
/* eslint-enable no-console */

export { Sentry, logger, sendExceptionToSentry, sendMessageToSentry, sentEventToSentry }
