import * as React from 'react'

import { FormConsumer } from './context'
import { flattenValues, flattenErrors } from './utils'

function formSectionApi(formState) {
  const {
    values,
    errors,
    sectionRegister,
    setInputValue,
    setInputError,
    submit,
    valuesHaveChanged,
  } = formState

  return {
    values,
    errors,
    sectionRegister,
    flatValues: flattenValues(sectionRegister, values),
    flatErrors: flattenErrors(sectionRegister, errors),
    onChange: (value, options) => setInputValue([], value, options),
    onChangeInput: setInputValue,
    setError: (error, { afterUpdate } = {}) => setInputError([], error, { afterUpdate }),
    setInputError,
    submit,
    valuesHaveChanged,
  }
}

const FormApiConnector = ({ children: renderFn }) => (
  <FormConsumer>{(formState) => renderFn(formSectionApi(formState))}</FormConsumer>
)

export default FormApiConnector
