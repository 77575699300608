import * as React from 'react'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { Paper } from '@material-ui/core'
import Autosuggest from 'react-autosuggest'

import colors from '@shared/colors'
import AutoSuggestSizeMap from './AutoSuggestSizeMap'

import AutoSuggestInput from './AutoSuggestInput'

import { AutoSuggestItem, AutoSuggestItemText } from './styled'

const styles = {
  container: {
    height: '40px', // Needed to prevent below content from jumping when closing menu
    position: 'relative', // Needed for proper sizing on the dropdown,
    display: 'inline-block',
    width: '100%',
  },
  suggestion: {
    display: 'block',
  },
  suggestionHighlighted: {
    backgroundColor: colors.blue.light,
  },
  suggestionsContainer: {
    display: 'none', // Hide menu while not open (because of border)
    border: '2px solid transparent', // Render border while not open (because of strange flashes)
    padding: '10px 0',
    overflowY: 'auto',
  },
  suggestionsContainerOpen: {
    display: 'block', // Show the menu while open
    position: 'absolute',
    marginTop: '5px',
    marginBottom: '15px',
    left: 0,
    right: 0,
    zIndex: 4, // Should appear on top
    borderColor: colors.blue.mediumDark, // Display border by adding colour
    borderRadius: '7px',
    backgroundColor: colors.white,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
}

function defaultGetValueFromData({ value } = {}) {
  return value
}

function defaultGetTextFromData({ text } = {}) {
  return text
}

/*

NOTE: There are several things that may yet need to be done:

- Enable customizability of input
  - Wasn't concerned with this at the time due to time constraints, but could be
    similar to existing (old) component where props are accepted and then spread inline.
- Standardize 'onBlur' and 'onChange' responses
  - The component was designed with only one use case in mind (all I had access to really)
  - It may be nice to return the event, along with an object containing the other values
 */

/**
 * AutoSuggest component that enables searching and selecting from a list of options
 * @param  {object}   props                - React props
 * @param  {boolean}  props.disabled       - Whether component is disabled
 * @param  {string}   props.errorText      - Error indication text
 * @param  {string}   props.hintText       - Placeholder text
 * @param  {string}   props.highlightFirst - Whether to highlight (and select) first suggestion in list
 * @param  {number}   props.maxItems       - Maximum items to display in list
 * @param  {boolean}  props.multiselect    - Whether component support multi-selection (for multi-select parents)
 * @param  {string}   props.name           - Component name
 * @param  {function} props.onBlur         - Handler for onBlur event
 * @param  {function} props.onChange       - Handler for onChange event
 * @param  {string}   props.size           - Component size map key
 * @param  {string}   props.value          - Selected value
 * @return {element}                      - React component
 */
class AutoSuggest extends React.Component {
  constructor(props) {
    super(props)

    const { value, dataSource } = this.props

    // Set the initial value and suggestions (all suggestions since there is no search)
    this.state = {
      inputText: this.getTextFromDataSource(dataSource, value),
      suggestions: this.getSuggestions(),
    }
  }

  static defaultProps = {
    disabled: false,
    errorText: '',
    highlightFirst: false,
    hintText: '',
    iconSize: 'large',
    multiSelect: false,
    maxItems: 15,
    name: '',
    onBlur: undefined,
    onChange: undefined,
    size: 'medium',
    value: '',
    getValueFromData: defaultGetValueFromData,
    getTextFromData: defaultGetTextFromData,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value: oldValue } = this.props
    const { value: newValue, dataSource } = nextProps

    /* Update the component with new props (when dependencies change). Either
        the suggestions or selected value can be set/changed by the parent,
        often caused by another field that has a relationship/dependency.
        (ie. each Machine Type has different serial numbers and must update suggestions)
     */
    const updatedState = {}

    if (newValue && oldValue !== newValue) {
      updatedState.inputText = this.getTextFromDataSource(dataSource, newValue)
    }

    this.setState(updatedState)
  }

  getTextFromDataSource = (dataSource, value) => {
    const { getValueFromData = defaultGetValueFromData, getTextFromData = defaultGetTextFromData } =
      this.props

    const selectedData = dataSource.find((suggestion) => value === getValueFromData(suggestion))
    return selectedData ? getTextFromData(selectedData) : ''
  }

  /**
   * Handler for fetching the sugggestions
   * @param  {object} params        - Function parameters
   * @param  {string} params.value  - Current search value
   * @param  {string} params.reason - Reason fetch was requested
   */
  onSuggestionsFetchRequested = ({ value: inputText, reason }) => {
    this.setState({
      suggestions: this.getSuggestions(inputText),
    })
  }

  /**
   * Handler for clearing the suggestions list
   */
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  /**
   * Handler for selecting a suggestion
   * @param  {object} event           - Event object
   * @param  {object} args            - Function arguments
   * @param  {object} args.suggestion - Selected suggestion
   */
  onSuggestionSelected = (event, args) => {
    const { disabled, onChange, getValueFromData = defaultGetValueFromData } = this.props
    const { suggestion } = args

    if (disabled || !onChange) return

    // Pass the selected value to the parent
    onChange(getValueFromData(suggestion))
  }

  /**
   * Handler for leaving the autocomplete input
   * @param  {object} event                      - Event object
   * @param  {object} args                       - Function arguments
   * @param  {object} args.highlightedSuggestion - Currently highlighted suggestion (if any)
   */
  onBlur = (event, { highlightedSuggestion }) => {
    const {
      disabled,
      onBlur,
      onChange,
      dataSource,
      getValueFromData = defaultGetValueFromData,
      getTextFromData = defaultGetTextFromData,
    } = this.props
    const { inputText } = this.state

    if (disabled) return

    if (onChange) {
      const matchingSuggestion = dataSource.find(
        (data) => inputText.toLowerCase() === getTextFromData(data).toLowerCase(),
      )

      if (matchingSuggestion) {
        onChange(getValueFromData(matchingSuggestion))
      } else {
        onChange()
      }
    }

    if (onBlur) {
      onBlur(highlightedSuggestion)
    }
  }

  /**
   * Handler for input in the search field
   * @param  {object} event           - Event object
   * @param  {object} params          - Function parameters
   * @param  {string} params.newValue - New search value
   */
  onTextChange = (event, { newValue }) => {
    this.setState({
      inputText: newValue,
    })
  }

  /**
   * Handler for clicking on the Input dropdown icon (focuses on input)
   */
  onDropDownIconClick = () => {
    if (!this.autoSuggestRef || !this.autoSuggestRef.input) return

    // Send focus to the input element
    this.autoSuggestRef.input.focus()
  }

  /**
   * Get the value of a selected suggestion
   * @param  {object} suggestion - Selected suggestion object
   * @return {string}            - Suggestion value
   */
  getInputSuggestionValue = (suggestion) => {
    const { getTextFromData = defaultGetTextFromData } = this.props

    return getTextFromData(suggestion)
  }

  /**
   * Get the list of suggestions that match the query
   * @param  {string}   value - Current search query
   * @return {object[]}       - List of matching suggestion
   */
  getSuggestions = (text = '') => {
    const {
      dataSource,
      maxItems = 15,
      value,
      getTextFromData = defaultGetTextFromData,
    } = this.props

    const valueText = this.getTextFromDataSource(dataSource, value)
    const queryText = text.trim().toLowerCase()
    let count = 0

    // Filter the list of suggestions
    return dataSource.filter((suggestion) => {
      let keep = count < maxItems

      /* Only filter the list when a search change has been made (ie. different
          than previous value). This will permit displaying the entire list
          when the dropdown first opens with an exising value (rather than only
          the exact matching item). */
      if (keep && text !== valueText) {
        keep = getTextFromData(suggestion).toLowerCase().includes(queryText)
      }

      if (keep) {
        count += 1
      }

      return keep
    })
  }

  /**
   * Render the input field (using custom component because of 'ref' dependency)
   * @param  {object}  inputProps - Input props
   * @return {element}            - React component
   */
  renderInput = (inputProps) => {
    // Need to separate and pass the 'ref' in separately so it isn' swallowed
    //  If this is removed the component 'focus' will be broken!
    const { ref, ...rest } = inputProps

    return <AutoSuggestInput inputRef={ref} {...rest} />
  }

  /**
   * Render a suggestion menu item (using MUI component)
   * @param  {object}  suggestion          - Suggestion object
   * @param  {object}  props               - Suggestion options
   * @param  {string}  props.query         - Current search query
   * @param  {string}  props.isHighlighted - Whether suggestion is highlighted
   * @return {element}                     - React component
   */
  renderSuggestion = (suggestion, { query }) => {
    /* NOTE: The 'autosuggest-highlight' only supports highlighting from the
     *       beginning of a word. Also, since it strips out spaces any
     *       selections with two or more words will occasionally not have spacing.
     */
    const {
      value,
      getValueFromData = defaultGetValueFromData,
      getTextFromData = defaultGetTextFromData,
    } = this.props
    const matches = match(getTextFromData(suggestion), query)
    const parts = parse(getTextFromData(suggestion), matches)

    const isChecked = getValueFromData(suggestion) === value

    /* The MateriaulUI MenuItem component causes broken tabbing behaviour!
     * However, not using menu item circumvents our styling
     */
    return (
      <AutoSuggestItem checked={isChecked}>
        <AutoSuggestItemText>
          {parts.map((part, index) => {
            return part.highlight ? (
              <strong key={index}>{part.text}</strong>
            ) : (
              <span key={index}>{part.text}</span>
            )
          })}
        </AutoSuggestItemText>
      </AutoSuggestItem>
    )
  }

  /**
   * Render the suggestions container (using MUI component)
   * @param  {object}  options - Container options
   * @return {element}         - Rendered element
   */
  renderSuggestionsContainer = (options) => {
    const { containerProps, children } = options

    return <Paper {...containerProps}>{children}</Paper>
  }

  /**
   * Display the suggestions menu on focus
   * @param  {string}  value - Current input value
   * @return {boolean}       - Whether suggestions should display
   */
  shouldRenderSuggestions = () => true

  render() {
    const { disabled, errorText, highlightFirst, hintText, iconSize, name, size } = this.props
    const { suggestions, inputText } = this.state

    // Don't highlight the first item if an initial value was passed in
    const shouldHighlightFirst = highlightFirst && !inputText

    return (
      <Autosuggest
        inputProps={{
          disabled,
          errorText,
          iconSize: iconSize,
          name,
          placeholder: hintText,
          size,
          value: inputText,
          onBlur: this.onBlur,
          onChange: this.onTextChange,
          onDropDownIconClick: this.onDropDownIconClick,
        }}
        ref={(element) => (this.autoSuggestRef = element)}
        getSuggestionValue={this.getInputSuggestionValue}
        highlightFirstSuggestion={shouldHighlightFirst}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        renderInputComponent={this.renderInput}
        renderSuggestion={this.renderSuggestion}
        // renderSuggestionsContainer={this.renderSuggestionsContainer}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        suggestions={suggestions}
        theme={{
          container: styles.container,
          suggestion: styles.suggestion,
          suggestionHighlighted: styles.suggestionHighlighted,
          suggestionsContainer: styles.suggestionsContainer,
          suggestionsContainerOpen: styles.suggestionsContainerOpen,
          suggestionsList: styles.suggestionsList,
        }}
      />
    )
  }
}

export default AutoSuggest

export { AutoSuggestSizeMap }
