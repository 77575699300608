// Libraries
import React from 'react'

// Alias Imports
import colors from '@shared/colors'

const SuccessIcon = ({ color = colors.white, ...rest }) => (
  <svg {...rest} width='30px' height='26px'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(1, 1)' stroke={color} strokeLinecap='square'>
        <g>
          <polyline
            strokeWidth='2'
            points='5.45454545 12 9.81818182 16.3636364 18.5454545 7.63636364'
          />
          <circle strokeWidth='2' cx='12' cy='12' r='12' />
        </g>
      </g>
    </g>
  </svg>
)

SuccessIcon.displayName = 'success'

export default SuccessIcon
