/**
 * @generated SignedSource<<281e3bb6b755058c854e1c74eddb5f1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "appRoutes_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "keycloakID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "groups",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "landing_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

node.hash = "8f62ccb8f1ec3e1501289f0a494ecee5";

module.exports = node;
