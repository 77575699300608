import React from 'react'

export default (
  <g
    id='Styleguide'
    stroke='none'
    strokeWidth='1'
    fill='none'
    fillRule='evenodd'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <g id='Icons' transform='translate(-99.000000, -5766.000000)' stroke='#4A4A4A'>
      <g id='Nav-Icon/default/followup' transform='translate(100.000000, 5767.000000)'>
        <g id='icon---follow-up-copy'>
          <circle id='Oval' strokeWidth='2' cx='18.5' cy='13.625' r='6.09375' />
          <path
            d='M30.5729375,32.2011875 C28.348422,27.5530475 23.6530258,24.594764 18.5,24.594764 C13.3469742,24.594764 8.65157801,27.5530475 6.4270625,32.2011875'
            id='Shape'
            strokeWidth='2'
          />
          <circle id='Oval' strokeWidth='2' cx='18.5' cy='18.5' r='18.28125' />
        </g>
      </g>
    </g>
  </g>
)
