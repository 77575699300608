import { v4 as uuidv4 } from 'uuid'

class UUIDValuePairing {
  constructor(value, uuid = uuidv4()) {
    this.uuid = uuid
    this.value = value
  }

  // This creates a new UUIDWrapped instance. Used for immutable scenarios
  clone = (newValue = this.value) => new UUIDValuePairing(newValue, this.uuid)

  // This does not create a new UUIDWrapped instance. Beware of react reconciliation
  set = (newValue) => {
    this.value = newValue
  }

  get = () => this.value

  getUUID = () => this.uuid
}

export default UUIDValuePairing
