import * as React from 'react'
import { Typography, withStyles } from '@material-ui/core'

import colors from '@shared/colors'

const HeadingText = withStyles({
  root: {
    marginTop: 40,
    paddingTop: 30,
    borderTop: `1px solid ${colors.grey.light}`,
  },
})(Typography)

function Heading({ children }) {
  return <HeadingText variant='title'>{children}</HeadingText>
}

export default Heading
