import * as React from 'react'
import { QueryRenderer, graphql } from 'react-relay'

import relayEnvironment from '@app/config/relayEnvironment'

const facilitiesQuery = graphql`
  query FacilitiesQuery($tenantID: Int!) {
    facilities(tenantID: $tenantID) {
      id
      legacyID
      name
    }
  }
`

function FacilitiesQuery({ variables, render }) {
  return (
    <QueryRenderer
      environment={relayEnvironment}
      query={facilitiesQuery}
      variables={variables}
      render={render}
    />
  )
}

export default FacilitiesQuery
