import {
  FASTElement,
  Observable,
  Updates,
  css,
  customElement,
  html,
  ref,
  when,
} from '@microsoft/fast-element'
import checkmarkIcon from '@fluentui/svg-icons/icons/checkmark_16_regular.svg'

import { client } from '@shared/graphClient'
import { UserListQuery } from '../../queries/user-list.query'

export const usersGridTemplateColumns = '1fr 120px 100px 120px 1fr 70px'

const emptyValue = html` <span> - </span>`

export const enabledCellTemplate = html`
  <template>
    ${when((x) => x.rowData.enabled, html` ${html.partial(checkmarkIcon)} `)}
    ${when((x) => !x.rowData.enabled, emptyValue)}
  </template>
`

const userListTemplate = html`
  <div class="container">
    <h1>Portal Users</h1>

    <div class="bar">
      <div class="filters">
        <form action="" @submit=${(x) => x.handleSearchSubmit()}>
          <adaptive-text-field
            placeholder="Search by e-mail"
            ${ref('searchInput')}
          ></adaptive-text-field>

          <adaptive-button type="submit"> Search </adaptive-button>
        </form>
      </div>

      <div class="actions">
        <adaptive-button disabled="true">Add User</adaptive-button>
        <adaptive-button @click=${(x) => x.toggleBulkImportDialog()}>Bulk Import</adaptive-button>
      </div>
    </div>

    <portal-users-bulk-import
      ${ref('bulkImportDialog')}
      @togglevisibility=${(x) => x.toggleBulkImportDialog()}
    ></portal-users-bulk-import>

    <adaptive-data-grid
      glass="users-grid"
      ${ref('usersGrid')}
      generate-header="sticky"
      :gridTemplateColumns=${usersGridTemplateColumns}
    ></adaptive-data-grid>

    <div>
      <adaptive-button @click=${(x) => x.previousPage()}> Back </adaptive-button>
      <adaptive-button @click=${(x) => x.nextPage()}> Next </adaptive-button>

      <span>Page ${(x) => x.page + 1}</span>
    </div>
  </div>
`

const styles = css`
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

@customElement({
  name: 'portal-user-list-page',
  template: userListTemplate,
  styles,
})
export class UserListPage extends FASTElement {
  usersGrid = null
  bulkImportDialog = null
  usersPerPage = 15
  searchInput = null
  _page = 0

  get page() {
    Observable.track(this, 'page')
    return this._page
  }

  set page(value) {
    this._page = value
    Observable.notify(this, 'page')
  }

  connectedCallback() {
    super.connectedCallback()
    Updates.enqueue(() => {
      this.initializeComponents()
      this.fetchUsers(this.page)
    })
  }

  pageChanged(oldValue, newValue) {
    console.log('pageChanged', oldValue, newValue)
  }

  initializeComponents() {
    this.bulkImportDialog.hidden = true

    this.usersGrid.columnDefinitions = [
      { columnDataKey: 'email', title: 'E-mail' },
      { columnDataKey: 'firstName', title: 'First Name' },
      { columnDataKey: 'middleInitial', title: 'Middle Name' },
      { columnDataKey: 'lastName', title: 'Last Name' },
      { columnDataKey: 'groups', title: 'Groups' },
      { columnDataKey: 'enabled', title: 'Enabled', cellTemplate: enabledCellTemplate },
    ]
  }

  toggleBulkImportDialog() {
    this.bulkImportDialog.hidden = !this.bulkImportDialog.hidden
  }

  nextPage() {
    this.page = this.page + 1
    this.fetchUsers(this.page)
  }

  previousPage() {
    if (this.page > 0) {
      this.page = this.page - 1
      this.fetchUsers(this.page)
    }
  }

  handleSearchSubmit() {
    console.log('handleSearchSubmit', this.searchInput.value)
    this.fetchUsers()
  }

  async fetchUsers(page) {
    const max = this.usersPerPage
    const offset = page ? page * max : 0

    const result = await client.query({
      query: UserListQuery,
      variables: {
        tenantID: 1,
        max,
        offset,
        search: this.searchInput.value,
      },
    })

    if (result.data && result.data.users) {
      this.usersGrid.rowsData = result.data.users
    }
  }
}
