import React from 'react'
import { ThemeProvider } from 'styled-components'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import { AppMediaQuery } from '@shared/MediaQuery'
import customMuiTheme from '@shared/muiTheme'
import styledComponentTheme from '@shared/styledComponentTheme'
import { withOpenState } from '@shared/utilities/hoc'
import { Route, Switch } from 'react-router-dom'

import { AppContentWrapper, AppWrapper } from '@shared/styled/app'
import {
  AppHeader,
  AppMenuDrawer,
  AppSettingsProvider,
  IncompatibleBrowserModal,
  SnackbarListener,
} from '@shared/components'
import { Routes } from '@app/constants'
import { isUnsupportedBrowser } from '@shared/utilities/helpers'

import { StoneDecisionEngine } from '../Routes'
import AppRoutes from './appRoutes'

const muiTheme = createMuiTheme(customMuiTheme)

// Time in milliseconds that the message snackbar is open.
const snackbarMessageDuration = 4000

const App = withOpenState((props) => {
  const { isOpen: isAppMenuOpen, setOpenState: setAppMenuState, viewer, ...restOfProps } = props

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={styledComponentTheme}>
        {isUnsupportedBrowser() ? (
          <IncompatibleBrowserModal />
        ) : (
          <SnackbarListener defaultSnackbarProps={{ autoHideDuration: snackbarMessageDuration }}>
            <AppSettingsProvider>
              <AppWrapper>
                <AppMenuDrawer
                  onClose={() => setAppMenuState(false)}
                  open={isAppMenuOpen}
                  viewer={viewer}
                />
                <AppHeader onMenuIconClick={() => setAppMenuState(true)} viewer={viewer} />
                <AppContentWrapper>
                  <Switch>
                    <Route
                      path={Routes.stoneDecisionEngine}
                      render={(route) => <StoneDecisionEngine {...props} {...route} />}
                    />
                    <Route
                      path='/'
                      render={() => (
                        <AppMediaQuery>
                          <AppRoutes viewer={viewer} {...restOfProps} />
                        </AppMediaQuery>
                      )}
                    />
                  </Switch>
                </AppContentWrapper>
              </AppWrapper>
            </AppSettingsProvider>
          </SnackbarListener>
        )}
      </ThemeProvider>
    </MuiThemeProvider>
  )
})

export default App
