import * as React from 'react'
import { withStyles, TableRow, TableCell } from '@material-ui/core'

// Relative Imports
import RightArrowIcon from '@images/iconComponents/right-arrow'

const styles = {
  cell: {
    padding: 0,
    '&:last-child': {
      paddingRight: 10,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    fontSize: 18,
    display: 'block',
  },
}

const LinkRow = ({ children, classes, ...rest }) => (
  <TableRow {...rest}>
    {children}
    <TableCell className={classes.cell}>
      <div className={classes.iconWrapper}>
        <RightArrowIcon className={classes.icon} />
      </div>
    </TableCell>
  </TableRow>
)

LinkRow.defaultProps = {
  children: undefined,
}

export default withStyles(styles)(LinkRow)
