import React from 'react'
import styled from 'styled-components'

import { Modal, Unit, Button, Flex } from '@shared/components'
import { txpro } from '@shared/utilities/integrations/txpro'
import { Routes } from '@app/constants'

const VersionWrapper = styled.div`
  font-style: italic;
`

class TxProDownloadModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      renderRedirect: false,
      txproVersion: '',
    }

    txpro().then((res) =>
      this.setState({
        txproPath: res.data.metadata.buildInfo.url,
        txproVersion: res.data.metadata.buildInfo.version,
      }),
    )
  }

  render() {
    const { history } = this.props
    const { txproVersion, txproPath } = this.state

    return (
      <Modal title='Download TxPro' open={true}>
        <VersionWrapper> Version {txproVersion} </VersionWrapper>
        <Unit>
          TxPro is a standalone application and does not run in the browser. Click the button below
          to download the installer (.exe).
        </Unit>
        <Unit>
          <Flex parent spacing={20} justifyContent='center'>
            <Button onClick={() => window.location.assign(txproPath)}>Download</Button>
            <Button color='secondary' onClick={() => history.push(Routes.home)}>
              Go Back
            </Button>
          </Flex>
        </Unit>
      </Modal>
    )
  }
}

export default TxProDownloadModal
