import React from 'react'

export default (
  <g id='Styleguide' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
    <g id='Icons' transform='translate(-170.000000, -5766.000000)' stroke='#4A4A4A'>
      <g id='Nav-Icon/default/investors' transform='translate(167.000000, 5766.000000)'>
        <g id='chart-bar' transform='translate(4.000000, 1.000000)'>
          <polyline id='Shape' strokeWidth='2' points='21.6 0 21.6 7.2 28.8 7.2' />
          <polygon
            id='Shape'
            strokeWidth='2'
            strokeLinecap='square'
            points='21.6 0 0 0 0 36 28.8 36 28.8 7.2'
          />
          <rect
            id='Rectangle-path'
            strokeWidth='1.5'
            strokeLinecap='square'
            x='12.6'
            y='12'
            width='3.6'
            height='16.1043921'
          />
          <rect
            id='Rectangle-path'
            strokeWidth='1.5'
            strokeLinecap='square'
            x='4.8'
            y='22.4678549'
            width='3.6'
            height='5.63653724'
          />
          <rect
            id='Rectangle-path'
            strokeWidth='1.5'
            strokeLinecap='square'
            x='20.4'
            y='18.4417568'
            width='3.6'
            height='9.66263527'
          />
        </g>
      </g>
    </g>
  </g>
)
