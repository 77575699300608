import React from 'react'

export default (
  <g
    id='Styleguide'
    stroke='none'
    strokeWidth='1'
    fill='none'
    fillRule='evenodd'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <g id='Icons' transform='translate(-225.000000, -5684.000000)' stroke='#4A4A4A'>
      <g id='UI-Icons/Menu-Logout' transform='translate(225.000000, 5684.000000)'>
        <g id='logout' transform='translate(1.000000, 2.000000)'>
          <path d='M6,9 L20,9' id='Shape' strokeWidth='1.5' />
          <polyline id='Shape' strokeWidth='1.5' points='15 3 21 9 15 15' />
          <path
            d='M11,19 L1.375,19 C0.616,19 0,18.392 0,17.6428571 L0,1.35714286 C0,0.608 0.616,0 1.375,0 L11,0'
            id='Shape'
            strokeWidth='1.5'
          />
        </g>
      </g>
    </g>
  </g>
)
