import React from 'react'
import PropTypes from 'prop-types'

import colors from '@shared/colors'
import theme from '@shared/theme'
import { withFocusState } from '@shared/utilities/hoc'
import { AutoSuggestSizeMap } from '@shared/components/AutoSuggest'
import DropDownIcon from '@shared/components/Icon/DropDownIcon'

import { AutoSuggestInputWrapper, AutoSuggestInputIcon, ErrorTextWrapper } from './styled'

/**
 * Input field for AutoSuggest component
 * @name AutoSuggestInput
 * @param {object}   props                    - React props
 * @param {boolean}  props.disabled           - Whether component is disabled
 * @param {string}   props.errorText          - Error indication text
 * @param {function} props.inputRef           - Reference to input field for AutoSuggest focus
 * @param {function} prop.onDropDownIconClick - Handler for clicking on the dropdown icon
 * @param {string}   props.size               - Input size map key
 */
export const WrappedAutoSuggestInput = (props) => {
  const { errorText, focused, iconSize, inputRef, onDropDownIconClick, setFocus, size, ...rest } =
    props
  const { disabled } = rest

  // Get the field width or set a default (for invalid sizes)
  const width = AutoSuggestSizeMap[size] || AutoSuggestSizeMap.medium

  // Disabled field styles
  const disabledStateStyle = disabled && {
    backgroundColor: colors.grey.light,
    borderColor: colors.grey.light,
    color: colors.grey.dark,
  }

  // Focused field styles (from enhanced component)
  const focusStateStyle = focused && {
    border: `2px solid ${colors.blue.mediumDark}`,
    padding: '0 32px 0 8px',
  }

  const placeholderStyle = {
    '&::placeholder': {
      color: 'red',
    },
  }

  // Error state field styles (based on error text)
  const errorStateStyle = errorText && {
    borderColor: colors.red,
    boxShadow: theme.outlineError,
  }

  return (
    <AutoSuggestInputWrapper width={width} focused={focused} iconSize={iconSize}>
      <input
        {...rest}
        type='search'
        ref={inputRef}
        style={{
          width,
          border: `1px solid ${colors.grey.light}`,
          padding: '1px 33px 1px 9px',
          ...disabledStateStyle,
          ...errorStateStyle,
          ...focusStateStyle,
          ...rest.style,
          ...placeholderStyle,
        }}
      />
      <AutoSuggestInputIcon disabled={disabled} onClick={onDropDownIconClick}>
        <DropDownIcon disabled={disabled} iconSize={iconSize} />
      </AutoSuggestInputIcon>
      {errorText && <ErrorTextWrapper>{errorText}</ErrorTextWrapper>}
    </AutoSuggestInputWrapper>
  )
}

WrappedAutoSuggestInput.propTypes = {
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  iconSize: PropTypes.string,
  inputRef: PropTypes.func.isRequired,
  onDropDownIconClick: PropTypes.func,
  size: PropTypes.string,
}

WrappedAutoSuggestInput.defaultProps = {
  disabled: false,
  errorText: '',
  iconSize: 'large',
  inputRef: null,
  onDropDownIconClick: null,
  size: 'medium',
}

export default withFocusState(WrappedAutoSuggestInput)
