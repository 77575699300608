import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import App from './App'
import withTracker from './withTracker'

function Root(props) {
  return (
    <Router basename='/portal'>
      <Route
        path='/'
        component={withTracker((route) => (
          <App {...route} {...props} />
        ))}
      />
    </Router>
  )
}

export default Root
