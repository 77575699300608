import * as React from 'react'
import { Checkbox as MuiCheckbox } from '@material-ui/core'

import { CheckedIcon, UncheckedIcon } from '@images/iconComponents/checkbox'
import { connectInput } from '@shared/components/Form'

import { checkboxControlMappers } from './utils'

const { noneOrList } = checkboxControlMappers
const {
  getChecked: basicGetChecked,
  getToggledFormValues: basicGetToggledFormValues,
  getDisabled: basicGetDisabled,
} = noneOrList

const Checkbox = ({ checked, disabled, error, onChange, value, size }) => (
  <MuiCheckbox
    icon={<UncheckedIcon size={size} disabled={disabled} error={error} />}
    checkedIcon={<CheckedIcon size={size} disabled={disabled} />}
    checked={checked}
    disabled={disabled}
    onChange={onChange}
    value={value}
  />
)

Checkbox.defaultProps = {
  checked: undefined,
  disabled: false,
  error: false,
  onChange: undefined,
  size: undefined,
}

const FormCheckboxBase = ({ formApi, controlMappers = noneOrList, ...externalProps }) => {
  const { value: formValues, onChange, error, setError } = formApi
  const { value, disabled, ...otherProps } = externalProps
  const {
    getChecked = basicGetChecked,
    getToggledFormValues = basicGetToggledFormValues,
    getDisabled = basicGetDisabled,
  } = controlMappers

  return (
    <Checkbox
      {...otherProps}
      value={value}
      checked={getChecked(formValues, value)}
      onChange={() => onChange(getToggledFormValues(formValues, value, error, setError))}
      disabled={getDisabled(formValues, value, disabled)}
    />
  )
}

FormCheckboxBase.defaultProps = {
  controlMappers: noneOrList,
  disabled: undefined,
}

const FormCheckbox = connectInput(FormCheckboxBase, {
  defaultName: 'checkbox',
  bundleApi: true,
})

export default Checkbox
export { FormCheckbox }
