const radioGroupControlMappers = {
  boolean: {
    getValue: (formValue) => {
      if (formValue === true) {
        return 'true'
      } else if (formValue === false) {
        return 'false'
      }

      return undefined
    },
    getUpdatedFormValues: (formValue, nextInputValue) => {
      if (nextInputValue === 'true') {
        return true
      } else if (nextInputValue === 'false') {
        return false
      }

      return undefined
    },
  },
  string: {
    getValue: (formValue) => formValue,
    getUpdatedFormValues: (formValue, nextInputValue) => nextInputValue,
  },
}

export { radioGroupControlMappers }
