import React from 'react'
import ReactDOM from 'react-dom'
import { AdaptiveDesignSystem } from '@adaptive-web/adaptive-web-components'
import { AllComponents } from '@adaptive-web/adaptive-web-components/all-components'
import { DesignToken } from '@microsoft/fast-foundation'

import RootQuery from './components/rootQuery'

import './components/styles/index.css'
import './shared/utilities/logging'

DesignToken.registerDefaultStyleTarget()
AdaptiveDesignSystem.defineComponents(AllComponents)

ReactDOM.render(<RootQuery />, document.getElementById('root'))
