import * as React from 'react'
import { FileDownloadButton } from '@shared/components'

import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'

import { TableWrapper } from '@shared/components/Table'

const StyledTableRow = withStyles({ root: { cursor: 'pointer' } })(TableRow)

export default function LibraryTable(props) {
  const { SPFolder, files } = props
  const headers = [
    {
      id: 0,
      label: 'Report Name',
    },
    {
      id: 1,
      label: 'Download',
    },
  ]

  return (
    <TableWrapper margin='36px 0 0'>
      {files.length === 0 ? (
        ''
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header.id}>{header.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file) => (
              <StyledTableRow hover key={file.id}>
                <TableCell
                  style={{
                    width: '80%',
                  }}
                >
                  {file.name}
                </TableCell>
                <TableCell
                  style={{
                    width: '10%',
                  }}
                >
                  <FileDownloadButton
                    link={`/library-items/${SPFolder}/${file.name}/file`}
                    fileName={file.name}
                  />
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableWrapper>
  )
}
